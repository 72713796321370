import { useSlice } from "components/hooks/useSlice";
import { useCourseParams } from "components/hooks/useCourseParams";

export function useSemester() {
  const { semesterId, isInstructor } = useCourseParams();
  const { teachingSemesters = [], semesters = [] } = useSlice("myAcademics");

  if (isInstructor) {
    return teachingSemesters.find((semester) => semester.slug === semesterId);
  }

  return semesters.find((semester) => semester.slug === semesterId);
}
