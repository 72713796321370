import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Card, CardTitle, CardBody } from "components/ui/Card";
import { useCourseData } from "components/hooks/useCourseData";
import { useTeachingCourse } from "components/hooks/useTeachingCourse";
import { fetchRoster } from "data/slices/courses/roster";
import { MediumSpacer } from "components/ui/VerticalSpacers";
import { SectionRow } from "./SectionOverviewCard/SectionRow";
import { SectionOverviewTable } from "./SectionOverviewCard/SectionOverviewTable";
import { SortableButton } from "./SectionOverviewCard/SortableButton";
import { useSortable } from "./SectionOverviewCard/useSortable";
import { sortableSort } from "./SectionOverviewCard/sortableSort";

import WithMyAcademics from "components/data/WithMyAcademics";
import { ErrorBoundary } from "../ui/ErrorBoundary";
import { Spinner } from "components/ui/Spinner";

export function SectionOverviewCard() {
  return (
    <>
      <WithMyAcademics>
        <ErrorBoundary title="Section Overview">
          <SectionOverviewCardUI />
        </ErrorBoundary>
      </WithMyAcademics>
    </>
  );
}

function SectionOverviewCardUI() {
  const dispatch = useDispatch();
  const teachingCourse = useTeachingCourse();
  const { roster: { loadState, sections = [] } = {} } = useCourseData();

  useEffect(() => {
    if (teachingCourse) {
      dispatch(fetchRoster(teachingCourse));
    }
  }, [dispatch, teachingCourse]);

  const sortable = useSortable("is_primary", "DESC");
  const sortedSections = sections.toSorted(sortableSort(sortable)) || [];

  return (
    <Card>
      <CardTitle>
        <h2>Section Overview</h2>
      </CardTitle>
      <CardBody>
        {loadState === "pending" && (
          <div style={{ textAlign: "center" }}>
            <Spinner />
            <br />
            Downloading rosters. This may take a minute for larger classes.
          </div>
        )}

        {loadState === "failure" && (
          <div style={{ textAlign: `center` }}>
            There was an error retrieving enrollment data.
          </div>
        )}

        {loadState === "success" && (
          <>
            <div>
              <strong>Note: </strong> Use headers to sort columns
            </div>
            <MediumSpacer />
            <SectionOverviewTable>
              <thead>
                <tr>
                  <th>
                    <SortableButton column="is_primary" sortable={sortable}>
                      P/S
                    </SortableButton>
                  </th>
                  <th>
                    <SortableButton column="section_label" sortable={sortable}>
                      Section
                    </SortableButton>
                  </th>
                  <th>
                    <SortableButton column="ccn" sortable={sortable}>
                      Class Number
                    </SortableButton>
                  </th>
                  <th className="show-for-large-up">
                    <SortableButton column="dates" sortable={sortable}>
                      Day and Time
                    </SortableButton>
                  </th>
                  <th className="show-for-large-up">
                    <SortableButton column="locations" sortable={sortable}>
                      Location
                    </SortableButton>
                  </th>
                  <th className="header-separator">
                    <SortableButton column="enroll_count" sortable={sortable}>
                      <div>
                        <strong>Enrollment</strong>
                      </div>
                      On List
                    </SortableButton>
                  </th>
                  <th>
                    <SortableButton column="enroll_open" sortable={sortable}>
                      Open
                    </SortableButton>
                  </th>
                  <th className="show-for-medium-up">
                    <SortableButton column="enroll_limit" sortable={sortable}>
                      Capacity
                    </SortableButton>
                  </th>
                  <th className="header-separator">
                    <SortableButton column="waitlist_count" sortable={sortable}>
                      <div>
                        <strong>Wait List</strong>
                      </div>
                      On List
                    </SortableButton>
                  </th>
                  <th>
                    <SortableButton column="waitlist_open" sortable={sortable}>
                      Open
                    </SortableButton>
                  </th>
                  <th className="show-for-medium-up">
                    <SortableButton column="waitlist_limit" sortable={sortable}>
                      Capacity
                    </SortableButton>
                  </th>
                </tr>
              </thead>
              <tbody>
                {sortedSections.map((section) => (
                  <SectionRow
                    key={section.ccn}
                    index={section.ccn}
                    section={section}
                  />
                ))}
              </tbody>
            </SectionOverviewTable>
          </>
        )}
      </CardBody>
    </Card>
  );
}
