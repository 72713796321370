import React from "react";
import { react2angular } from "react2angular";
import { BrowserRouter as Router } from "react-router-dom";
import { ReduxProvider } from "components/data/ReduxProvider";
import GraduateDegreeProgressCard from "components/cards/GraduateDegreeProgressCard";

const NgGraduateDegreeProgressCard = () => (
  <ReduxProvider>
    <Router>
        <GraduateDegreeProgressCard />
    </Router >
  </ReduxProvider>
);

angular
  .module("calcentral.react")
  .component(
    "graduateDegreeProgressCard",
    react2angular(NgGraduateDegreeProgressCard)
  );
