import React from "react";
import { react2angular } from "react2angular";
import { ReduxProvider } from "components/data/ReduxProvider";
import { CostOfAttendanceCard } from "components/cards/CostOfAttendanceCard";

function NgCostOfAttendanceCard() {
  return (
    <ReduxProvider>
      <CostOfAttendanceCard />
    </ReduxProvider>
  );
}

angular
  .module("calcentral.react")
  .component("costOfAttendanceCard", react2angular(NgCostOfAttendanceCard));
