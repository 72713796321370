import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { CardBody } from "components/ui/Card";
import WithAPILink from "components/ui/WithAPILink";
import { LargeSpacer } from "components/ui/VerticalSpacers";
import CourseTable from "./CourseTable";
import SubTitle from "./SubTitle";
import SwapIndicator from "./SwapIndicator";

export default function SwapsTable({
  swapSections,
  waitlistedCourses,
  enrolledCourses,
}) {
  return (
    <div data-testid="swaps-table">
      <SubTitle><h3>Swaps</h3></SubTitle>
      <CardBody>
        <div>
          You have requested swap(s) for the class(es) listed below. They are
          marked by ⇄ in the Enrollments section above. Learn more about{" "}
          <WithAPILink urlID="UC_CX_WAITLIST_SWAP" />.
        </div>
        <CourseTable>
          <thead>
            <tr>
              <th>Swap</th>
              <th>From</th>
              <th>To</th>
              <th>Date Requested</th>
            </tr>
          </thead>
          <tbody>
            {swapSections.map((swap, index) => {
              const fromCourse = enrolledCourses.find((course) =>
                course.sections.some((section) => section.ccn === swap.from.ccn)
              );

              const toCourse = waitlistedCourses.find((course) =>
                course.sections.some((section) => section.ccn === swap.to.ccn)
              );

              return (
                <SwapRow
                  key={index}
                  swapNumber={index + 1}
                  fromCourse={fromCourse}
                  toCourse={toCourse}
                  swapRecord={swap}
                />
              );
            })}
          </tbody>
        </CourseTable>
      </CardBody>
      <LargeSpacer />
    </div>
  );
}

SwapsTable.displayName = "SwapsTable";
SwapsTable.propTypes = {
  enrolledCourses: PropTypes.array,
  swapSections: PropTypes.array,
  waitlistedCourses: PropTypes.array,
};

function SwapRow({ swapRecord, swapNumber, fromCourse, toCourse }) {
  return (
    <tr key={fromCourse.course_code}>
      <td>
        {/* Without this flex, like the rows in the Enrollments and Waitlists
            tables, the swap indicator renders inconsistently */}
        <div style={{ display: `flex` }}>
          <SwapIndicator index={swapNumber} />
        </div>
      </td>
      <td>
        <Link to={fromCourse.url}>{fromCourse.course_code}</Link>{" "}
        {fromCourse.title}
      </td>
      <td>
        <Link to={toCourse.url}>{toCourse.course_code}</Link> {toCourse.title}
      </td>
      <td>{swapRecord.to.last_enrl_dt_stmp}</td>
    </tr>
  );
}

SwapRow.displayName = "SwapRow";
SwapRow.propTypes = {
  swapRecord: PropTypes.object,
  swapNumber: PropTypes.number,
  fromCourse: PropTypes.object,
  toCourse: PropTypes.object,
};
