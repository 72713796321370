import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchAcademicsDiploma } from "data/actions/academics/diplomaActions";
import Popover, { usePopover } from "components/ui/Popover";
import InfoIcon from "components/ui/InfoIcon";
import ButtonAsLink from "components/ui/ButtonAsLink";
import { useSlice } from "./hooks/useSlice";

import "./Diploma.scss";

export function Diploma() {
  const dispatch = useDispatch();

  const {
    diplomaEligible,
    diplomaReady,
    electronicDiplomaHelpMessage,
    electronicDiplomaNoticeMessage,
    electronicDiplomaReadyMessage,
    paperDiplomaMessage,
    paperDiplomaNoticeHeading,
    paperDiplomaNoticeMessage,
  } = useSlice("academics").diploma || {};

  useEffect(() => {
    dispatch(fetchAcademicsDiploma());
  }, [dispatch]);

  const paperDiplomaPopover = usePopover();
  const electronicDiplomaPopover = usePopover();

  if (diplomaEligible) {
    return (
      <div data-testid="diploma" className="cc-section-block">
        <div data-testid="paper-diploma" className="content-entry">
          <div className="header-text">
            <span>Paper Diploma</span>&nbsp;
            <ButtonAsLink onClick={paperDiplomaPopover.open}>
              <InfoIcon />
            </ButtonAsLink>
          </div>
          <div style={{ position: `relative` }}>
            {paperDiplomaPopover.isOpen && (
              <Popover
                ref={paperDiplomaPopover.ref}
                title={paperDiplomaNoticeHeading}
                close={paperDiplomaPopover.close}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: paperDiplomaNoticeMessage,
                  }}
                />
              </Popover>
            )}
            <div
              dangerouslySetInnerHTML={{
                __html: paperDiplomaMessage,
              }}
            />
          </div>
        </div>

        <div data-testid="electronic-diploma" className="content-entry">
          <div className="header-text">
            <span>Electronic Diploma</span>&nbsp;
            <ButtonAsLink onClick={electronicDiplomaPopover.open}>
              <InfoIcon />
            </ButtonAsLink>
          </div>
          <div style={{ position: `relative` }}>
            {electronicDiplomaPopover.isOpen && (
              <Popover
                ref={electronicDiplomaPopover.ref}
                title="Electronic Diploma"
                close={electronicDiplomaPopover.close}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: electronicDiplomaHelpMessage,
                  }}
                />
              </Popover>
            )}
            {!diplomaReady && (
              <div
                dangerouslySetInnerHTML={{
                  __html: electronicDiplomaNoticeMessage,
                }}
              />
            )}
            {diplomaReady && (
              <div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: electronicDiplomaReadyMessage,
                  }}
                />
                <div className="download-button-container">
                  <a
                    className="download-button"
                    href="/api/my/academics/diploma/single_sign_on"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Proceed to Download
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
  return null;
}

Diploma.displayName = "Diploma";
