import React from "react";
import { react2angular } from "react2angular";
import ReduxProvider from "components/ReduxProvider";
import AcademicPlanCard from "components/cards/AcademicPlanCard";

const NgAcademicPlanCard = () => (
  <ReduxProvider>
    <AcademicPlanCard />
  </ReduxProvider>
);

angular
  .module("calcentral.react")
  .component("academicPlanCard", react2angular(NgAcademicPlanCard));
