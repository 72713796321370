import React from "react";
import { useSelector } from "react-redux";
import { isLoaded } from "data/utils/statusHelpers";

import formatCurrency from "functions/formatCurrency";

import RedExclamationCircle from "./RedExclamationCircle";
import StatusItemLink from "./StatusItemLink";

export default function FinancesStatusItem() {
  const { financesLoadState, isWithdrawnAdmit, minimumDue } = useSelector((state) => {
    const {
      myStatus: {
        roles: {
          withdrawnAdmit: isWithdrawnAdmit,
        } = {},
      } = {},
    } = state;

    const {
      finances: {
        loadState: financesLoadState,
        summary: { amountDueNow: minimumDue = 0 } = {},
      } = {},
    } = state;

    return {
      financesLoadState,
      isWithdrawnAdmit,
      minimumDue,
    };
  });

  if (isLoaded(financesLoadState) && minimumDue > 0) {
    const myFinancesPath = "/finances";
    const billingDetailsPath = "/billing/details";

    const linkPath = isWithdrawnAdmit ? billingDetailsPath : myFinancesPath;

    return (
      <StatusItemLink to={linkPath}>
        <RedExclamationCircle />
        <div>
          <strong>Amount Due Now:</strong> {formatCurrency(minimumDue, false)}
        </div>
      </StatusItemLink>
    );
  }

  return null;
}
