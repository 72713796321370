import React from "react";
import styled from "styled-components";
import LightText from "components/ui/LightText";
import { ClockIcon } from "components/ui/Icons";

export const GreyClockIcon = styled(ClockIcon)`
  color: var(--gumbo);
`;

import { useAdvisingStudent } from "components/hooks/useAdvisingStudent";

export const ExpectedGraduationTermUndergraduate = () => {
  const {
    termsInAttendance,
    graduation: { undergraduate: undergraduateGraduationTerm } = {},
  } = useAdvisingStudent(
    (student) => student.advisingStudentAcademicProfile || {}
  );

  const {
    academicRoles: { historical: { degreeSeeking, summerVisitor } = {} } = {},
  } = useAdvisingStudent((student) => student.studentData || {});

  const isNonDegreeSeekingSummerVisitor = summerVisitor && !degreeSeeking;

  if (
    !isNonDegreeSeekingSummerVisitor &&
    (termsInAttendance || undergraduateGraduationTerm)
  ) {
    return (
      <>
        <dt>Terms Information</dt>
        <dd>
          {termsInAttendance && (
            <div>
              <LightText>Terms in Attendance</LightText>
              <div>{termsInAttendance}</div>
            </div>
          )}

          {undergraduateGraduationTerm &&
            undergraduateGraduationTerm.termName && (
              <div>
                <LightText>Expected Graduation</LightText>
                <span>
                  {undergraduateGraduationTerm.appointmentsInGraduatingTerm && (
                    <GreyClockIcon />
                  )}
                  <strong>
                    <span>{undergraduateGraduationTerm.termName}</span>
                  </strong>
                </span>
              </div>
            )}
        </dd>
      </>
    );
  }
  return null;
};
ExpectedGraduationTermUndergraduate.displayName =
  "ExpectedGraduationTermUndergraduate";
