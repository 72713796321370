import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import styled from "styled-components";
import APILink from "components/ui/APILink";

export function SectionIncompleteGradingStatus({
  frozenDisplayColumnIndex,
  lapseDateDisplayColumnIndex,
  section,
  totalColumns = 0,
}) {
  const { academicGuideGradesPolicyLink } = useSelector((state) => {
    const {
      apiLinks: { UC_CX_ACAD_GUIDE_GRADES: academicGuideGradesPolicyLink } = {},
    } = state;

    return {
      academicGuideGradesPolicyLink,
    };
  });

  const {
    grading: {
      gradingBasis,
      gradingLapseDeadline,
      gradingLapseDeadlineDisplay = false,
    },
    is_primary_section: isPrimarySection = false,
  } = section;

  const displayGradingStatus =
    isPrimarySection &&
    ((gradingLapseDeadlineDisplay && gradingLapseDeadline) ||
      gradingBasis === "FRZ");

  let columnIndexes = [];
  if (totalColumns) {
    columnIndexes = [...Array(totalColumns).keys()];
  }

  const showGradingLapseDeadline =
    gradingLapseDeadlineDisplay && gradingLapseDeadline;
  const gradingBasisIsFrozen = gradingBasis === "FRZ";
  const showSingleColumn =
    (showGradingLapseDeadline && lapseDateDisplayColumnIndex === 0) ||
    (gradingBasisIsFrozen && frozenDisplayColumnIndex === 0);
  const displayColumnIndex =
    (showGradingLapseDeadline && lapseDateDisplayColumnIndex) ||
    (gradingBasisIsFrozen && frozenDisplayColumnIndex);

  if (displayGradingStatus) {
    return (
      <ContainerRowStyles>
        {showSingleColumn &&
          (showGradingLapseDeadline || gradingBasisIsFrozen) && (
            <td colSpan={totalColumns}>
              {showGradingLapseDeadline && (
                <>
                  <APILink
                    link={academicGuideGradesPolicyLink}
                    text={
                      "Student Completion Deadline is 30 days before " +
                      gradingLapseDeadline
                    }
                  />
                </>
              )}
              {gradingBasisIsFrozen && (
                <APILink link={academicGuideGradesPolicyLink} text={"Frozen"} />
              )}
            </td>
          )}
        {!showSingleColumn &&
          (showGradingLapseDeadline || gradingBasisIsFrozen) &&
          columnIndexes.map((col) => (
            <td key={col}>
              {showGradingLapseDeadline && col === displayColumnIndex && (
                <>
                  <APILink
                    link={academicGuideGradesPolicyLink}
                    text={
                      "Student Completion Deadline is 30 days before " +
                      gradingLapseDeadline
                    }
                  />
                </>
              )}
              {gradingBasisIsFrozen && col === displayColumnIndex && (
                <>
                  <APILink
                    link={academicGuideGradesPolicyLink}
                    text={"Frozen"}
                  />
                </>
              )}
              {col !== displayColumnIndex && <span>&nbsp;</span>}
            </td>
          ))}
      </ContainerRowStyles>
    );
  } else {
    return null;
  }
}

SectionIncompleteGradingStatus.displayName = "SectionIncompleteGradingStatus";
SectionIncompleteGradingStatus.propTypes = {
  frozenDisplayColumnIndex: PropTypes.number,
  lapseDateDisplayColumnIndex: PropTypes.number,
  section: PropTypes.object,
  totalColumns: PropTypes.number,
};

const ContainerRowStyles = styled.tr`
  td {
    padding-bottom: 10px;
    padding-top: 0;
  }
`;
