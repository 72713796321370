import { OPTION_ACCEPT } from "../statusProps";

export const validate = (sirConditions) => (values) => {
  let errors = {};

  if (values.progAction === false) {
    errors.progAction = "Must be selected";
  }

  if (
    allChecked(sirConditions)(values.progAction, values.conditions) === false
  ) {
    errors.conditions = "All conditions must be checked";
  }

  return errors;
};

const allChecked = (sirConditions) => (option, conditions) => {
  if (option === OPTION_ACCEPT) {
    const checkedConditions = conditions.toSorted().join();
    const conditionSequenceNumbers = sirConditions
      .map((condition) => condition.seqnum)
      .toSorted()
      .join();

    return checkedConditions === conditionSequenceNumbers;
  }

  return true;
};
