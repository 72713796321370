import React from "react";
import PropTypes from "prop-types";
import { SortIndicator } from "components/ui/SortIndicator";

export function SortableButton({ column, sortable, children }) {
  return (
    <button onClick={updateSortable(column, sortable)}>
      {children}{" "}
      <SortIndicator
        column={column}
        sortColumn={sortable.column}
        sortDirection={sortable.direction}
      />
    </button>
  );
}

SortableButton.propTypes = {
  column: PropTypes.string,
  sortable: PropTypes.shape({
    column: PropTypes.string,
    direction: PropTypes.oneOf(["ASC", "DESC"]),
    setColumn: PropTypes.func,
    setDirection: PropTypes.func,
  }),
  children: PropTypes.node,
};

// If the clicked column hasn't changed, just toggle the direction of the sort
// otherwise set the new sort column and default to ASCending
const updateSortable = (column, sortable) => (_event) => {
  if (column === sortable.column) {
    sortable.setDirection(sortable.direction === "ASC" ? "DESC" : "ASC");
  } else {
    sortable.setColumn(column);
    sortable.setDirection("ASC");
  }
};
