import React from "react";
import PropTypes from "prop-types";
import VisuallyHidden from "components/ui/VisuallyHidden";

export function MidpointGradingStatus({ status }) {
  const description =
    midpointGradingDesc[status] || midpointGradingDesc["default"];
  const classNames =
    midpointGradingClassNames[status] || midpointGradingClassNames["default"];
  const style =
    midpointGradingStyles[status] || midpointGradingStyles["default"];

  return (
    <>
      <i className={classNames} aria-hidden="true" style={style}></i>
      <VisuallyHidden>{description}</VisuallyHidden>
    </>
  );
}

MidpointGradingStatus.propTypes = {
  status: PropTypes.string,
};

const midpointGradingClassNames = {
  periodNotStarted: "fa fa-ban",
  periodStarted: "fa fa-file-o",
  gradesPosted: "fa fa-check-circle",
  default: "fa fa-circle",
};

const midpointGradingDesc = {
  periodNotStarted: "Grade entry period not open",
  periodStarted: "Grade entry period open; grades not approved or posted",
  gradesPosted:
    "Grades posted. Note: All classes, with or without midpoint grades, will post",
  default:
    "Law class (no midpoint grades) OR you do not have grading permissions",
};

const midpointGradingStyles = {
  gradesPosted: { color: `var(--jewel)` },
  default: {},
};
