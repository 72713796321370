import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import StyledMessage from "components/ui/StyledMessage";

function MissingAppointmentMessage({ message }) {
  return (
    <StyledMessage>
      <div dangerouslySetInnerHTML={{ __html: message.descrlong }} />
    </StyledMessage>
  );
}

MissingAppointmentMessage.displayName = "MissingAppointmentMessage";
MissingAppointmentMessage.propTypes = {
  message: PropTypes.shape({
    descrlong: PropTypes.string,
  }),
};

function mapStateToProps({ currentUID, users }) {
  const {
    enrollmentResources: { messages: { missingAppointment } = {} } = {},
  } = users[currentUID] || {};

  return { message: missingAppointment };
}

export default connect(mapStateToProps)(MissingAppointmentMessage);
