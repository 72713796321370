import React from "react";
import { presentationShape, deadlineShape } from "../propTypes";

import TimeCell from "../TimeCell";
import DeadlinesTable from "./DeadlinesTable";

function DeadlineRowsLaw({ deadline }) {
  const { addDeadline } = deadline;
  return (
    <tr>
      <td>Law Add/Drop</td>
      <td>
        <TimeCell time={addDeadline} />
      </td>
    </tr>
  );
}

DeadlineRowsLaw.displayName = "DeadlineRowsLaw";
DeadlineRowsLaw.propTypes = {
  deadline: deadlineShape,
};

export default function Deadlines({
  presentation: { constraints: { deadlines = [] } = {} },
}) {
  if (deadlines.length) {
    return (
      <DeadlinesTable>
        {deadlines.map((deadline) => (
          <DeadlineRowsLaw key={deadline.session} deadline={deadline} />
        ))}
      </DeadlinesTable>
    );
  }

  return null;
}

Deadlines.displayName = "LawEnrollmentDeadlines";
Deadlines.propTypes = {
  presentation: presentationShape,
};
