import React, { useState } from "react";
import PropTypes from "prop-types";

import ellipsize from "ellipsize";

import ClassLabel from "./ClassLabel";
import ClassGrade from "./ClassGrade";
import ClassSchedules from "./ClassSchedules";
import GradingLapseDeadline from "./GradingLapseDeadline/GradingLapseDeadline";
import MidpointGradeNotice from "./MidpointGradeNotice";

import styled from "styled-components";

const SectionRow = styled.tr`
  margin-top: 8px;

  td {
    font-size: 13px;
    vertical-align: top;
  }
`;

const TitleCell = styled.td`
  padding: 12px 0px 2px;
`;

const UnitsCell = styled.td`
  text-align: center;
  padding: 12px 0px 2px;
  width: 25px;
`;

const GradeCell = styled.td`
  text-align: right;
  padding: 12px 0px 2px 12px;
  width: 45px;

  button {
    outline: none;
  }
`;

const SectionRows = ({
  canViewGrades,
  isAdvisingView,
  isInactiveTerm,
  semesterClass,
  section,
}) => {
  if (!section) {
    return null;
  }

  const [midpointGradeExpanded, setMidpointGradeExpanded] = useState(false);

  return (
    <React.Fragment key={section.ccn}>
      {section.is_primary_section &&
        !section.waitlisted &&
        !semesterClass.transcript && (
          <>
            <SectionRow data-testid="semester-class-section-row">
              <ClassLabel
                isAdvisingView={isAdvisingView}
                section={section}
                semesterClass={semesterClass}
              />
              <TitleCell>
                <div data-testid="semester-class-section-row-title">
                  {!isInactiveTerm && ellipsize(semesterClass.title, 28)}
                  {isInactiveTerm && semesterClass.title}
                </div>
                <ClassSchedules
                  section={section}
                  semesterClass={semesterClass}
                  isInactiveTerm={isInactiveTerm}
                />
              </TitleCell>
              <UnitsCell data-testid="semester-class-section-row-units">
                {section.units && (
                  <span>{Number.parseFloat(section.units).toFixed(1)}</span>
                )}
              </UnitsCell>
              <GradeCell data-testid="semester-class-section-row-grade">
                <ClassGrade
                  canViewGrades={canViewGrades}
                  midpointGradeExpanded={midpointGradeExpanded}
                  section={section}
                  setMidpointGradeExpanded={setMidpointGradeExpanded}
                />
              </GradeCell>
            </SectionRow>
            <GradingLapseDeadline
              section={section}
              frozenDisplayColumnIndex={3}
              lapseDateDisplayColumnIndex={0}
              totalColumns={4}
            />
            <MidpointGradeNotice
              midpointGradeExpanded={midpointGradeExpanded}
              setMidpointGradeExpanded={setMidpointGradeExpanded}
              section={section}
            />
          </>
        )}
    </React.Fragment>
  );
};

SectionRows.displayName = "SectionRows";
SectionRows.propTypes = {
  canViewGrades: PropTypes.bool,
  isAdvisingView: PropTypes.bool,
  isInactiveTerm: PropTypes.bool,
  section: PropTypes.object,
  semesterClass: PropTypes.object,
};

export default SectionRows;
