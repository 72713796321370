import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, CardTitle } from "components/ui/Card";
import Spinner from "components/../react/components/Spinner";
import {
  TasksByCategory,
  CompletedTasksByCategory,
} from "./TasksCard/TasksByCategory";
import { TaskTabs } from "./TasksCard/Switcher";
import { Tabs, TabPanels, TabPanel } from "components/ui/Tabs";
import { getAgreements } from "data/slices/users/agreements";
import { getBCoursesTodos } from "data/slices/users/bCoursesTodos";
import { getChecklistItems } from "data/slices/users/checklistItems";
import currentUserState from "utils/currentUserState";
import { useCurrentUID } from "../hooks/useCurrentUID";

export function TasksCard() {
  const dispatch = useDispatch();
  const currentUID = useCurrentUID();

  const { loaded } = useSelector((state) => {
    const {
      agreements: { loadState: agreementsLoadState } = {},
      checklistItems: { loadState: checklistLoadState } = {},
      bCoursesTodos: { loadState: bCoursesTodosLoadState } = {},
    } = currentUserState(state);

    const loaded =
      agreementsLoadState === "success" &&
      checklistLoadState === "success" &&
      bCoursesTodosLoadState === "success";

    return {
      loaded,
    };
  });

  useEffect(() => {
    if (currentUID) {
      dispatch(getBCoursesTodos(currentUID));
      dispatch(getAgreements(currentUID));
      dispatch(getChecklistItems(currentUID));
    }
  }, [dispatch, currentUID]);

  return (
    <Card id="tasks-card" data-testid="tasks-card">
      <CardTitle>
        <h2>Tasks</h2>
      </CardTitle>

      {loaded ? (
        <Tabs>
          <TaskTabs />
          <TabPanels>
            <TabPanel>
              <TasksByCategory />
            </TabPanel>
            <TabPanel>
              <CompletedTasksByCategory />
            </TabPanel>
          </TabPanels>
        </Tabs>
      ) : (
        <CardBody>
          <Spinner />
        </CardBody>
      )}
    </Card>
  );
}
TasksCard.displayName = "TasksCard";
