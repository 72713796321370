import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import ClassInformationCard from "./ClassInformationCard";
import { fetchCourseInfo } from "data/slices/courses/courseInfo";
import { useCourseParams } from "components/hooks/useCourseParams";
import { useSemester } from "components/hooks/useSemester";
import { useCourse } from "components/hooks/useCourse";

export default function ClassInformationContainer() {
  const dispatch = useDispatch();
  const { classId } = useCourseParams();
  const semester = useSemester();
  const termId = semester?.termId;
  const course = useCourse();

  useEffect(() => {
    if (course) {
      dispatch(fetchCourseInfo(course));
    }
  }, [dispatch, course]);

  const { loadState, sections = [] } =
    useSelector((state) => {
      if (classId) {
        const { courseInfo = {} } = state.byCourse[classId] || {};
        return courseInfo;
      }
    }) || {};

  const courseWithSections = {
    ...course,
    sections: (course?.sections || []).map((section) => ({
      ...section,
      ...sections.find((sec) => sec.ccn === section.ccn),
    })),
  };

  return (
    <ClassInformationCard
      loaded={loadState === "success"}
      termId={termId}
      course={courseWithSections}
    />
  );
}
