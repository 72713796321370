import React from "react";
import PropTypes from "prop-types";

import Card from "components/../react/components/Card";

import ClassAttributesTable from "./ClassAttributesTable";
import InstructionalSection from "./InstructionalSection";
import VerticalSpacer from "components/../react/components/VerticalSpacer";
import ClassInfoNotice from "../ClassInfoNotice";
import FinalExams from "./FinalExams";

import styles from "./ClassInformationCard.module.scss";
import CrossListings from "./CrossListings";
import { useCourseParams } from "components/hooks/useCourseParams";

export default function ClassInformationCard({
  loaded,
  termId,
  course: {
    title,
    units,
    role,
    gradingBasis,
    classNotesLink,
    sections,
    slug,
    listings,
    courseCareerCode,
  },
}) {
  const { isInstructor } = useCourseParams();

  return (
    <Card
      title="Class Information"
      loading={!loaded}
      style={{ marginRight: `15px` }}
    >
      <VerticalSpacer />
      <ClassInfoNotice termId={termId} />

      <h1 className={styles.title}>{title}</h1>

      <ClassAttributesTable
        role={role}
        units={units}
        gradingBasis={gradingBasis}
        classNotesLink={classNotesLink}
        isLaw={courseCareerCode === "LAW"}
        slug={slug}
        isInstructor={isInstructor}
        sections={sections}
      />

      <hr />

      <CrossListings listings={listings} />

      {sections.map((section, index) => (
        <InstructionalSection
          key={index}
          section={section}
          isInstructor={isInstructor}
        />
      ))}

      <FinalExams sections={sections} />
    </Card>
  );
}

ClassInformationCard.displayName = "ClassInformationCard";

ClassInformationCard.propTypes = {
  loaded: PropTypes.bool,
  termId: PropTypes.string,
  course: PropTypes.shape({
    title: PropTypes.string,
    isLaw: PropTypes.bool,
    units: PropTypes.number,
    sections: PropTypes.array,
    role: PropTypes.string,
    gradingBasis: PropTypes.string,
    classNotesLink: PropTypes.string,
    slug: PropTypes.string,
    listings: PropTypes.array,
    courseCareerCode: PropTypes.string,
  }),
};
