export const sortableSort = (sortable) => (objectA, objectB) => {
  const valueA = objectA[sortable.column];
  const valueB = objectB[sortable.column];

  const isAscending = sortable.direction === "ASC";

  if (valueA > valueB) {
    return isAscending ? 1 : -1;
  }

  if (valueA < valueB) {
    return isAscending ? -1 : 1;
  }

  return 0;
};
