import { useState } from "react";

export function useAccordionIndices(initialState = []) {
  const [indices, setIndices] = useState(initialState);

  function toggleItem(toggledIndex) {
    if (indices.includes(toggledIndex)) {
      setIndices(
        indices.filter((currentIndex) => currentIndex !== toggledIndex)
      );
    } else {
      setIndices([...indices, toggledIndex].sort());
    }
  }

  return [indices, toggleItem];
}
