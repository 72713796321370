import React from "react";
import PropTypes from "prop-types";

export function SortIndicator({ column, sortColumn, sortDirection }) {
  if (column === sortColumn) {
    if (sortDirection === "ASC") {
      return <span className="fa fa-caret-down" />;
    } else if (sortDirection === "DESC") {
      return <span className="fa fa-caret-up" />;
    }
  }
  return null;
}

SortIndicator.propTypes = {
  column: PropTypes.string,
  sortColumn: PropTypes.string,
  sortDirection: PropTypes.string,
};
