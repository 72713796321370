import React from "react";
import PropTypes from "prop-types";
import { Card } from "components/ui/Card";
import { useSlice } from "components/hooks/useSlice";
import { StatusHeader } from "./StatusHeader";
import { statusProps } from "./statusProps";

import { parseISO } from "date-fns";
import { longDate } from "functions/formatDate";
import { formatCurrency } from "./formatCurrency";
import { PayDepositButton } from "./PayDepositButton";

export function StatusReceived({ status }) {
  const { canActOnFinances } = useSlice("myStatus");
  const {
    isUndergraduate,
    header,
    deposit: { required: depositRequired, dueAmt, dueDt } = {},
    config: { descrProgramLong } = {},
  } = status;

  return (
    <Card>
      <StatusHeader header={header} />
      <div className="cc-widget-text">
        {depositRequired ? (
          <div>
            <h3>Deposit Required for {descrProgramLong}</h3>

            {isUndergraduate ? (
              <UndergradDepositRequired dueAmt={dueAmt} dueDt={dueDt} />
            ) : (
              <NonUndergradDepositRequired dueAmt={dueAmt} dueDt={dueDt} />
            )}

            <div style={{ textAlign: `center`, marginTop: `var(--md)` }}>
              {canActOnFinances ? (
                <PayDepositButton />
              ) : (
                <div>
                  <i className="fa fa-exclamation-triangle cc-icon-gold"></i>{" "}
                  You are not allowed to pay the deposit when viewing as.
                </div>
              )}
            </div>
          </div>
        ) : (
          <div>
            <h3>SIR received for {descrProgramLong}</h3>
            <div>
              Your Statement of Intent to Register (SIR) has been received
              successfully. We are currently updating your SIR status in our
              system. Soon you will see additional tasks in CalCentral. Please
              check back periodically to see important messages and to complete
              new applicant tasks.
            </div>
          </div>
        )}
      </div>
    </Card>
  );
}
StatusReceived.propTypes = statusProps;

function NonUndergradDepositRequired({ dueAmt, dueDt }) {
  return (
    <div>
      Thank you for submitting your Statement of Intent to Register (SIR). Your
      program requires a deposit of <strong>{formatCurrency(dueAmt)}</strong> by{" "}
      {longDate(parseISO(dueDt))}. Your payment will need time to process and
      may not be reflected immediately in your account.
    </div>
  );
}
NonUndergradDepositRequired.propTypes = {
  dueAmt: PropTypes.string,
  dueDt: PropTypes.string,
};

function UndergradDepositRequired({ dueAmt, dueDt }) {
  return (
    <div>
      To save your seat, you must pay a non-refundable deposit of{" "}
      <strong>{formatCurrency(dueAmt)}</strong> by{" "}
      <strong>{longDate(parseISO(dueDt))}</strong> (11:59pm PST).
      <br />
      <br />
      Your payment may need time to process and may not be reflected immediately
      in your account.
    </div>
  );
}
UndergradDepositRequired.propTypes = {
  dueAmt: PropTypes.string,
  dueDt: PropTypes.string,
};
