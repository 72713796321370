import React from "react";
import { react2angular } from "react2angular";
import { ReduxProvider } from "components/data/ReduxProvider";
import { LawDegreeAuditCard } from "components/cards/LawDegreeAuditCard";

const NgLawDegreeAuditCard = () => (
  <ReduxProvider>
    <LawDegreeAuditCard />
  </ReduxProvider>
);

angular
  .module("calcentral.react")
  .component("lawDegreeAuditCard", react2angular(NgLawDegreeAuditCard));
