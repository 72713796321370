import React from "react";
import {
  AccordionButton,
  AccordionItem,
  AccordionPanel,
} from "components/ui/AccordionModern";
import EnrolledClassesTable from "./EnrolledClassesTable";
import WaitlistedClassesTable from "./WaitlistedClassesTable";
import { ClassesTable } from "./ClassesTable";

import { semesterShape } from "../../propTypes/semesterShape";

export function PlanSemester({ semester }) {
  const {
    name,
    notation,
    hasWithdrawalData,
    hasEnrolledClasses,
    hasStandingData,
    hasStudyProgData,
    hasWaitlisted,
    studyProg,
  } = semester;

  // Destructure standing and withdrawl data, even if the objects are "null"
  const { withcnclTypeDescr, withcnclFromDate, withcnclReasonDescr } =
    semester.withdrawalStatus || {};

  const {
    statusDescription,
    effectiveDateFormatted,
    actionDescription,
    statusCode,
  } = semester.standing || {};

  return (
    <AccordionItem>
      <AccordionButton style={{ padding: `10px 0` }}>
        <h3>
          {name}{" "}
          {notation && (
            <span style={{ color: `var(--thunderbird)` }}>{notation}</span>
          )}
        </h3>
      </AccordionButton>
      <AccordionPanel style={{ paddingBottom: `var(--lg)` }}>
        <table>
          <tbody>
            {hasWithdrawalData && (
              <tr>
                <td></td>
                <td>
                  {withcnclTypeDescr} {withcnclFromDate} (Reason:{" "}
                  {withcnclReasonDescr})
                </td>
              </tr>
            )}

            {hasStandingData && statusCode !== "GST" && (
              <tr>
                <td>
                  <i
                    className="fa fa-exclamation-circle"
                    aria-hidden="true"
                    style={{ color: `var(--thunderbird)` }}
                  ></i>
                </td>
                <td>
                  <div>
                    {statusDescription}{" "}
                    <span className="cc-academic-plan-card-sub-title cc-italic">
                      Updated {effectiveDateFormatted}
                    </span>
                  </div>
                  <div className="cc-academic-plan-card-sub-title">
                    {actionDescription}
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>

        {hasStudyProgData && <div>{studyProg.studyprogTypeDescr}</div>}

        {(hasEnrolledClasses || hasWaitlisted) && (
          <ClassesTable style={{ width: `100%` }}>
            {hasEnrolledClasses && <EnrolledClassesTable semester={semester} />}
            {hasWaitlisted && <WaitlistedClassesTable semester={semester} />}
          </ClassesTable>
        )}
      </AccordionPanel>
    </AccordionItem>
  );
}

PlanSemester.displayName = "PlanSemester";
PlanSemester.propTypes = {
  semester: semesterShape,
};
