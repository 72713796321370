import React from "react";

import { ReduxProvider } from "components/data/ReduxProvider";
import { react2angular } from "react2angular";
import { DelegateQuickLinksCard } from "components/cards/DelegateQuickLinksCard";

const NgDelegateQuickLinksCard = () => {
  return (
    <ReduxProvider>
      <DelegateQuickLinksCard />
    </ReduxProvider>
  );
};

angular
  .module("calcentral.react")
  .component("delegateQuickLinksCard", react2angular(NgDelegateQuickLinksCard));
