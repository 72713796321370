import React, { useState } from "react";
import PropTypes from "prop-types";

import { presentationShape, termInstructionShape } from "./propTypes";

import { MediumSpacer } from "components/ui/VerticalSpacers";

import { termFromId } from "components/../react/helpers/terms";
import EnrollmentCard from "./EnrollmentCard";

import Deadlines from "./LawEnrollment/Deadlines";
import SummerDeadlines from "./LawEnrollment/SummerDeadlines";

import TermLabel from "./TermLabel";
import EnrollmentCenterButton from "./EnrollmentCenterButton";
import EnrollmentInfoExpander from "./EnrollmentInfoExpander";

import EnrollmentInfoLaw from "./EnrollmentInfoLaw";
import SummerLawEnrollmentInfo from "./SummerLawEnrollmentInfo";

import EnrollmentPeriods from "./LawEnrollment/EnrollmentPeriods";
import SummerEnrollmentPeriods from "./LawEnrollment/SummerEnrollmentPeriods";

function LawEnrollmentCard({ presentations = [], termInstructions = [] }) {
  const [enrollmentInfoExpanded, setEnrollmentInfoExpanded] = useState(true); // TODO: default to false after the deadline..

  return (
    <EnrollmentCard
      presentations={presentations}
      termInstructions={termInstructions}
      testid="law-enrollment-card"
    >
      {({ presentation, enrollmentPeriods }) => {
        const termId = presentation.termId;
        const { semester } = termFromId(termId);
        const isSummer = semester === "Summer";
        const { constraints: { maxUnits } = {} } = presentation;

        return (
          <>
            <TermLabel termId={termId} />
            <MediumSpacer />

            {isSummer ? (
              <SummerEnrollmentPeriods
                enrollmentPeriods={enrollmentPeriods}
                maxUnits={maxUnits}
              />
            ) : (
              <EnrollmentPeriods enrollmentPeriods={enrollmentPeriods} />
            )}

            <MediumSpacer />

            {isSummer ? (
              <SummerDeadlines presentation={presentation} />
            ) : (
              <Deadlines presentation={presentation} />
            )}

            <div
              style={{
                display: `flex`,
                justifyContent: `center`,
                marginTop: `15px`,
              }}
            >
              <EnrollmentCenterButton />
            </div>

            <hr />

            {isSummer ? (
              <SummerLawEnrollmentInfo />
            ) : (
              <>
                <EnrollmentInfoExpander
                  title="Enrollment Information"
                  onClick={() =>
                    setEnrollmentInfoExpanded(!enrollmentInfoExpanded)
                  }
                  expanded={enrollmentInfoExpanded}
                />

                {enrollmentInfoExpanded && <EnrollmentInfoLaw />}
              </>
            )}
          </>
        );
      }}
    </EnrollmentCard>
  );
}

LawEnrollmentCard.displayName = "LawEnrollmentCard";
LawEnrollmentCard.propTypes = {
  presentations: PropTypes.arrayOf(presentationShape),
  termInstructions: PropTypes.arrayOf(termInstructionShape),
};

export default LawEnrollmentCard;
