import React from "react";
import Pipe from "components/ui/Pipe";
import WithAPILink from "components/ui/WithAPILink";
import HasAccessTo from "./HasAccessTo";
import ItemIfAccess from "./ItemIfAccess";

export default function EmergencyLoans() {
  return (
    <ItemIfAccess urlID="UC_FA_FINRES_EMERG_LOAN">
      <WithAPILink urlID="UC_FA_FINRES_EMERG_LOAN" />
      <HasAccessTo urlIDs={["UC_FA_FINRES_EMERG_LOAN_APPLY"]}>
        <Pipe />
        <strong>
          <WithAPILink urlID="UC_FA_FINRES_EMERG_LOAN_APPLY" />
        </strong>
      </HasAccessTo>
    </ItemIfAccess>
  );
}
EmergencyLoans.displayName = "EmergencyLoans";
