import styled from "styled-components";
import { DefinitionList } from "components/ui/DefinitionList";

export const SubList = styled(DefinitionList)`
  display: grid;
  grid-template-columns: 50% 50%;

  dt {
    font-weight: bold;
    color: var(--dove-gray);
  }

  dd {
    margin: 0;
  }
`;
