import styled from "styled-components";
import {
  Accordion as ReachAccordion,
  AccordionButton as ReachAccordionButtion,
  AccordionItem as ReachAccordionItem,
  AccordionPanel as ReachAccordionPanel,
} from "@reach/accordion";
import "@reach/accordion/styles.css";

import chevronUp from "../../icons/chevron-up.svg";
import chevronDown from "../../icons/chevron-down.svg";

// Note: Use of ChevronAccordion is recommended
export const Accordion = styled(ReachAccordion)``;

export const AccordionButton = styled(ReachAccordionButtion)`
  border: none;
  background: transparent;
  cursor: pointer;
  text-align: left;
  width: 100%;
  color: var(--base-color);

  background-image: url("${chevronDown}");
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: center;

  &[data-state="open"] {
    background-image: url("${chevronUp}");
  }
`;
AccordionButton.displayName = "AccordionModern Button";

export const AccordionItem = styled(ReachAccordionItem)`
  & + & {
    border-top: var(--border-hairline);
  }
`;
AccordionItem.displayName = "AccordionModern Item";

export const AccordionPanel = ReachAccordionPanel;
AccordionPanel.displayName = "AccordionModern Panel";
