import React, { useEffect } from "react";

import AdvisingCard from "./AdvisingCard/AdvisingCard";

import { useDispatch, useSelector } from "react-redux";

import advisingStudentState from "utils/advisingStudentState";
import { fetchAdvisingAcademicAdvising } from "data/slices/users/advisingAcademicAdvising";

export default function AdvisorAcademicAdvisingCard() {
  const dispatch = useDispatch();

  const {
    actionItems,
    advisors,
    advisingLinksEnabled,
    advisingStudentUid,
    currentStudentAcademicRoles,
    isDirectlyAuthenticated,
    isErrored,
    links,
    loadState,
    showAdvising,
  } = useSelector((state) => {

    const {
      advising: {
        userId: advisingStudentUid,
      },
    } = state;

    const {
      advisingStudent: {
        academicRoles: { current: currentStudentAcademicRoles } = {},
      } = {},
      advisingAcademicAdvising: {
        errored: isErrored,
        feed: { actionItems, advisors, links } = {},
        loadState,
      } = {},
    } = advisingStudentState(state, advisingStudentUid) || {};

    const { summerVisitor: studentIsCurrentSummerVisitor } =
      currentStudentAcademicRoles || {};

    const {
      myStatus: {
        features: {
          advising: advisingFeatureEnabled,
          csAdvisingLinks: advisingLinksEnabled,
        } = {},
      } = {},
      isDirectlyAuthenticated,
    } = state;

    const showAdvising =
      !studentIsCurrentSummerVisitor && advisingFeatureEnabled;

    return {
      actionItems,
      advisors,
      advisingFeatureEnabled,
      advisingLinksEnabled,
      advisingStudentUid,
      currentStudentAcademicRoles,
      isDirectlyAuthenticated,
      isErrored,
      links,
      loadState,
      showAdvising,
    };
  });

  useEffect(() => {
    if (advisingStudentUid) {
      dispatch(fetchAdvisingAcademicAdvising(advisingStudentUid));
    }
  }, [dispatch, advisingStudentUid]);

  if (showAdvising) {
    return (
      <AdvisingCard
        actionItems={actionItems}
        advisors={advisors}
        advisingLinksEnabled={advisingLinksEnabled}
        currentStudentAcademicRoles={currentStudentAcademicRoles}
        id="advisor-academic-advising-card"
        isAdvisorLookup={true}
        isErrored={isErrored}
        isDirectlyAuthenticated={isDirectlyAuthenticated}
        links={links}
        loadState={loadState}
        showAdvising={showAdvising}
        testid="advisor-academic-advising-card"
      />
    );
  } else {
    return null;
  }
}

AdvisorAcademicAdvisingCard.displayName = "AdvisorAcademicAdvisingCard";
