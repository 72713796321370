import React from "react";
import { useAdvisingStudent } from "components/hooks/useAdvisingStudent";

export const LdapUID = () => {
  const { uid = "" } = useAdvisingStudent(
    (student) => student.advisingStudentProfile
  );

  if (uid) {
    return (
      <>
        <dt>UID</dt>
        <dd>
          <strong>{uid}</strong>
        </dd>
      </>
    );
  }
  return null;
};
LdapUID.displayName = "LdapUID";
