import React, { useContext } from "react";
import PropTypes from "prop-types";
import ComparisonRow from "./ComparisonRow";

import SelectedDateContext from "./SelectedDateContext";

import "./AwardComparison.scss";

export const NumberComparisonRow = ({ description, current, snapshot }) => {
  const { selectedDate: selectedDate } = useContext(SelectedDateContext);
  const formattedCurrentValue =
    current || current === 0 ? current.toLocaleString() : "N/A";

  const formattedSnapshotValue =
    snapshot || snapshot === 0
      ? snapshot.toLocaleString()
      : selectedDate === "X"
      ? null
      : "N/A";

  return (
    <ComparisonRow
      description={description}
      current={formattedCurrentValue}
      snapshot={formattedSnapshotValue}
    />
  );
};

NumberComparisonRow.displayName = "AwardComparisonNumberComparisonRow";
NumberComparisonRow.propTypes = {
  description: PropTypes.string.isRequired,
  current: PropTypes.number,
  snapshot: PropTypes.number,
};
