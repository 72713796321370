import React from "react";
import { RequireDelegate } from "components/RequireDelegate";
import { BulletList } from "components/ui/Lists";
import { Card, CardBody, CardTitle } from "components/ui/Card";
import { ExternalLink } from "components/ui/ExternalLink";

export function AcademicDatesCard() {
  return (
    <RequireDelegate>
      <Card data-testid="academic-dates-card">
        <CardTitle>
          <h2>Academic Dates &amp; Deadlines</h2>
        </CardTitle>
        <CardBody>
          <BulletList>
            <li>
              <ExternalLink href="http://registrar.berkeley.edu/calendar">
                View the Academic Calendar
              </ExternalLink>
            </li>
            <li>
              <ExternalLink href="http://registrar.berkeley.edu/add-academic-calendar-bcal">
                Subscribe to the Academic Calendar
              </ExternalLink>
            </li>
            <li>
              <ExternalLink href="http://grad.berkeley.edu/academic-progress/deadlines/">
                Graduate Division Degree Deadlines
              </ExternalLink>
            </li>
          </BulletList>
        </CardBody>
      </Card>
    </RequireDelegate>
  );
}
