import React from "react";

import { PronounHeading } from "components/cards/AcademicProfileCard/Pronouns";

import { useSelector } from "react-redux";
import advisingStudentState from "utils/advisingStudentState";

export const Pronouns = () => {
  const { pronouns } = useSelector((state) => {
    const { advising: { userId: advisingStudentUID } = {} } = state;

    const { advisingStudentProfile: { pronouns } = {} } =
      advisingStudentState(state, advisingStudentUID) || {};

    return {
      pronouns,
    };
  });

  if (pronouns) {
    return <PronounHeading>pronouns: {pronouns}</PronounHeading>;
  }
  return null;
};
Pronouns.displayName = "Pronouns";
