import React from "react";
import PropTypes from "prop-types";
import WithAPILink from "components/ui/WithAPILink";
import HasAccessTo from "./HasAccessTo";

export default function ItemIfAccess({ children, urlID }) {
  return (
    <HasAccessTo urlIDs={[urlID]}>
      <li>{children ? children : <WithAPILink urlID={urlID} />}</li>
    </HasAccessTo>
  );
}

ItemIfAccess.displayName = "ItemIfAccess";
ItemIfAccess.propTypes = {
  urlID: PropTypes.string.isRequired,
  children: PropTypes.node,
};
