import React from "react";
import { useUser } from "components/hooks/useUser";

export const CollegeAndLevelError = () => {
  const { ihubPersonServerErrored: errored = false } = useUser(
    (user) => user.academicProfile
  );

  if (errored) {
    return (
      <div data-testid="academic-profile-error">
        There was a problem reaching campus services.
      </div>
    );
  }

  return null;
};

CollegeAndLevelError.displayName = "CollegeAndLevelError";
