import React from "react";
import { useSelector } from "react-redux";
import { Accordion } from "components/ui/Accordion";
import { isPast, parseDate } from "functions/formatDate";
import { endOfDay } from "date-fns";
import currentUserState from "utils/currentUserState";
import { Category } from "./Category";
import {
  BCoursesTasks,
  FinancialAidTasks,
  OverdueTasks,
  CompletedFinancesTasks,
  CompletedAgreements,
  CompletedCategoryTasks,
} from "./Categories";
import { useAccordionIndices } from "components/hooks/useAccordionIndices";

export function TasksByCategory() {
  const [indices, toggleItem] = useAccordionIndices([]);
  const tasks = useSelector((state) => {
    const {
      agreements: { incompleteAgreements = [] } = {},
      bCoursesTodos: { bCoursesTodos = [] } = {},
      checklistItems: { incompleteItems = [] } = {},
    } = currentUserState(state);

    const tasks = [
      ...incompleteItems,
      ...incompleteAgreements,
      ...bCoursesTodos,
    ].map(markOverdue);

    return tasks;
  });

  const {
    admissions,
    bCourses,
    financialAid,
    newStudent,
    overdue,
    residency,
    student,
  } = tasks.reduce(taskCategoryReducer, {});

  return (
    <Accordion
      data-testid="tasks-by-category"
      index={indices}
      onChange={toggleItem}
    >
      <OverdueTasks indices={indices} tasks={overdue} />
      <Category
        indices={indices}
        title="Admission Tasks"
        tasks={admissions}
        testid="admission-tasks-category"
      />
      <Category
        indices={indices}
        title="Residency Tasks"
        tasks={residency}
        testid="residency-tasks-category"
      />
      <FinancialAidTasks indices={indices} tasks={financialAid} />
      <Category
        indices={indices}
        title="New Student Tasks"
        tasks={newStudent}
        testid="new-student-tasks-category"
      />
      <Category
        indices={indices}
        title="Student Tasks"
        tasks={student}
        testid="student-tasks-category"
      />
      <BCoursesTasks tasks={bCourses} />
    </Accordion>
  );
}

export function CompletedTasksByCategory() {
  const [indices, toggleItem] = useAccordionIndices([]);
  const items = useSelector((state) => {
    const {
      checklistItems: { completedItems = [] },
    } = currentUserState(state);

    return completedItems;
  });

  const { admissions, residency, financialAid, newStudent, student } =
    items.reduce(taskCategoryReducer, {});

  return (
    <Accordion index={indices} onChange={toggleItem}>
      <CompletedAgreements />
      <CompletedCategoryTasks title="Admission Tasks" tasks={admissions} />
      <CompletedCategoryTasks title="Residency Tasks" tasks={residency} />
      <CompletedFinancesTasks tasks={financialAid} />
      <CompletedCategoryTasks title="New Student Tasks" tasks={newStudent} />
      <CompletedCategoryTasks title="Student Tasks" tasks={student} />
    </Accordion>
  );
}

function taskCategoryReducer(acc, current, index) {
  const currentWithIndex = { ...current, index };

  if (current.isOverdue) {
    if (acc.hasOwnProperty("overdue")) {
      acc["overdue"].push(currentWithIndex);
      return acc;
    }

    acc["overdue"] = [currentWithIndex];
    return acc;
  }

  if (acc.hasOwnProperty(current.displayCategory)) {
    acc[current.displayCategory].push(currentWithIndex);
    return acc;
  }

  acc[current.displayCategory] = [currentWithIndex];
  return acc;
}

// The OVERDUE_ENABLED flag makes it easy to disable the "overdue" grouping for
// development purposes - desirable if development data is out of date and most
// items are showing up in "Overdue" rather their normal categories.
const OVERDUE_ENABLED = true;

const markOverdue = (item) => {
  if (OVERDUE_ENABLED) {
    if (
      item.displayCategory === "financialAid" ||
      (item.isBeingProcessed && !item.isSir)
    ) {
      return item;
    } else {
      return { ...item, isOverdue: isPast(endOfDay(parseDate(item.dueDate))) };
    }
  } else {
    return { ...item, isOverdue: false };
  }
};
