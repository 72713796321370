import React from "react";
import { react2angular } from "react2angular";
import { ReduxProvider } from "components/data/ReduxProvider";
import { TasksCard } from "components/cards/TasksCard";

const NgTasksCard = () => (
  <ReduxProvider>
    <TasksCard />
  </ReduxProvider>
);

angular
  .module("calcentral.react")
  .component("tasksCard", react2angular(NgTasksCard));
