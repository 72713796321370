import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { DenimArrowRightIcon } from "components/ui/Icons";

import { getMyStatus } from "data/slices/myStatus";

const ViewAcademicSummaryLink = ({
  delegateActingAsUid,
  isAdvisingView,
  fetchData,
}) => {
  useEffect(() => {
    fetchData();
  }, []);

  if (isAdvisingView || delegateActingAsUid) {
    return null;
  } else {
    return (
      <a className="ViewAcademicSummaryLink" href="/academics/academic_summary">
        <strong>View Academic Summary </strong>
        <DenimArrowRightIcon />
      </a>
    );
  }
};

ViewAcademicSummaryLink.displayName = "ViewAcademicSummaryLink";
ViewAcademicSummaryLink.propTypes = {
  delegateActingAsUid: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
    PropTypes.string,
  ]),
  isAdvisingView: PropTypes.bool,
  fetchData: PropTypes.func,
};

function mapState({ myStatus }) {
  const { delegateActingAsUid = null } = myStatus;

  return { delegateActingAsUid };
}

function mapDispatch(dispatch) {
  return {
    fetchData: () => {
      dispatch(getMyStatus());
    },
  };
}

export default connect(mapState, mapDispatch)(ViewAcademicSummaryLink);
