import React from "react";
import PropTypes from "prop-types";
import LightText from "components/ui/LightText";
import { useAdvisingStudent } from "components/hooks/useAdvisingStudent";

export const ExpectedGraduationTermGradLaw = () => {
  const { graduation: { gradOrLaw: gradOrLawGraduation } = {} } =
    useAdvisingStudent((student) => student.advisingStudentAcademicProfile);

  const { expectedGraduationTerms = [] } = gradOrLawGraduation || {};

  if (expectedGraduationTerms.length) {
    return (
      <>
        <dt>Expected Graduation</dt>
        <dd data-testid="grad-or-law-expected-graduation">
          {expectedGraduationTerms.map((term, index) => (
            <GradOrLawExpectedGraduationTerm term={term} key={index} />
          ))}
        </dd>
      </>
    );
  }
  return null;
};
ExpectedGraduationTermGradLaw.displayName = "ExpectedGraduationTermGradLaw";

const GradOrLawExpectedGraduationTerm = ({ term }) => {
  if (term) {
    return (
      <div>
        {term.plans.map((plan) => (
          <LightText key={plan}>
            <span key={plan}>{plan}</span>
          </LightText>
        ))}
        <div>{term.expectedGradTermNames.join(", ")}</div>
      </div>
    );
  }
  return null;
};
GradOrLawExpectedGraduationTerm.displayName = "GradOrLawExpectedGraduationTerm";
GradOrLawExpectedGraduationTerm.propTypes = {
  term: PropTypes.object,
};
