import React from "react";
import { Field, useFormikContext } from "formik";
import { AcceptanceConditions } from "./AcceptanceConditions";
import { DeclineResponse } from "./DeclineResponse";
import { optionShape, statusShape } from "../statusProps";

export const OptionFields = ({ status, option }) => {
  const { setFieldValue } = useFormikContext();
  const { config: { sirConditions = [] } = {}, responseReasons = [] } = status;

  // The onClick hander is attached to the label to force Formik to prevent a
  // bug that requires the user to click the label twice (if they don't click
  // the radio button directly.
  //
  // Sets actionReason and messageText to be used by the submit handler to update
  // the card using the "messageObj" in-memory state.
  const onClick = () => {
    setFieldValue("progAction", option.progAction);
    setFieldValue("actionReason", option.progReason);
    setFieldValue("messageText", option.messageText);
  };

  return (
    <div>
      <label onClick={onClick}>
        <Field type="radio" name="progAction" value={option.progAction} />
        <div>{option.descrLong}</div>
      </label>

      <AcceptanceConditions option={option} sirConditions={sirConditions} />
      <DeclineResponse option={option} responseReasons={responseReasons} />
    </div>
  );
};

OptionFields.propTypes = {
  status: statusShape,
  option: optionShape,
};
