import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Tabs, TabPanels, TabPanel, TabList, Tab } from "components/ui/Tabs";
import { Card, CardBody, CardTitle } from "components/ui/Card";
import Spinner from "components/ui/Spinner";
import { fetchWebMessages } from "data/actions/webMessagesActions";
import { fetchBCoursesActivities } from "data/actions/bCoursesActivityActions";
import BCoursesTab from "./NotificationsCard/BCourses/BCoursesTab";
import UniversityTab from "./NotificationsCard/University/UniversityTab";
import useShowMore from "./NotificationsCard/useShowMore";

export function NotificationsCard() {
  const dispatch = useDispatch();
  const { loaded, notificationsCount } = useSelector((state) => {
    const {
      myWebMessages: {
        loaded,
        universityNotifications: { notifications = [] } = {},
      },
    } = state;
    return {
      loaded,
      notificationsCount: notifications.length,
    };
  });

  useEffect(() => {
    dispatch(fetchWebMessages());
    dispatch(fetchBCoursesActivities());
  }, [useDispatch]);

  const [defaultTabIndex, setDefaultTabIndex] = useState(0);
  const [isLoaded, setIsLoaded] = useState(loaded);
  const [shownNotificationsCount, showMoreNotifications] = useShowMore(5);
  const [shownCoursesCount, showMoreCourses] = useShowMore(10);

  // Use local state to determine when the data loads, which allows checking
  // the notifications count and change the default tab if the university
  // notifications count is zero
  if (!isLoaded && loaded) {
    if (notificationsCount === 0) {
      setDefaultTabIndex(1);
    }

    setIsLoaded(true);
  }

  return (
    <Card data-testid="notifications-card">
      <CardTitle>
        <h2>Notifications</h2>
      </CardTitle>

      {isLoaded ? (
        <Tabs defaultIndex={defaultTabIndex}>
          <TabList>
            <Tab data-testid="university-tab">University</Tab>
            <Tab data-testid="bcourses-tab">bCourses</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <UniversityTab
                shownCount={shownNotificationsCount}
                showMore={showMoreNotifications}
              />
            </TabPanel>
            <TabPanel>
              <BCoursesTab
                shownCount={shownCoursesCount}
                showMore={showMoreCourses}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      ) : (
        <CardBody>
          <Spinner />
        </CardBody>
      )}
    </Card>
  );
}
NotificationsCard.displayName = "NotificationsCard";
