import styled from "styled-components";
import { CCTable } from "components/ui/Table";

export const TransferCreditTable = styled(CCTable)`
  thead tr th {
    &:last-child {
      padding-right: 14px;
      text-align: right;
    }
  }
`;
