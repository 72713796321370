import React from "react";
import { react2angular } from "react2angular";
import { BrowserRouter as Router } from "react-router-dom";
import ReduxProvider from "components/ReduxProvider";
import IDLookupCard from "components/cards/IDLookupCard";

const NgIDLookupCard = () => (
  <ReduxProvider>
    <Router>
      <IDLookupCard />
    </Router>
  </ReduxProvider>
);

angular
  .module("calcentral.react")
  .component("idLookupCard", react2angular(NgIDLookupCard));
