import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

import { CCTable } from "components/ui/Table";
import { presentationShape, deadlineShape } from "../propTypes";

import InfoIconButton from "./InfoIconButton";

import TimeCell from "../TimeCell";
import EarlyDropPopover from "../EarlyDropPopover";
import EarlyDropDeadline from "./EarlyDropDeadline";

function DeadlineRows({ deadline, programCodes = [] }) {
  const { addDeadline, optionDeadline } = deadline;

  const collegePolicyOption = programCodes.find((code) =>
    ["UCCH", "UCOE"].includes(code)
  );

  return (
    <>
      <tr>
        <td>Add, drop, or change units</td>
        <td>
          <TimeCell time={addDeadline} />
        </td>
      </tr>
      {collegePolicyOption ? (
        <tr>
          <td colSpan="2">
            Grading option *Please refer to your college&apos;s policy*
          </td>
        </tr>
      ) : (
        optionDeadline && (
          <tr>
            <td>Grading option</td>
            <td>
              <TimeCell time={optionDeadline} />
            </td>
          </tr>
        )
      )}
    </>
  );
}

DeadlineRows.displayName = "DeadlineRows";
DeadlineRows.propTypes = {
  deadline: deadlineShape,
  programCodes: PropTypes.arrayOf(PropTypes.string),
  setPopoverOpen: PropTypes.func,
};

export default function Deadlines({
  presentation: {
    termId,
    constraints: { deadlines = [] } = {},
    programCodes = [],
  },
}) {
  const [popoverOpen, setPopoverOpen] = useState(false);

  // Create a DOM node reference to connect to the popover element
  const popoverRef = useRef(null);

  // Checks if a click event is inside the bounds of the popover. If not,
  // closes the popover by setting popoverOpen -> false.
  const outsideClick = (e) => {
    if (popoverRef.current) {
      const isInside = popoverRef.current.contains(e.target);
      setPopoverOpen(isInside);
    }
  };

  const handleEscape = (e) => {
    const isEscape = e.key == "Escape";

    if (isEscape) {
      setPopoverOpen(false);
    }
  };

  // Attach an event listener to the DOM to forward click events to this
  // component's outsideClick handler.
  //
  // The return value of useEffect is the cleanup function to be run when the
  // component is unmounted.
  useEffect(() => {
    document.addEventListener("mousedown", outsideClick);
    return () => removeEventListener("mousedown", outsideClick);
  }, []);

  useEffect(() => {
    document.addEventListener("keyup", handleEscape);
    return () => removeEventListener("keyup", handleEscape);
  });

  if (deadlines.length > 0) {
    return (
      <div data-testid="enrollment-deadlines" style={{ position: `relative` }}>
        {popoverOpen && (
          <EarlyDropPopover
            setPopoverOpen={setPopoverOpen}
            popoverRef={popoverRef}
          />
        )}

        <CCTable>
          <thead>
            <tr>
              <th>Deadline</th>
              <th width="33%">Deadline Date</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{ position: `relative` }}>
                Early drop{" "}
                <InfoIconButton onClick={() => setPopoverOpen(true)} />
              </td>
              <td>
                <EarlyDropDeadline termId={termId} />
              </td>
            </tr>
            {deadlines.map((deadline) => (
              <DeadlineRows
                key={deadline.session}
                deadline={deadline}
                programCodes={programCodes}
                setPopoverOpen={setPopoverOpen}
              />
            ))}
          </tbody>
        </CCTable>
      </div>
    );
  }

  return null;
}

Deadlines.displayName = "Deadlines";
Deadlines.propTypes = {
  presentation: presentationShape,
  programCodes: PropTypes.arrayOf(PropTypes.string),
};
