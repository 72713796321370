import React from "react";
import PropTypes from "prop-types";
import { FormatCurrency } from "./FormatCurrency";

export const Summary = ({ categoryTitle, item }) => {
  const { total, totals = [], amounts = [] } = item;

  if (categoryTitle !== "Grand Total") {
    return (
      <tbody className="summary">
        <tr className="titleRow">
          <td
            colSpan={totals.length + 2}
            style={{
              textTransform: `uppercase`,
              textAlign: `right`,
              color: `var(--dove-gray)`,
            }}
          >
            {categoryTitle} Total
          </td>
        </tr>
        <tr>
          <td></td>
          {totals.map((total, index) => (
            <td key={index} className="total">
              <FormatCurrency value={total} />
            </td>
          ))}
          <td>{total}</td>
        </tr>
      </tbody>
    );
  }

  return (
    <tbody className="summary">
      <tr>
        <td></td>
        {amounts.map((total, index) => (
          <td key={index} className="total">
            <strong>
              <FormatCurrency value={total} />
            </strong>
          </td>
        ))}
        <td className="total">
          <strong>
            <FormatCurrency value={total} />
          </strong>
        </td>
        <td></td>
      </tr>
    </tbody>
  );
};

Summary.displayName = "Summary";
Summary.propTypes = {
  categoryTitle: PropTypes.string,
  item: PropTypes.array,
};
