import React from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import { statusShape } from "./statusProps";
import { OptionFields } from "./ResponseForm/OptionsFields";
import { validate } from "./ResponseForm/validate";
import { ResponseFormStyles } from "./ResponseForm/ResponseFormStyles";
import { onSubmit } from "./ResponseForm/onSubmit";
import { SubmitButton } from "./ResponseForm/SubmitButton";
import { useCSRFToken } from "components/hooks/useCSRFToken";

export function ResponseForm({ status, setMessageObj }) {
  const dispatch = useDispatch();
  const csrfToken = useCSRFToken();

  const {
    config: { sirOptions = [], sirConditions = [], chklstItemCd },
    isUndergraduate,
    deposit: { required: depositRequired } = {},
    checkListMgmtAdmp: {
      acadCareer,
      admApplNbr,
      applProgNbr,
      stdntCarNbr,
    } = {},
    responseReasons = [],
  } = status;

  return (
    <Formik
      initialValues={{
        progAction: false,
        conditions: [],
        responseReason: responseReasons[0]?.responseReason,
        responseDescription: "",
        acadCareer,
        admApplNbr,
        applProgNbr,
        chklstItemCd,
        stdntCarNbr,
        actionReason: "",
      }}
      onSubmit={onSubmit(dispatch, csrfToken, setMessageObj)}
      validate={validate(sirConditions)}
      validateOnMount={true}
    >
      {() => (
        <ResponseFormStyles>
          {sirOptions.map((option, index) => (
            <OptionFields key={index} status={status} option={option} />
          ))}

          <div style={{ padding: `var(--lg)`, textAlign: `center` }}>
            <SubmitButton
              isUndergraduate={isUndergraduate}
              depositRequired={depositRequired}
            />
          </div>
        </ResponseFormStyles>
      )}
    </Formik>
  );
}

ResponseForm.propTypes = {
  status: statusShape,
  setMessageObj: PropTypes.func,
};
