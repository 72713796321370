import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const SemesterUnitsTableStyles = styled.table`
  background: transparent;
  border: 0;
  border-spacing: 0;
  margin: 15px 0;
  overflow-y: hidden;
  width: 100%;
`;

const SemesterUnitsDataCell = styled.td`
  margin: 0;
  padding: 0;
  text-align: right;
`;

const SemesterTotalUnits = ({ enrolledUnits, semester, waitlistedUnits }) => {
  if (semester.timeBucket === "past") {
    return null;
  }

  return (
    <SemesterUnitsTableStyles data-testid="semester-total-units-table">
      <tbody>
        {!(enrolledUnits == undefined) && (
          <tr>
            <SemesterUnitsDataCell data-testid="semester-total-enrolled-units">
              My Enrolled Units:{" "}
              <strong>{isNaN(enrolledUnits) ? 0 : enrolledUnits}</strong>
            </SemesterUnitsDataCell>
          </tr>
        )}

        {!(waitlistedUnits == undefined) && (
          <tr>
            <SemesterUnitsDataCell data-testid="semester-total-waitlisted-units">
              {parseInt(waitlistedUnits) > 0 && (
                <a href={"/academics/semester/" + semester.slug}>
                  My Waitlisted Units: <strong>{waitlistedUnits}</strong>
                </a>
              )}
              {parseInt(waitlistedUnits) < 1 && (
                <span>
                  My Waitlisted Units: <strong>{waitlistedUnits}</strong>
                </span>
              )}
            </SemesterUnitsDataCell>
          </tr>
        )}
      </tbody>
    </SemesterUnitsTableStyles>
  );
};

SemesterTotalUnits.displayName = "SemesterTotalUnits";
SemesterTotalUnits.propTypes = {
  enrolledUnits: PropTypes.number,
  semester: PropTypes.object,
  waitlistedUnits: PropTypes.number,
};

export default SemesterTotalUnits;
