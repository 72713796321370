import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { Accordion } from "components/ui/Accordion";

import ActionItem from "./ActionItem";
import AdvisorCardSection from "./AdvisorCardSection";
import WidgetList from "components/ui/WidgetList";

const ActionItemList = styled(WidgetList)`
  border-bottom: 1px solid var(--gallery);
  font-size: 13px;
  line-height: 1.5;
  margin-top: 0;
  padding: 0;
`;

export default function ActionItems({ actionItems }) {
  const [shownItemIndex, setShownItemIndex] = useState();

  if (actionItems.length > 0) {
    return (
      <AdvisorCardSection>
        <h3 style={{ marginTop: "20px" }}>Action Items</h3>
        <Accordion collapsible onChange={(value) => setShownItemIndex(value)}>
          <ActionItemList>
            {actionItems.map((actionItem, actionItemIndex) => (
              <ActionItem
                actionItem={actionItem}
                actionItemIndex={actionItemIndex}
                setShownItemIndex={setShownItemIndex}
                shownItemIndex={shownItemIndex}
                key={actionItemIndex}
              />
            ))}
          </ActionItemList>
        </Accordion>
      </AdvisorCardSection>
    );
  } else {
    return null;
  }
}

ActionItems.displayName = "ActionItems";
ActionItems.propTypes = {
  actionItems: PropTypes.array,
};
