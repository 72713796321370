import React from "react";
import { react2angular } from "react2angular";
import { ReduxProvider } from "components/data/ReduxProvider";
import { SIRCard } from "components/cards/SIRCard";

const NgSIRCard = () => (
  <ReduxProvider>
    <SIRCard />
  </ReduxProvider>
);

angular
  .module("calcentral.react")
  .component("sirCard", react2angular(NgSIRCard));
