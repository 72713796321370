import React from "react";
import PropTypes from "prop-types";
import { ReduxProvider } from "components/data/ReduxProvider";
import { SemesterCoursesCard } from "components/cards/SemesterCoursesCard";
import WithMyAcademics from "components/data/WithMyAcademics";
import { react2angular } from "react2angular";
import WithSemesters from "components/data/WithSemesters";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

const WrappedSemesterCoursesCard = ({displayTitleTerm}) => (
  <WithMyAcademics>
    <WithSemesters>
      <SemesterCoursesCard displayTitleTerm={displayTitleTerm} />
    </WithSemesters>
  </WithMyAcademics>
);

WrappedSemesterCoursesCard.propTypes = {
  displayTitleTerm: PropTypes.bool,
};

const NgSemesterCoursesCard = () => (
  <ReduxProvider>
    <Router>
      <Routes>
        <Route
          path="academics/semester/:semesterId/*"
          element={<WrappedSemesterCoursesCard displayTitleTerm={false} />}
        />
        <Route path="academics" element={<WrappedSemesterCoursesCard displayTitleTerm={true} />} />
      </Routes>
    </Router>
  </ReduxProvider>
);

angular
  .module("calcentral.react")
  .component("semesterCoursesCard", react2angular(NgSemesterCoursesCard));
