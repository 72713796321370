import React from "react";
import PropTypes from "prop-types";
import { CCTable } from "components/ui/Table";
export default function DeadlinesTable({ children }) {
  return (
    <CCTable>
      <thead>
        <tr>
          <th>Deadline</th>
          <th width="33%">Deadline Date</th>
        </tr>
      </thead>
      <tbody>{children}</tbody>
    </CCTable>
  );
}

DeadlinesTable.displayName = "DeadlinesTable";
DeadlinesTable.propTypes = {
  children: PropTypes.node.isRequired,
};
