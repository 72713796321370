import React from "react";
import styled from "styled-components";
import { usdFilter } from "components/../react/helpers/filters";
import { useSlice } from "components/hooks/useSlice";

export function DueNow() {
  const { summary: { amountDueNow = 0, pastDueAmount = 0 } = {} } =
    useSlice("billingActivity");

  const pastDue = parseFloat(pastDueAmount);

  return (
    <DueStyles data-testid="billing-due-now">
      Due Now
      <h2>{usdFilter(amountDueNow)}</h2>
      {pastDue > 0 && <p>Includes Overdue: {usdFilter(pastDueAmount)}</p>}
    </DueStyles>
  );
}

DueNow.displayName = "DueNow";

const DueStyles = styled.div`
  background-color: var(--foam);
  padding: var(--large-spacing);

  h2 {
    font-size: 26px;
  }

  p {
    color: var(--thunderbird)
    margin: 0;
  }
`;
DueStyles.displayName = "DueStyles";
