import React from "react";
import { useSelector } from "react-redux";
import APILink from "components/ui/APILink";
import AccessibleLinkContent from "components/ui/AccessibleLinkContent";

export function DegreePlannerLink() {
  const { link } = useSelector((state) => {
    const {
      advising: {
        academics: { advisorLinks: { degreePlannerLink: link = {} } = {} },
      },
    } = state;

    return { link };
  });

  if (link.url) {
    return (
      <div
        style={{
          borderBottom: `var(--border-hairline)`,
          paddingBottom: `var(--md)`,
        }}
      >
        <h3>
          <APILink link={link} text="Update Degree Planner">
            {({ props, text, showNewWindow }) => (
              <a {...props}>
                <AccessibleLinkContent showNewWindow={showNewWindow}>
                  {text}{" "}
                  <i className="fa fa-arrow-right" aria-hidden="true"></i>
                </AccessibleLinkContent>
              </a>
            )}
          </APILink>
        </h3>
      </div>
    );
  }

  return (
    <div>
      <i
        className="fa fa-exclamation-triangle"
        aria-hidden="true"
        style={{ color: `var(--buttercup)` }}
      ></i>{" "}
      There is a problem reaching Degree Planner. Please try again soon.
    </div>
  );
}

DegreePlannerLink.displayName = "DegreePlannerLink";
