import React from "react";
import PropTypes from "prop-types";

export function SectionRow({ section }) {
  const {
    is_primary,
    section_label,
    ccn,
    dates = [],
    locations = [],
    enroll_count,
    enroll_open,
    enroll_limit,
    waitlist_count,
    waitlist_open,
    waitlist_limit,
  } = section;

  return (
    <tr className="cc-academics-class-enrollment-table-row">
      <td>
        <strong>{is_primary ? "P" : "S"}</strong>
      </td>
      <td>
        <strong>{section_label}</strong>
      </td>
      <td>{ccn}</td>
      <td className="show-for-large-up">
        {dates.map((date, index) => (
          <div key={index}>{date}</div>
        ))}
      </td>
      <td className="show-for-large-up">
        {locations.map((location, index) => (
          <div key={index}>{location}</div>
        ))}
      </td>
      <td>{enroll_count}</td>
      <td>{enroll_open}</td>
      <td className="show-for-medium-up">{enroll_limit}</td>
      <td>{waitlist_count}</td>
      <td>{waitlist_open}</td>
      <td className="show-for-medium-up">{waitlist_limit}</td>
    </tr>
  );
}

SectionRow.propTypes = {
  section: PropTypes.shape({
    is_primary: PropTypes.bool,
    section_label: PropTypes.string,
    ccn: PropTypes.string,
    dates: PropTypes.array,
    locations: PropTypes.array,
    enroll_count: PropTypes.number,
    enroll_open: PropTypes.number,
    enroll_limit: PropTypes.number,
    waitlist_count: PropTypes.number,
    waitlist_open: PropTypes.number,
    waitlist_limit: PropTypes.number,
  }),
};
