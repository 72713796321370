import { useEffect } from "react";
import getUserLinks from "data/actions/getUserLinks";
import { useDispatch, useSelector } from "react-redux";

export default function WithLinks({ children }) {
  const dispatch = useDispatch();

  const csrfToken = useSelector(({ csrfToken }) => {
    return csrfToken;
  });

  useEffect(() => {
    if (csrfToken) {
      dispatch(getUserLinks(csrfToken));
    }
  }, [dispatch, csrfToken]);

  return children;
}
