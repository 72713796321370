import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import SemestersContext from "components/contexts/SemestersContext";
import { Card, CardTitle } from "components/ui/Card";
import EnrollmentsTable from "./SemesterCoursesCard/EnrollmentsTable";
import WaitlistsTable from "./SemesterCoursesCard/WaitlistsTable";
import SwapsTable from "./SemesterCoursesCard/SwapsTable";
import TeachingCoursesTable from "./SemesterCoursesCard/TeachingCoursesTable";
import { SemesterGrading } from "./SemesterCoursesCard/SemesterGrading";
import { DenimArrowRightIcon } from "components/ui/Icons";
import {
  filteredCourseSections,
  isEnrolled,
  splitMultiplePrimaries,
  collectTopics,
} from "utils/semesters";
import { Spinner } from "components/ui/Spinner";

export function SemesterCoursesCard({displayTitleTerm}) {
  return (
    <SemestersContext.Consumer>
      {({
        currentSemester,
        currentStudentSemester,
        currentTeachingSemester,
        loadState,
      }) => {
        if (loadState !== "success") {
          return <Spinner />;
        }

        const waitlistedCourses = currentSemester.classes.filter((course) =>
          course.sections.some((section) => section.waitlisted)
        );

        const waitlistedSections = waitlistedCourses
          .flatMap((course) => course.sections)
          .filter((section) => section.is_primary_section);

        const enrolledCourses = currentSemester.classes
          .map(collectTopics)
          .map(filteredCourseSections(isEnrolled))
          .flatMap(splitMultiplePrimaries);

        const enrolledSections = enrolledCourses
          .flatMap((course) => course.sections)
          .filter((section) => section.is_primary_section);

        const dropClassIfEnrolled = waitlistedSections.filter(
          (section) =>
            section.drop_class_if_enrl && section.drop_class_if_enrl !== "0"
        );

        const swapSections = enrolledSections.reduce(
          (previous, currentEnrolledSection) => {
            const match = dropClassIfEnrolled.find(
              (section) =>
                section.drop_class_if_enrl === currentEnrolledSection.ccn
            );

            if (match) {
              return [
                ...previous,
                {
                  from: currentEnrolledSection,
                  to: match,
                },
              ];
            }

            return previous;
          },
          []
        );

        const cardTitle = displayTitleTerm ? `${currentSemester.name} Classes` : 'Classes';

        return (
          <Card data-testid="semester-courses-card">
            <CardTitle>
              <h2>{cardTitle}</h2>
              {currentSemester.timeBucket !== "past" && (
                <Link to={`/academics/booklist/${currentSemester.slug}`}>
                  <strong>Book List</strong> <DenimArrowRightIcon />
                </Link>
              )}
            </CardTitle>

            {currentStudentSemester && (
              <EnrollmentsTable
                currentSemester={currentStudentSemester}
                enrolledCourses={enrolledCourses}
                swapSections={swapSections}
              />
            )}

            {waitlistedCourses.length > 0 && (
              <WaitlistsTable
                waitlistedCourses={waitlistedCourses}
                swapSections={swapSections}
              />
            )}

            {swapSections.length > 0 && (
              <SwapsTable
                swapSections={swapSections}
                enrolledCourses={enrolledCourses}
                waitlistedCourses={waitlistedCourses}
              />
            )}

            {currentTeachingSemester && (
              <>
                <SemesterGrading semester={currentTeachingSemester} />
                <TeachingCoursesTable semester={currentTeachingSemester} />
              </>
            )}
          </Card>
        );
      }}
    </SemestersContext.Consumer>
  );
}

SemesterCoursesCard.displayName = "SemesterCoursesCard";
SemesterCoursesCard.propTypes = {
  displayTitleTerm: PropTypes.bool,
};
