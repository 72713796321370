import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { semesterShape } from "../propTypes/semesterShape";

import { Accordion } from "components/ui/AccordionModern";
import { Card, CardBody, CardTitle } from "components/ui/Card";
import { DegreePlannerLink } from "./AcademicPlanCard/DegreePlannerLink";
import Spinner from "components/ui/Spinner";
import { fetchAdvisingAcademics } from "data/actions/advisingActions";
import { PlanSemester } from "./AcademicPlanCard/PlanSemester";

export default function AcademicPlanContainer() {
  const dispatch = useDispatch();
  const { uid } = useParams();

  useEffect(() => {
    if (uid) {
      dispatch(fetchAdvisingAcademics(uid));
    }
  }, [dispatch, uid]);

  const {
    semesters = [],
    isLoading,
    defaultIndex,
    loaded,
  } = useSelector((state) => {
    const {
      advising: {
        academics: {
          planSemesters = [],
          isLoading = true,
          loaded = false,
        } = {},
      } = {},
    } = state;

    const semesters = planSemesters.map((semester) => ({
      ...semester,
      show: ["current", "previous", "next"].indexOf(semester.timeBucket) > -1,
    }));

    const defaultIndex = semesters.reduce((prev, current, currentIndex) => {
      if (current.show) {
        return [...prev, currentIndex];
      }

      return prev;
    }, []);

    return {
      semesters,
      isLoading,
      loaded,
      defaultIndex,
    };
  });

  return (
    <Card data-testid="academic-plan-card">
      <CardTitle>
        <h2>Academic Plan</h2>
      </CardTitle>

      {isLoading && (
        <CardBody>
          <Spinner />
        </CardBody>
      )}

      {loaded && (
        <AcademicPlanCard semesters={semesters} defaultIndex={defaultIndex} />
      )}
    </Card>
  );
}
AcademicPlanContainer.displayName = "AcademicPlanContainer";

function AcademicPlanCard({ semesters, defaultIndex }) {
  const [indices, setIndices] = useState(defaultIndex);

  function toggleItem(toggledIndex) {
    if (indices.includes(toggledIndex)) {
      setIndices(
        indices.filter((currentIndex) => currentIndex !== toggledIndex)
      );
    } else {
      setIndices([...indices, toggledIndex]);
    }
  }

  return (
    <CardBody style={{ paddingBottom: `0` }}>
      <DegreePlannerLink />
      <Accordion collapsible multiple index={indices} onChange={toggleItem}>
        {semesters.map((semester) => (
          <PlanSemester key={semester.name} semester={semester} />
        ))}
      </Accordion>
    </CardBody>
  );
}

AcademicPlanCard.displayName = "AcademicPlanCard";
AcademicPlanCard.propTypes = {
  semesters: PropTypes.arrayOf(semesterShape),
  defaultIndex: PropTypes.array,
};
