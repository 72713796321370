import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import campusSolutionsIcon from "assets/images/icon_campussolutions_256x256.svg";

const styledIconProps = {
  className: PropTypes.string,
};

export const BaseIcon = styled.i`
  background-repeat: no-repeat;
  background-size: 16px;
  height: 16px;
  width: 16px;
`;
BaseIcon.displayName = "BaseIcon";

/////////////////////////////////////////////////////////////////////////////
// Colored Icons

export const GreenIcon = styled(BaseIcon)`
  color: var(--jewel);
`;
GreenIcon.displayName = "GreenIcon";

export const GoldIcon = styled(BaseIcon)`
  color: var(--buttercup);
`;
GoldIcon.displayName = "GoldIcon";

export const RedIcon = styled(BaseIcon)`
  color: var(--thunderbird);
`;
RedIcon.displayName = "RedIcon";

export const BlueGrayIcon = styled(BaseIcon)`
  color: var(--gumbo);
`;
BlueGrayIcon.displayName = "BlueGrayIcon";

export const DoveGrayIcon = styled(BaseIcon)`
  color: var(--dove-gray);
`;
DoveGrayIcon.displayName = "DoveGrayIcon";

/////////////////////////////////////////////////////////////////////////////
// Font Awesome Icons

// https://fontawesome.com/v4/icon/arrow-right
export const ArrowRightIcon = ({ className = "" }) => (
  <BaseIcon className={`fa fa-arrow-right ${className}`} aria-hidden="true" />
);
ArrowRightIcon.displayName = "ArrowRightIcon";
ArrowRightIcon.propTypes = styledIconProps;

export const DenimArrowRightIcon = styled(ArrowRightIcon)`
  color: var(--denim);
`;
DenimArrowRightIcon.displayName = "DenimArrowRightIcon";

// https://fontawesome.com/v4/icon/bell
export const BellIcon = ({ className = "" }) => (
  <BaseIcon className={`fa fa-bell ${className}`} aria-hidden="true" />
);
BellIcon.displayName = "BellIcon";
BellIcon.propTypes = styledIconProps;

// https://fontawesome.com/v4/icon/check
export const CheckIcon = ({ className = "" }) => (
  <BaseIcon className={`fa fa-check ${className}`} aria-hidden="true" />
);
CheckIcon.displayName = "CheckIcon";
CheckIcon.propTypes = styledIconProps;

// https://fontawesome.com/v4/icon/chevron-down
export const ChevronDownIcon = ({ className = "" }) => (
  <BaseIcon className={`fa fa-chevron-down ${className}`} aria-hidden="true" />
);
ChevronDownIcon.displayName = "ChevronDownIcon";
ChevronDownIcon.propTypes = styledIconProps;

// https://fontawesome.com/v4/icon/chevron-up
export const ChevronUpIcon = ({ className = "" }) => (
  <BaseIcon className={`fa fa-chevron-up ${className}`} aria-hidden="true" />
);
ChevronUpIcon.displayName = "ChevronUpIcon";
ChevronUpIcon.propTypes = styledIconProps;

// https://fontawesome.com/v4/icon/clipboard
export const ClipboardIcon = ({ className = "" }) => (
  <BaseIcon className={`fa fa-clipboard  ${className}`} aria-hidden="true" />
);
ClipboardIcon.displayName = "ClipboardIcon";
ClipboardIcon.propTypes = styledIconProps;

// https://fontawesome.com/v4/icon/clock-o
export const ClockIcon = ({ className = "" }) => (
  <BlueGrayIcon className={`fa fa-clock-o  ${className}`} aria-hidden="true" />
);
ClockIcon.displayName = "ClockIcon";
ClockIcon.propTypes = styledIconProps;

// https://fontawesome.com/v4/icon/download
export const DownloadIcon = ({ className = "" }) => (
  <BaseIcon className={`fa fa-download ${className}`} aria-hidden="true" />
);
DownloadIcon.displayName = "DownloadIcon";
DownloadIcon.propTypes = styledIconProps;

// https://fontawesome.com/v4/icon/exclamation-circle
export const ExclamationCircleIcon = ({ className = "" }) => {
  return (
    <BaseIcon
      className={`fa fa-exclamation-circle ${className}`}
      aria-hidden="true"
    />
  );
};
ExclamationCircleIcon.displayName = "ExclamationCircleIcon";
ExclamationCircleIcon.propTypes = styledIconProps;

// https://fontawesome.com/v4/icon/external-link
export const ExternalLink = ({ className = "" }) => {
  return (
    <BaseIcon
      className={`fa fa-external-link ${className}`}
      aria-hidden="true"
    />
  );
};
ExternalLink.displayName = "ExternalLink";
ExternalLink.propTypes = styledIconProps;

// https://fontawesome.com/v4/icon/envelope-o
export const EnvelopeIcon = ({ className = "" }) => {
  return (
    <BaseIcon className={`fa fa-envelope-o ${className}`} aria-hidden="true" />
  );
};
EnvelopeIcon.displayName = "EnvelopeIcon";
EnvelopeIcon.propTypes = styledIconProps;

// https://fontawesome.com/v4/icon/eye
export const EyeIcon = ({ className = "" }) => (
  <BaseIcon className={`fa fa-eye ${className}`} aria-hidden="true" />
);
EyeIcon.displayName = "EyeIcon";
EyeIcon.propTypes = styledIconProps;

// https://fontawesome.com/v4/icon/exclamation-triangle
// TODO: Replace with YellowWarning below
export function ExclamationTriangle() {
  return (
    <i
      className="fa fa-exclamation-triangle"
      aria-hidden="true"
      style={{ color: `var(--buttercup)` }}
    ></i>
  );
}
ExclamationTriangle.displayName = "ExclamationTriangle";

// https://fontawesome.com/v4/icon/graduation-cap
export const GraduationCapIcon = ({ className = "" }) => (
  <BaseIcon
    className={`fa fa-graduation-cap ${className}`}
    aria-hidden="true"
  />
);
GraduationCapIcon.displayName = "GraduationCapIcon";
GraduationCapIcon.propTypes = styledIconProps;

// https://fontawesome.com/v4/icon/check
export const GreenCheck = styled(CheckIcon)`
  color: var(--jewel);
`;
GreenCheck.displayName = "GreenCheck";

// https://fontawesome.com/v4/icon/check-circle
export const GreenCheckCircleIcon = ({ className = "" }) => (
  <GreenIcon className={`fa fa-check-circle ${className}`} aria-hidden="true" />
);
GreenCheckCircleIcon.displayName = "GreenCheckCircleIcon";
GreenCheckCircleIcon.propTypes = styledIconProps;

// https://fontawesome.com/v4/icon/plus
export const PlusIcon = ({ className = "" }) => (
  <BaseIcon className={`fa fa-plus ${className}`} aria-hidden="true" />
);
PlusIcon.displayName = "PlusIcon";
PlusIcon.propTypes = styledIconProps;

// https://fontawesome.com/v4/icon/print
export const PrintIcon = ({ className = "" }) => (
  <BaseIcon
    className={`fa fa-print ${className}`}
    style={{ alignContent: `center` }}
    aria-hidden="true"
  />
);
PrintIcon.displayName = "PrintIcon";
PrintIcon.propTypes = styledIconProps;

// https://fontawesome.com/v4/icon/exclamation-circle
export const RedWarning = ({ className = "" }) => (
  <RedIcon
    className={`fa fa-exclamation-circle ${className}`}
    aria-hidden="true"
  />
);
RedWarning.displayName = "RedWarning";
RedWarning.propTypes = styledIconProps;

// https://fontawesome.com/v4/icon/times-circle
export const RedTimesCircle = ({ className = "" }) => (
  <RedIcon className={`fa fa-times-circle ${className}`} aria-hidden="true" />
);
RedTimesCircle.displayName = "RedTimesCircle";
RedTimesCircle.propTypes = styledIconProps;

// https://fontawesome.com/v4/icon/refresh
export const RefreshIcon = ({ className = "" }) => (
  <DoveGrayIcon className={`fa fa-refresh ${className}`} aria-hidden="true" />
);
RefreshIcon.displayName = "RefreshIcon";
RefreshIcon.propTypes = styledIconProps;

// https://fontawesome.com/v4/icon/times
export const TimesIcon = ({ className = "" }) => (
  <BaseIcon className={`fa fa-times ${className}`} aria-hidden="true" />
);
TimesIcon.displayName = "TimesIcon";
TimesIcon.propTypes = styledIconProps;

// https://fontawesome.com/v4/icon/user
export const UserIcon = ({ className = "" }) => (
  <BaseIcon className={`fa fa-user ${className}`} aria-hidden="true" />
);
UserIcon.displayName = "UserIcon";
UserIcon.propTypes = styledIconProps;

// https://fontawesome.com/v4/icon/exclamation-triangle
export const YellowWarning = ({ className = "" }) => (
  <GoldIcon
    className={`fa fa-exclamation-triangle ${className}`}
    aria-hidden="true"
  />
);
YellowWarning.displayName = "YellowWarning";
YellowWarning.propTypes = styledIconProps;

// https://fontawesome.com/v4/icon/exclamation-triangle
export const RedWarningTriangle = styled(YellowWarning)`
  color: var(--thunderbird);
  margin-right: 5px;
`;

export function OverdueIcon() {
  return (
    <i
      className="fa fa-exclamation-circle"
      style={{
        display: "block",
        fontSize: `19px`,
        color: `var(--thunderbird)`,
        marginTop: `1px`,
      }}
    />
  );
}
OverdueIcon.displayName = "OverdueIcon";

export function CampusSolutionsIcon() {
  return (
    <img
      src={campusSolutionsIcon}
      alt=""
      width="16"
      height="16"
      style={{ marginTop: `1px` }}
    />
  );
}
CampusSolutionsIcon.displayName = "CampusSolutionsIcon";

/////////////////////////////////////////////////////////////////////////////
// SVG Icons

import canvasIcon from "assets/images/icon_bcourses_256x256.svg";
export function CanvasIcon() {
  return (
    <img
      src={canvasIcon}
      alt=""
      width="16"
      height="16"
      style={{ marginTop: "1px" }}
    />
  );
}
CanvasIcon.displayName = "CanvasIcon";

export const RibbonIcon = styled(BaseIcon)`
  background-image: url("assets/images/icon_ribbon.svg");
`;
RibbonIcon.displayName = "RibbonIcon";

export const GrayRibbonIcon = styled(RibbonIcon)`
  color: var(--dove-gray);
`;
GrayRibbonIcon.displayName = "GrayRibbonIcon";
