import React from "react";
import { Route, Routes } from "react-router-dom";

import RequireSuperuser from "../RequireSuperuser";
import { PageTitle } from "components/ui/Page";
import { UserAuths } from "components/cards/UserAuths/UserAuths";
import { NewUserAuth } from "components/cards/UserAuths/NewUserAuth";
import { EditUserAuth } from "components/cards/UserAuths/EditUserAuth";

export default function UserAuthRoutes() {
  return (
    <Routes>
      <Route
        path="new"
        element={
          <RequireSuperuser>
            <PageTitle title="New User Auth" />
            <NewUserAuth />
          </RequireSuperuser>
        }
      />

      <Route
        path=":id/edit"
        element={
          <RequireSuperuser>
            <PageTitle title="Edit User Auth" />
            <EditUserAuth />
          </RequireSuperuser>
        }
      />

      <Route
        path=""
        element={
          <RequireSuperuser>
            <PageTitle title="User Auths" />
            <UserAuths />
          </RequireSuperuser>
        }
      />
    </Routes>
  );
}
