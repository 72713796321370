import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import styled from "styled-components";

import WarningIcon from "components/ui/WarningIcon";
import InfoIconButton from "./InfoIconButton";
import EnrollmentCenterButton from "../EnrollmentCenterButton";
import SchedulePlannerLink from "./SchedulePlannerLink";

import Popover, { usePopover } from "components/ui/Popover";
import CAEnrollmentAckMessage from "./CAEnrollmentAckMessage";

const CalgrantAcknowledgementStyles = styled.div`
  h3 {
    font-size: 13px;
    display: flex;
    align-items: center;

    i {
      width: 20px;
    }

    strong {
      margin-right: 5px;
    }
  }

  .body {
    margin-left: 20px;
  }
`;
CalgrantAcknowledgementStyles.displayName = "CalgrantAcknowledgementStyles";

const EnrollmentActionStyles = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
EnrollmentActionStyles.displayName = "EnrollmentActionStyles";

export default function EnrollmentActions({
  calgrantAckRequired,
  termId,
  careerCode,
}) {
  const popover = usePopover();

  if (calgrantAckRequired) {
    return (
      <div data-testid="enrollment-actions" style={{ position: `relative` }}>
        {popover.isOpen && (
          <Popover
            ref={popover.ref}
            title="CA Enrollment Acknowledgement"
            close={popover.close}
          >
            <CAEnrollmentAckMessage />
          </Popover>
        )}

        <CalgrantAcknowledgementStyles data-testid="ca-enrollment-acknowledgement">
          <h3>
            <WarningIcon />
            <strong>CA Enrollment Acknowledgement is Incomplete</strong>
            <InfoIconButton onClick={() => popover.open()} />
          </h3>
          <div className="body">
            <p>
              To enroll, first complete it in your Tasks.
              <br />
              <Link to="/dashboard#tasks-card">CalCentral Tasks</Link>
            </p>
          </div>
        </CalgrantAcknowledgementStyles>
        <EnrollmentActionStyles>
          <SchedulePlannerLink
            termId={termId}
            careerCode={careerCode}
            useRightArrow={true}
          />
        </EnrollmentActionStyles>
      </div>
    );
  }

  return (
    <EnrollmentActionStyles>
      <EnrollmentCenterButton />
      <div style={{ width: `15px` }} />
      <SchedulePlannerLink termId={termId} careerCode={careerCode} />
    </EnrollmentActionStyles>
  );
}

EnrollmentActions.displayName = "EnrollmentActions";
EnrollmentActions.propTypes = {
  calgrantAckRequired: PropTypes.bool,
  termId: PropTypes.string,
  careerCode: PropTypes.string,
};
