import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

export default function SwapIndicator({ index }) {
  if (index > 0) {
    return <SwapStyles>{index} ⇄</SwapStyles>;
  }

  return null;
}

SwapIndicator.displayName = "SwapIndicator";
SwapIndicator.propTypes = {
  index: PropTypes.number.isRequired,
};

const SwapStyles = styled.span`
  background-color: var(--casper);
  color: var(--mine-shaft);
  border-radius: var(--small-border-radius);
  padding: var(--tiny-spacing) var(--small-spacing);
`;
