import React, { Fragment } from "react";
import styled from "styled-components";
import VerticallySpaced from "components/ui/VerticallySpaced";

import { HubTermLegacyNote } from "./HubTermLegacyNote";
import { TransferCredit } from "./TransferCredit/TransferCredit";
import { Semesters } from "./Semesters";
import { SemestersSummary } from "./SemestersSummary";

import { useSlice } from "components/hooks/useSlice";

const Enrollment = () => {
  const { semesters } = useSlice("myAcademics");
  const { hasStudentHistory } = useSlice("myStatus");
  const { law, graduate, undergraduate } = useSlice("myTransferCredit");

  const hasCredit = [law, graduate, undergraduate].some((i) => i.detailed);
  const showEnrollment =
    (semesters.length > 0 && hasStudentHistory) || hasCredit;

  if (showEnrollment) {
    return (
      <Fragment>
        <h3 data-testid="academic-summary-enrollment-header">Enrollment</h3>

        <EnrollmentSectionsWrapper>
          <HubTermLegacyNote />
          <TransferCredit />
          <Semesters />
          <SemestersSummary />
        </EnrollmentSectionsWrapper>
      </Fragment>
    );
  } else {
    return null;
  }
};

const EnrollmentSectionsWrapper = styled(VerticallySpaced)`
  table {
    caption {
      text-align: left;
    }
    td {
      padding-top: 0;
      vertical-align: top;
    }
    th {
      font-size: 14px;
      margin: 0;
      padding: 2px 10px 2px 0;
      width: 30%;
    }
  }
  @media print {
    a[href]::after {
      content: '';
    }
  }
`;

export default Enrollment;
