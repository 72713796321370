import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

export const TasksWrapper = styled.div`
  padding: 0 10px;
`;

export const CategoryTitle = styled.h4`
  font-size: 15px;
  margin: 0;
  padding: 10px;

  span {
    font-weight: normal;
  }

  border-bottom: 1px solid var(--gallery);
  border-top: 1px solid var(--gallery);

  &:first-child {
    border-top-width: 0;
  }
`;

export const CategoryHeader = styled.div`
  border-top: 1px solid var(--silver);

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;

  .aidYearLabel {
    font-weight: normal;
  }

  ${({ open }) => open && `background-color: var(--wild-sand);`}
  ${({ hover }) => hover === "true" && `background-color: var(--foam);`}
`;

export function BeingProcessedCount({ count }) {
  return (
    <BeingProcessedStyles>
      Being processed <span className="badge">{count}</span>
    </BeingProcessedStyles>
  );
}

BeingProcessedCount.displayName = "BeingProcessedCount";
BeingProcessedCount.propTypes = {
  count: PropTypes.number,
};

const BeingProcessedStyles = styled.div`
  border-top: 1px solid var(--gallery);
  border-bottom: 1px solid var(--gallery);

  font-size: 14px;
  font-weight: 600;
  padding: 22px 30px 16px 10px;

  .badge {
    color: white;
    background-color: var(--dusty-gray);
    padding: 2px 5px;
  }
`;
