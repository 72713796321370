import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { taskShape } from "components/propTypes/taskShape";
import { BellIcon, ExclamationTriangle } from "components/ui/Icons";
import { withinWeek } from "utils/withinWeek";

export function IncompleteDueSoonBadges({ tasks }) {
  const dueSoonCount = tasks.filter((task) => withinWeek(task.dueDate)).length;
  const incompleteCount = tasks.filter((item) => item.isIncomplete).length;

  if (incompleteCount > 0 || dueSoonCount > 0) {
    return (
      <>
        {incompleteCount > 0 && (
          <IncompleteBadge>
            <BellIcon /> {incompleteCount} incomplete
          </IncompleteBadge>
        )}

        {dueSoonCount > 0 && (
          <WarningText>
            <ExclamationTriangle /> {dueSoonCount} due within 1 week
          </WarningText>
        )}
      </>
    );
  }

  return null;
}

IncompleteDueSoonBadges.displayName = "IncompleteDueSoonBadges";
IncompleteDueSoonBadges.propTypes = {
  tasks: PropTypes.arrayOf(taskShape),
};

export const IncompleteBadge = styled.span`
  color: var(--prussian-blue);
  background-color: var(--mystic);
  padding: 3px;
`;

const WarningText = styled.span`
  color: #f79400;
  padding: 3px;
`;
