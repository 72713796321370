import React from "react";
import { react2angular } from "react2angular";

import ReduxProvider from "components/data/ReduxProvider";
import { DataSourceVerificationCard } from "components/cards/DataSourceVerificationCard";

const NgDataSourceVerificationCard = () => (
  <ReduxProvider>
    <DataSourceVerificationCard />
  </ReduxProvider>
);

// note: react2angular components cannot start with 'data'
angular
  .module("calcentral.react")
  .component(
    "sourceDataVerificationCard",
    react2angular(NgDataSourceVerificationCard)
  );
