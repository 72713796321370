import React from "react";
import { react2angular } from "react2angular";
import ReduxProvider from "components/data/ReduxProvider";
import AdvisorAcademicAdvisingCard from "components/cards/AdvisorAcademicAdvisingCard";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

const NgAdvisorAcademicAdvisingCard = () => {
  return (
    <ReduxProvider>
      <Router>
        <Routes>
          <Route path="/user/overview/:uid" element={<AdvisorAcademicAdvisingCard />} />
        </Routes>
      </Router>
    </ReduxProvider>
  );
};

angular
  .module("calcentral.react")
  .component("advisorAcademicAdvisingCard", react2angular(NgAdvisorAcademicAdvisingCard));
