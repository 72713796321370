import PropTypes from "prop-types";
import { parseISO } from "date-fns";
import { format, utcToZonedTime } from "date-fns-tz";

const DayOfWeek = ({ time }) => {
  if (time) {
    const zonedTime = utcToZonedTime(parseISO(time), "America/Los_Angeles");
    return format(zonedTime, "E");
  }

  return null;
};

DayOfWeek.displayName = "DayOfWeek";
DayOfWeek.propTypes = {
  time: PropTypes.string,
};

export default DayOfWeek;
