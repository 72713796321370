import styled from "styled-components";

const SubTitle = styled.div`
  h3 {
    color: var(--white);
    background-color: var(--ironside-gray);
    font-size: 13px;
    line-height: 20px;
    font-weight: normal;
    margin: 0;
    height: 20px;
    padding: 0 15px;
  }
`;

export default SubTitle;
