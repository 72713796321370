import styled from "styled-components";

export const ComparisonTable = styled.table`
  border: none;
  border-collapse: collapse;
  width: 100%;

  thead {
    /* visually hidden, available to screenreaders */
    height: 1px;
    width: 1px;
    position: absolute;
    overflow: hidden;
    top: -10px;
  }

  tbody,
  tfoot {
    border: 1px solid var(--silver);
  }

  tfoot {
    border-top: 2px solid var(--mine-shaft) !important;
  }

  tbody,
  tfoot {
    tr {
      th,
      td {
        font-weight: normal;
        padding: var(--sm);

        // 1/4 width to the two comparison value cells
        &:nth-last-child(1):not(.typeTitle),
        &:nth-last-child(2):not(.typeTitle) {
          width: 25%;
        }
      }
    }

    tr {
      &.typeRow {
      }
      &.awardRow {
        td,
        th {
          border-bottom: 1px solid var(--silver);
        }
      }
    }
  }

  & + & {
    tbody {
      border-top-width: 0;
    }
  }
`;

export const LegendTable = styled(ComparisonTable)`
  tbody {
    border: none;
  }
`;
