import React from "react";
import PropTypes from "prop-types";
import { nbsp } from "functions/symbols";

const MidpointGradeNotice = ({
  midpointGradeExpanded,
  section,
  setMidpointGradeExpanded,
}) => {
  const {
    is_primary_section: isPrimary = false,
    waitlisted = false,
    grading: { midpointGrade } = {},
  } = section;

  const hideMidpointGradeNotice = () => {
    setMidpointGradeExpanded(false);
  };

  const gradeNoticeWrapper = {
    alignItems: "flex-start",
    display: "flex",
    flexWrap: "nowrap",
    marginTop: "10px",
  };

  const alertIconContainer = {
    margin: 0,
    order: 1,
    padding: "0px 8px 0px 0px",
    verticalAlign: "text-top",
  };

  const noticeContainer = {
    margin: 0,
    order: 2,
    padding: 0,
    verticalAlign: "text-top",
  };

  return (
    <>
      {isPrimary && !waitlisted && midpointGrade && midpointGradeExpanded && (
        <tr>
          <td>{nbsp}</td>
          <td style={{ margin: 0, padding: 0 }}>
            <div
              style={gradeNoticeWrapper}
              data-testid="semester-classes-section-class-schedule"
            >
              <div style={alertIconContainer}>
                <i className="fa fa-exclamation-triangle cc-icon-gold"></i>
              </div>
              <div style={noticeContainer}>
                Your midpoint grade is: <strong>{midpointGrade}</strong>
                <br />
                Your success is important to us. Midpoint grades do not appear
                on transcripts. <br />
                Please contact your instructor or an advisor as soon as
                possible. <br />
                <button
                  className="cc-button-link cc-inherit-font-size"
                  onClick={hideMidpointGradeNotice}
                >
                  Hide
                </button>
              </div>
            </div>
          </td>
          <td colSpan={2}>{nbsp}</td>
        </tr>
      )}
    </>
  );
};

MidpointGradeNotice.displayName = "MidpointGradeNotice";
MidpointGradeNotice.propTypes = {
  midpointGradeExpanded: PropTypes.bool,
  section: PropTypes.object,
  setMidpointGradeExpanded: PropTypes.func,
};

export default MidpointGradeNotice;
