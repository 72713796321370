import React from "react";
import { react2angular } from "react2angular";
import { ReduxProvider } from "components/data/ReduxProvider";
import { NotificationsCard } from "components/cards/NotificationsCard";

const NgNotificationsCard = () => (
  <ReduxProvider>
    <NotificationsCard />
  </ReduxProvider>
);

angular
  .module("calcentral.react")
  .component("notificationsCard", react2angular(NgNotificationsCard));
