import { Form } from "formik";
import styled from "styled-components";

export const ResponseFormStyles = styled(Form)`
  background: var(--white);
  border: none;
  padding: 0 var(--lg);

  label {
    font-size: var(--small-font-size);
    text-align: left;
    display: flex;
    align-items: flex-start;
    gap: var(--md);
  }
`;
