import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import SubTitle from "./SubTitle";
import { CardBody } from "components/ui/Card";
import { LargeSpacer } from "components/ui/VerticalSpacers";

import CourseTable from "./CourseTable";
import SwapIndicator from "./SwapIndicator";

export default function EnrollmentsTable({
  currentSemester,
  enrolledCourses,
  swapSections,
}) {
  return (
    <div data-testid="enrollments-table">
      <SubTitle><h3>Enrollments</h3></SubTitle>

      <CardBody>
        {currentSemester.timeBucket === "current" && (
            <h3>Current</h3>
        )}

        <CourseTable>
          <thead>
            <tr>
              <th>Course</th>
              <th>Title</th>
              <th>Grade Basis</th>
              <th style={{ textAlign: `right` }}>Units</th>
            </tr>
          </thead>
          <tbody>
            {enrolledCourses.map((course) => (
              <CourseRow
                key={course.url}
                course={course}
                swapSections={swapSections}
              />
            ))}
          </tbody>
        </CourseTable>
      </CardBody>

      {swapSections.length > 0 ? (
        <CardBody>
          <div>
            Classes marked ⇄ have had a swap requested. See &quot;Swaps&quot;
            below.
          </div>
        </CardBody>
      ) : (
        <LargeSpacer />
      )}
    </div>
  );
}

EnrollmentsTable.displayName = "EnrollmentsTable";
EnrollmentsTable.propTypes = {
  currentSemester: PropTypes.object,
  enrolledCourses: PropTypes.array,
  swapSections: PropTypes.array,
};

function CourseRow({ course, swapSections }) {
  const primarySection = course.sections.find(
    (section) => section.is_primary_section
  );

  const swapIndex =
    swapSections.findIndex((swap) => {
      return course.sections.find((section) => section.ccn === swap.from.ccn);
    }) + 1;

  return (
    <tr>
      <td>
        <div
          style={{
            display: `flex`,
            gap: `var(--medium-spacing)`,
            alignItems: "flex-start",
          }}
        >
          <SwapIndicator index={swapIndex} />
          <div>
            <Link to={course.url}>{course.course_code}</Link>
            {course.sections.map((section) => (
              <div key={section.section_label}>{section.section_label}</div>
            ))}
          </div>
        </div>
      </td>
      <td>{course.title}</td>
      <td>{course.sections.map((section) => section.grading.gradingBasis)}</td>
      <td style={{ textAlign: `right` }}>
        {primarySection.units && parseFloat(primarySection.units).toFixed(1)}
      </td>
    </tr>
  );
}

CourseRow.displayName = "CourseRow";
CourseRow.propTypes = {
  course: PropTypes.object,
  swapSections: PropTypes.array,
};
