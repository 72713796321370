import React from "react";
import PropTypes from "prop-types";
import DisclosureChevron from "react/components/DisclosureChevron";
import ButtonAsLink from "components/ui/ButtonAsLink";
import { FormatCurrency } from "./FormatCurrency";
import { SubItemRow } from "./SubItemRow";

export function TermItem({ item, isExpanded, onClick }) {
  const { title, total, amounts = [], subItems = [], totals = [] } = item;

  return (
    <tbody className="hoverFocus">
      {totals.length === 0 ? (
        <>
          <tr>
            <td>{title}</td>

            {amounts.map((amount, index) => (
              <td key={index} className="amount">
                {!isExpanded && <FormatCurrency value={amount} />}
              </td>
            ))}

            <td className="total">
              {!isExpanded && <FormatCurrency value={total} />}
            </td>

            <td className="controls">
              <ButtonAsLink onClick={onClick}>
                <DisclosureChevron expanded={isExpanded} />
              </ButtonAsLink>
            </td>
          </tr>

          {isExpanded &&
            subItems.map((subItem, index) => (
              <SubItemRow subItem={subItem} key={index} />
            ))}
        </>
      ) : (
        <>
          <tr>
            <td>asdfasd</td>
          </tr>
          {}
          {/* <tr>
            <td></td>
            {totals.map((total, index) => (
              <td key={index} className="amount">
                <FormatCurrency value={total} />
              </td>
            ))}
            <td></td>
          </tr> */}
        </>
      )}
    </tbody>
  );
}

TermItem.displayName = "TermItem";
TermItem.propTypes = {
  isExpanded: PropTypes.bool,
  item: PropTypes.shape({
    title: PropTypes.string,
    total: PropTypes.number,
    amounts: PropTypes.array,
    subItems: PropTypes.array,
    totals: PropTypes.array,
  }),
  onClick: PropTypes.func,
};
