import React, { useState } from "react";
import PropTypes from "prop-types";
import { BaseButton } from "components/ui/Buttons";
import ButtonAsLink from "components/ui/ButtonAsLink";
import DisclosureChevron from "components/../react/components/DisclosureChevron";
import { useSlice } from "components/hooks/useSlice";
import { useFeatures } from "components/hooks/useFeatures";

export function TermLimiter({ termsExpanded, toggleTermsExpanded }) {
  const { hubTermApi: hubTermFeatureActivated = false } = useFeatures();
  const { pastSemestersCount = 0 } = useSlice("myAcademics");
  const [termDataNoticeExpanded, setTermDataNoticeExpanded] = useState(false);

  return (
    <div data-testid="term-limiter" style={{ marginTop: "15px" }}>
      {pastSemestersCount <= 1 && hubTermFeatureActivated && (
        <>
          <strong>Note: </strong>
          Enrollment data for current term and back to Spring 2010 (where
          applicable) is displayed.
          {!termDataNoticeExpanded && (
            <div>
              <BaseButton
                data-testid="show-term-data-notice"
                style={{ display: "block", margin: "10px auto" }}
                onClick={() => {
                  setTermDataNoticeExpanded(true);
                }}
              >
                Show more
              </BaseButton>
            </div>
          )}
          {termDataNoticeExpanded && (
            <div data-testid="term-data-notice" style={{ marginTop: "10px" }}>
              If enrollments exist in terms prior to Spring 2010, the data will
              be displayed in Summer 2017. If you require a full record now,
              please order a transcript.
            </div>
          )}
        </>
      )}
      {pastSemestersCount > 1 && (
        <div style={{ display: `flex`, justifyContent: `center` }}>
          <ButtonAsLink onClick={() => toggleTermsExpanded()}>
            Show {termsExpanded ? "Less" : "More"}{" "}
            <DisclosureChevron expanded={termsExpanded} />
          </ButtonAsLink>
        </div>
      )}
    </div>
  );
}

TermLimiter.displayName = "TermLimiter";
TermLimiter.propTypes = {
  termsExpanded: PropTypes.bool,
  toggleTermsExpanded: PropTypes.func,
};
