import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Card, CardBody, CardTitle } from "components/ui/Card";
import styled from "styled-components";

import ButtonAsLink from "components/ui/ButtonAsLink";
import InfoIcon from "components/ui/InfoIcon";
import Popover, { usePopover } from "components/ui/Popover";
import WithMessage from "components/WithMessage";

import { getTuitionCohort } from "data/actions/tuitionCohortActions";
import getMessageCatalog from "data/actions/getMessageCatalog";

export default function TuitionStabilityCard() {
  const dispatch = useDispatch();

  const { csrfToken, shouldFetchTuitionCohort, messageHTML } = useSelector(
    ({
      config: { csrfToken },
      myStatus: {
        academicRoles: {
          current: { degreeSeeking, ugrd } = {},
          historical: { degreeSeeking: histDegreeSeeking, ugrd: histUgrd } = {},
        } = {},
      } = {},
      tuitionCohort: { messageHTML } = {},
    }) => {
      const shouldFetchTuitionCohort =
        (ugrd && degreeSeeking) || (histUgrd && histDegreeSeeking);

      return {
        csrfToken,
        shouldFetchTuitionCohort,
        messageHTML,
      };
    }
  );

  // Don't bother to get the messageHTML if the user is not a current or
  // historical undergraduate, degree-seeking student
  useEffect(() => {
    if (csrfToken && shouldFetchTuitionCohort) {
      dispatch(getMessageCatalog(csrfToken));
      dispatch(getTuitionCohort(csrfToken));
    }
  }, [dispatch, csrfToken, shouldFetchTuitionCohort]);

  const popover = usePopover();

  if (shouldFetchTuitionCohort && messageHTML) {
    return (
      <Card data-testid="tuition-stability-card">
        <CardTitle>
          <h2>Tuition Stability Plan</h2>
        </CardTitle>
        <CardBody>
          <div data-testid="tuition-cohort-info-link">
            <strong>
              Tuition Cohort{" "}
              <ButtonAsLink onClick={popover.open}>
                <InfoIcon />
              </ButtonAsLink>
            </strong>
          </div>
          <div
            data-testid="tuition-stability-message"
            style={{ position: `relative` }}
          >
            {popover.isOpen && (
              <PopoverContainer>
                <Popover
                  ref={popover.ref}
                  title="Tuition Stability Plan"
                  close={popover.close}
                >
                  <WithMessage set="28000" number="320">
                    {(message) => (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: message.description,
                        }}
                      ></div>
                    )}
                  </WithMessage>
                </Popover>
              </PopoverContainer>
            )}
            <div dangerouslySetInnerHTML={{ __html: messageHTML }} />
          </div>
        </CardBody>
      </Card>
    );
  }

  return null;
}
TuitionStabilityCard.displayName = "TuitionStabilityCard";

const PopoverContainer = styled.div`
  .header {
    padding: 10px 10px 0 10px;
  }
  .body {
    padding: 10px;
  }
`;
