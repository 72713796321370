import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { useFeatures } from "components/hooks/useFeatures";
import { useSlice } from "components/hooks/useSlice";
import ButtonLinkGroup from "components/ui/ButtonLinkGroup";
import { useCourseParams } from "components/hooks/useCourseParams";
import { useReact } from "components/hooks/useReact";

export function TeachingButtonGroup() {
  const { hasRosterAccess = false } = useSlice("myAcademics");
  const { classInfoEnrollmentTab: classInfoEnrollmentTabEnabled } =
    useFeatures();

  const { semesterId } = useCourseParams();

  if (hasRosterAccess) {
    return (
      <ButtonLinkGroup>
        <TeachingButtonLink end to="./">
          Class Info
        </TeachingButtonLink>

        {classInfoEnrollmentTabEnabled && (
          <TeachingButtonLink to="./enrollment">Enrollment</TeachingButtonLink>
        )}

        <TeachingButtonLink to="./roster">Roster</TeachingButtonLink>
        <TeachingButtonLink
          to={`/academics/semester/${semesterId}`}
          style={{ fontSize: `11px`, fontWeight: `bold` }}
        >
          Grading
        </TeachingButtonLink>
      </ButtonLinkGroup>
    );
  }

  return null;
}

TeachingButtonGroup.displayName = "TeachingButtonGroup";

const TeachingButtonLink = ({ to, children, end, style = {} }) => {
  const isReactOnly = useReact();

  if (isReactOnly) {
    return (
      <NavLink end={end} to={to} style={style}>
        {children}
      </NavLink>
    );
  }

  const href = TabPathFor({ to });
  const isActive = window.location.pathname === href;
  const className = isActive ? "active" : "";

  return (
    <a
      href={href}
      className={className}
      role="tab"
      aria-current={isActive ? "true" : "false"}
    >
      {children}
    </a>
  );
};

TeachingButtonLink.displayName = "TeachingButtonLink";
TeachingButtonLink.propTypes = {
  to: PropTypes.string,
  end: PropTypes.bool,
  children: PropTypes.node,
  style: PropTypes.object,
};

function TabPathFor({ to }) {
  const { semesterId, classId } = useCourseParams();

  const urlBase = `/academics/teaching-semester/${semesterId}/class/${classId}`;

  if (to === "./") {
    return urlBase;
  }

  if (to === "./enrollment" || to === "./roster") {
    return urlBase + to.replace(".", "");
  }

  return to;
}
