import { useSelector } from "react-redux";
import { useAdvisingStudentUID } from "./useAdvisingStudentUID";

export function useAdvisingStudent(callback = (user) => user) {
  const uid = useAdvisingStudentUID();

  return useSelector((state) => {
    return callback(state.byUser[uid] || {});
  });
}
