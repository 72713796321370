import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { columnHeaderPropTypeShape, studentPropTypeShape } from "./propTypes";

import { useCurrentWidth } from "react-socks";
import { largeBreakpoint, mediumBreakpoint } from "config/media";

import {
  EnrollmentTable,
  EnrollmentTableContainer,
  EnrollmentTableHeader,
} from "./EnrollmentTable";

const DefaultSectionContainer = styled.div`
  div.section {
    margin-top: var(--medium-spacing);
  }
`;

const NoStudentsNotice = styled.div`
  font-size: 13px;
  padding: 5px;
`;

const bmailLink = function (toAddress) {
  var urlEncodedToAddress = encodeURIComponent(toAddress);
  return (
    "https://mail.google.com/mail/u/0/?view=cm&fs=1&tf=1&source=mailto&to=" +
    urlEncodedToAddress
  );
};

export const DefaultSection = function ({
  columnHeaders,
  isWaitlistMode,
  searchFilters,
  selectedStudentIds,
  students,
  toggleSelectedStudent,
  toggleAllSelectedStudents,
}) {
  const { sortColumn, sortDirection, setSortColumn, setSortDirection } =
    searchFilters;

  const testIdSuffix = isWaitlistMode ? "waitlist" : "enrollments";
  const listStatus = isWaitlistMode ? "waitlisted" : "enrolled";

  return (
    <DefaultSectionContainer data-testid="default-section-container">
      <div className="section" data-testid="default-section-note">
        <strong>Notes:</strong> Use headers to sort columns. Email links and
        buttons work best when signed into bMail in another window/tab in the
        same browser as CalCentral.
      </div>
      <div className="section">
        <EnrollmentTableContainer data-testid="enrollment-table-container">
          <EnrollmentTable data-testid={`enrollment-table-${testIdSuffix}`}>
            <EnrollmentTableHeader
              columnHeaders={columnHeaders}
              isWaitlistMode={isWaitlistMode}
              setSortColumn={setSortColumn}
              setSortDirection={setSortDirection}
              sortDirection={sortDirection}
              sortColumn={sortColumn}
              toggleAllSelectedStudents={toggleAllSelectedStudents}
            />
            <tbody>
              {students &&
                students.length > 0 &&
                students.map((student, studentIndex) => (
                  <StudentRow
                    key={studentIndex}
                    isWaitlistMode={isWaitlistMode}
                    student={student}
                    selectedStudentIds={selectedStudentIds}
                    toggleSelectedStudent={toggleSelectedStudent}
                  />
                ))}
            </tbody>
          </EnrollmentTable>
          {students && students.length === 0 && (
            <NoStudentsNotice>
              No students are currently {listStatus} in this class.
            </NoStudentsNotice>
          )}
        </EnrollmentTableContainer>
      </div>
    </DefaultSectionContainer>
  );
};

DefaultSection.displayName = "DefaultSection";
DefaultSection.propTypes = {
  columnHeaders: PropTypes.arrayOf(columnHeaderPropTypeShape),
  isWaitlistMode: PropTypes.bool,
  searchFilters: PropTypes.object,
  selectedStudentIds: PropTypes.object,
  students: PropTypes.arrayOf(studentPropTypeShape),
  toggleSelectedStudent: PropTypes.func,
  toggleAllSelectedStudents: PropTypes.func,
};

function StudentRow({
  isWaitlistMode,
  selectedStudentIds,
  student,
  toggleSelectedStudent,
}) {
  const {
    columns,
    first_name,
    email,
    grade_option,
    last_name,
    majors,
    pronouns,
    terms_in_attendance,
    student_id,
    units,
    waitlist_position,
  } = student;

  const isChecked = selectedStudentIds && selectedStudentIds.has(student_id);
  const browserWidth = useCurrentWidth();

  return (
    <tr data-testid="student-row">
      <td>
        <input
          type="checkbox"
          value={student_id}
          name="student_id"
          onChange={(e) => toggleSelectedStudent(e.target.value)}
          checked={isChecked}
        />
      </td>
      {isWaitlistMode && (
        <td>
          <strong>{waitlist_position}</strong>
        </td>
      )}
      <td>
        {last_name}, {first_name}
      </td>
      {browserWidth >= largeBreakpoint && (
        <td>
          {pronouns}
        </td>
      )}
      <td>
        {email && (
          <a href={bmailLink(email)} rel="noreferrer" target="_blank">
            {email}
          </a>
        )}
      </td>
      {browserWidth >= mediumBreakpoint && (
        <td>
          {majors.map((major, majorIndex) => (
            <div key={majorIndex}>{major}</div>
          ))}
        </td>
      )}
      {browserWidth >= mediumBreakpoint &&
        columns &&
        columns.map((column, columnIndex) => (
          <td key={columnIndex}>{column.section_number}</td>
        ))}
      {browserWidth >= largeBreakpoint && (
        <>
          <td>{terms_in_attendance}</td>
          <td>{units}</td>
          <td>{grade_option}</td>
          <td>{student_id}</td>
        </>
      )}
    </tr>
  );
}

StudentRow.displayName = "StudentRow";
StudentRow.propTypes = {
  isWaitlistMode: PropTypes.bool,
  selectedStudentIds: PropTypes.object,
  student: studentPropTypeShape,
  toggleSelectedStudent: PropTypes.func,
};
