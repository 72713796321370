import React from "react";
import PropTypes from "prop-types";
import { Field, useFormikContext } from "formik";
import { VisuallyHidden } from "components/ui/VisuallyHidden";
import {
  OPTION_DECLINE,
  responseReasonShape,
  optionShape,
} from "../statusProps";
import { LargeSpacer } from "components/ui/VerticalSpacers";

export const DeclineResponse = ({ responseReasons, option }) => {
  const {
    values: { progAction },
  } = useFormikContext();

  if (progAction === OPTION_DECLINE && option.progAction === OPTION_DECLINE) {
    return (
      <div style={{ marginLeft: `30px`, marginTop: `var(--md)` }}>
        <div>
          You have indicated that you wish to <strong>decline</strong> our offer
          of admission. We understand that your decision is an important one and
          we&apos;d appreciate your reason for doing so.
        </div>

        <LargeSpacer />
        <div className="cc-select">
          <Field as="select" name="responseReason">
            {responseReasons.map((reason, index) => (
              <option key={index} value={reason.responseReason}>
                {reason.descr}
              </option>
            ))}
          </Field>
        </div>

        <LargeSpacer />
        <VisuallyHidden>
          <label htmlFor="responseDescription">Specify:</label>
        </VisuallyHidden>

        <Field
          type="text"
          name="responseDescription"
          placeholder="Specify"
          maxLength="30"
        />

        <div>
          To confirm this decision, please select the
          <strong>I Decline This Offer</strong> button.
        </div>
      </div>
    );
  }

  return null;
};

DeclineResponse.propTypes = {
  responseReasons: PropTypes.arrayOf(responseReasonShape),
  option: optionShape,
};
