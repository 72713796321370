import styled from "styled-components";
import { CCTable } from "components/ui/Table";

export const ClassesTable = styled(CCTable)`
  thead,
  tbody {
    tr {
      th,
      td {
        padding-left: 5px;
        padding-right: 5px;

        &:first-child {
          padding-left: 0;
        }

        &:first-child {
          padding-right: 0;
        }
      }
    }
  }

  tbody + thead {
    tr {
      th {
        padding-top: var(--lg);
      }
    }
  }
`;
