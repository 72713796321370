import { useState } from "react";

export function useToggleState(boolean = false) {
  const [toggle, setToggle] = useState(boolean);

  function actuate() {
    setToggle((toggle) => !toggle);
  }

  return [toggle, actuate];
}
