import React from "react";
import PropTypes from "prop-types";
import VisuallyHidden from "components/ui/VisuallyHidden";
import { EnvelopeIcon } from "./SVGIcons";

export function MailtoLink({
  emailAddress,
  children,
  text,
  isDisabled = false,
}) {
  return (
    <a href={`mailto:${emailAddress}`} disabled={isDisabled}>
      {children || text}
      <VisuallyHidden>, this link opens an email service</VisuallyHidden>
      &nbsp;
      <EnvelopeIcon />
    </a>
  );
}

MailtoLink.displayName = "MailtoLink";
MailtoLink.propTypes = {
  emailAddress: PropTypes.string,
  children: PropTypes.node,
  text: PropTypes.string,
  isDisabled: PropTypes.bool,
};
