import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { Card, CardBody, CardTitle } from "components/ui/Card";
import Spinner from "components/../react/components/Spinner";

import AdvisorCardSection from "./AdvisorCardSection";
import HaasProgramLinks from "./HaasProgramLinks";
import AdvisorsList from "./AdvisorsList";
import ActionItems from "./ActionItems";

import AccessibleLinkContent from "components/ui/AccessibleLinkContent";
import TransformsAPILink from "components/ui/TransformsAPILink";
import APILink from "components/ui/APILink";

import { BaseButtonLink } from "components/ui/ButtonLink";
import { PlusIcon } from "components/ui/Icons";

const appointmentButtonStyle = {
  margin: "0 0 0 5px",
};

const ShortPlusIcon = styled(PlusIcon)`
  height: 10px;
  color: var(--jewel);
`;

const appointmentButtonDisabled = function (
  link,
  isDirectlyAuthenticated,
  isAdvisingStudentLookup
) {
  return (
    !(link && link.url) || !isDirectlyAuthenticated || isAdvisingStudentLookup
  );
};

export default function AdvisingCard({
  actionItems,
  advisors,
  advisingLinksEnabled,
  currentStudentAcademicRoles,
  id,
  isAdvisorLookup,
  isDirectlyAuthenticated,
  isErrored,
  loadState,
  links,
  showAdvising,
  testid = 'advising-card',
}) {
  const showAppointmentLinks = advisingLinksEnabled && links;

  if (showAdvising) {
    return (
      <Card id={id} data-testid={testid}>
        <CardTitle style={{ alignItems: "center" }}>
          <h2>Advising</h2>
          {showAppointmentLinks && (
            <TransformsAPILink link={links.newAppointment}>
              {({ props, name, showNewWindow }) => (
                <BaseButtonLink
                  {...props}
                  disabled={appointmentButtonDisabled(
                    links.newAppointment,
                    isDirectlyAuthenticated,
                    isAdvisorLookup
                  )}
                  name={name}
                  style={appointmentButtonStyle}
                >
                  <ShortPlusIcon />
                  <AccessibleLinkContent
                    text="New Appointments"
                    showNewWindow={showNewWindow}
                  />
                </BaseButtonLink>
              )}
            </TransformsAPILink>
          )}
        </CardTitle>
        <CardBody>
          {loadState !== "success" && <Spinner />}
          {loadState === "success" && (
            <>
              {isErrored && (
                <div>There was an error retrieving advising data.</div>
              )}
              {!isErrored && (
                <>
                  <HaasProgramLinks
                    currentStudentAcademicRoles={currentStudentAcademicRoles}
                  />
                  <AdvisorsList
                    advisors={advisors}
                    isAdvisorLookup={isAdvisorLookup}
                    currentStudentAcademicRoles={currentStudentAcademicRoles}
                  />
                  <ActionItems actionItems={actionItems} />
                  {showAppointmentLinks && (
                    <AdvisorCardSection>
                      <h3>Appointments</h3>
                      <div style={{ marginTop: "var(--small-spacing)" }}>
                        {links && links.manageAppointments && (
                          <APILink
                            link={links.manageAppointments}
                            disabled={appointmentButtonDisabled(
                              links.manageAppointments,
                              isDirectlyAuthenticated,
                              isAdvisorLookup
                            )}
                          />
                        )}
                      </div>
                    </AdvisorCardSection>
                  )}
                </>
              )}
            </>
          )}
        </CardBody>
      </Card>
    );
  } else {
    return null;
  }
}

AdvisingCard.displayName = "AdvisingCard";
AdvisingCard.propTypes = {
  actionItems: PropTypes.array,
  advisors: PropTypes.array,
  advisingLinksEnabled: PropTypes.bool,
  id: PropTypes.string,
  isAdvisorLookup: PropTypes.bool,
  isDirectlyAuthenticated: PropTypes.bool,
  isErrored: PropTypes.bool,
  loadState: PropTypes.string,
  links: PropTypes.object,
  showAdvising: PropTypes.bool,
  currentStudentAcademicRoles: PropTypes.object,
  testid: PropTypes.string,
};
