import React from "react";
import styled from "styled-components";
import { usdFilter } from "components/../react/helpers/filters";
import { BasicTable } from "components/ui/Table";
import { useSlice } from "components/hooks/useSlice";

export function Balances() {
  const {
    summary: {
      amountDueNow = 0,
      chargesNotYetDue = 0,
      totalUnpaidBalance = 0,
    } = {},
  } = useSlice("billingActivity");

  return (
    <BasicTable data-testid="billing-summary-balances">
      <tbody>
        <BillingRow>
          <td>Due Now</td>
          <td>{usdFilter(amountDueNow)}</td>
        </BillingRow>
        <BillingRow>
          <td>Not Yet Due</td>
          <td>{usdFilter(chargesNotYetDue)}</td>
        </BillingRow>
        <TotalsRow>
          <td>Total Unpaid Balance</td>
          <td>{usdFilter(totalUnpaidBalance)}</td>
        </TotalsRow>
      </tbody>
    </BasicTable>
  );
}

Balances.displayName = "Balances";

const BillingRow = styled.tr`
  td {
    height: 32px;
    vertical-align: top;

    &:last-child {
      width: 100px;
      text-align: right;
    }
  }
`;
BillingRow.displayName = "BillingRow";

const TotalsRow = styled(BillingRow)`
  td {
    vertical-align: center;
  }

  td:first-child {
    font-weight: bold;
    padding: 10px 0;
  }

  td:last-child {
    text-align: right;
    padding: 10px 0;
    border-top: 1px solid var(--gallery);
  }
`;
TotalsRow.displayName = "TotalsRow";
