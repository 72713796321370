import React from "react";
import PropTypes from "prop-types";
import { BreakpointFragment } from "components/ui/BreakpointFragment";

import APILink from "components/ui/APILink";

import { MidpointGradingStatus } from "./MidpointGradingStatus";
import { FinalGradingStatusIcon } from "./FinalGradingStatusIcon";
import { IncompleteGradingStatus } from "./IncompleteGradingStatus";

export function SectionHeader({
  isSummerSemester,
  teachingCoursesHaveTopics,
  hasMidpointClass,
}) {
  const titleWidth =
    45 -
    10 * (hasMidpointClass ? 1 : 0) -
    10 * (isSummerSemester ? 1 : 0) -
    15 * (teachingCoursesHaveTopics ? 1 : 0);

  return (
    <tr>
      <th scope="col" width="15%">
        Course
      </th>

      <BreakpointFragment medium up>
        <th scope="col" width="10%">
          Class Number
        </th>
      </BreakpointFragment>

      <th scope="col" width={`${titleWidth}%`}>
        Title
      </th>

      {teachingCoursesHaveTopics && (
        <th scope="col" width="15%">
          Topic
        </th>
      )}

      <BreakpointFragment medium up>
        <th scope="col" width="10%" className="text-center">
          Section
        </th>

        {isSummerSemester && <th className="text-center">Grades Due</th>}

        <th scope="col" width="10%" className="text-center">
          Grading Links
        </th>

        {hasMidpointClass && (
          <th scope="col" width="10%" className="text-center">
            Midpoint Status
          </th>
        )}

        <th scope="col" width="10%" className="text-center">
          INC
        </th>

        <th scope="col" width="10%" className="text-center">
          Final Status
        </th>
      </BreakpointFragment>
    </tr>
  );
}

SectionHeader.displayName = "SectionHeader";
SectionHeader.propTypes = {
  isSummerSemester: PropTypes.bool,
  hasMidpointClass: PropTypes.bool,
  teachingCoursesHaveTopics: PropTypes.bool,
};

export function SectionRow({
  course,
  section,
  listings,
  hasMidpointClass,
  teachingCoursesHaveTopics,
  isSummerSemester,
}) {
  if (section.is_primary_section) {
    return (
      <tr>
        <td>
          {listings.map((listing, index) => (
            <div key={index}>{listing.course_code}</div>
          ))}
          <BreakpointFragment small only>
            {section.section_label}
          </BreakpointFragment>
        </td>
        <BreakpointFragment medium up>
          <td>{section.ccn}</td>
        </BreakpointFragment>
        {teachingCoursesHaveTopics && (
          <td>
            {section.topic_description ? section.topic_description : "&mdash;"}
          </td>
        )}
        <td>{course.title}</td>
        <td className="text-center">{section.section_label}</td>
        {isSummerSemester && (
          <td className="text-center">
            {section.gradingPeriodEndDateFormatted}
          </td>
        )}
        <td className="text-center">
          {section.gradingLink?.url ? (
            <APILink link={section.gradingLink} text="Grades" />
          ) : (
            <div>&mdash;</div>
          )}
        </td>
        {hasMidpointClass && (
          <td className="text-center">
            <MidpointGradingStatus status={section.ccMidpointGradingStatus} />
          </td>
        )}
        <td className="text-center">
          <IncompleteGradingStatus
            hasIncompleteGrades={section.hasIncompleteGrades}
          />
        </td>
        <td className="text-center">
          <FinalGradingStatusIcon status={section.ccGradingStatus} />
        </td>
      </tr>
    );
  }

  return null;
}

SectionRow.propTypes = {
  course: PropTypes.object,
  section: PropTypes.object,
  listings: PropTypes.array,
  isSummerSemester: PropTypes.bool,
  hasMidpointClass: PropTypes.bool,
  teachingCoursesHaveTopics: PropTypes.bool,
};
