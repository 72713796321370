import React from "react";
import styled from "styled-components";

import { Card, CardTitle, CardBody } from "components/ui/Card";
import Spinner from "components/ui/Spinner";
import TinyText from "components/ui/TinyP";
import WithAPILink from "components/ui/WithAPILink";
import WithLinks from "components/data/WithLinks";

import clipboardList from "icons/clipboard-list.svg";

import { useSlice } from "components/hooks/useSlice";
import { useAcademicRoles } from "components/hooks/useAcademicRoles";

export function LawDegreeAuditCard() {
  const { loadState = "pending" } = useSlice("apiLinks");
  const { lawDegreeAudit: showCard = false } = useAcademicRoles();

  if (showCard) {
    return (
      <WithLinks>
        <Card data-testid="law-degree-audit-card">
          <CardTitle>
            <h2>Law Degree Audit</h2>
          </CardTitle>
          <CardBody>
            {loadState === "pending" ? (
              <Spinner />
            ) : loadState === "failure" ? (
              <>The Law Degree Audit is not available right now.</>
            ) : (
              <DegreeAuditStyles>
                <div>
                  <img src={clipboardList} />
                </div>
                <div>
                  <h3>Law Degree Audit</h3>
                  <TinyText>
                    Review your progress in meeting degree requirements.
                  </TinyText>
                  <p>
                    <WithAPILink urlID="UC_AA_LAW_DEGREE_AUDIT" />
                  </p>
                  <p>
                    Learn more at{" "}
                    <WithAPILink urlID="UC_AA_LAW_DEGREE_AUDIT_FAQ" />
                  </p>
                </div>
              </DegreeAuditStyles>
            )}
          </CardBody>
        </Card>
      </WithLinks>
    );
  }

  return null;
}

LawDegreeAuditCard.displayName = "LawDegreeAuditCard";

const DegreeAuditStyles = styled.div`
  display: flex;

  div:first-child {
    width: 20px;
    padding-top: 2px;
  }

  h3 {
    color: var(--dove-gray);
    font-size: 13px;
    margin: 0;
  }

  h3 + p {
    margin-top: 0;
  }

  p {
    line-height: 1.5;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;
