import React from "react";
import PropTypes from "prop-types";
import TimeCell from "../TimeCell";
import MissingAppointmentMessage from "../MissingAppointmentMessage";
import { CCTable } from "components/ui/Table";

import { enrollmentPeriodShape } from "../propTypes";

function EnrollmentPeriodRow({
  enrollmentPeriod: {
    beginTime: { pacific },
  },
  maxUnits,
}) {
  return (
    <tr>
      <td>Begin Enrollment {maxUnits} Units Max</td>
      <td>
        <TimeCell time={pacific} />
      </td>
    </tr>
  );
}

EnrollmentPeriodRow.displayName = "EnrollmentPeriodRow";
EnrollmentPeriodRow.propTypes = {
  enrollmentPeriod: enrollmentPeriodShape,
  maxUnits: PropTypes.string,
};

function SummerEnrollmentPeriods({ enrollmentPeriods, maxUnits }) {
  if (enrollmentPeriods.length) {
    return (
      <CCTable>
        <thead>
          <tr>
            <th>Law Period</th>
            <th width="33%">Start</th>
          </tr>
        </thead>
        <tbody>
          <EnrollmentPeriodRow
            enrollmentPeriod={enrollmentPeriods[0]}
            maxUnits={maxUnits}
          />
        </tbody>
      </CCTable>
    );
  }

  return <MissingAppointmentMessage />;
}

SummerEnrollmentPeriods.displayName = "SummerEnrollmentPeriods";
SummerEnrollmentPeriods.propTypes = {
  enrollmentPeriods: PropTypes.arrayOf(PropTypes.shape({})),
  maxUnits: PropTypes.string,
};

export default SummerEnrollmentPeriods;
