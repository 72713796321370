import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { BulletList } from "components/ui/Lists";
import WithAPILink from "components/ui/WithAPILink";
import EnrollmentInfoMessage from "./EnrollmentInfoMessage";
import { MediumSpacer } from "components/ui/VerticalSpacers";

import {
  UC_SR_SS_BERK_ACAD_GUIDE,
  UC_SR_SS_BERK_TIME,
  UC_SR_SS_ENRL_RULES,
  UC_SR_SS_ENRL_CAL,
  UC_SR_SS_ENRL_FPFCAT,
  UC_SR_SS_ENRL_FAQS,
  UC_SR_SS_FINAL_EXAM_SCH,
  UC_SR_SS_TERM_WITH_CANC,
  UC_CX_GT_GRADEOPT_ADD,
  UC_CX_GT_SRLATEDROP_ADD,
} from "functions/urlIds";

const fallProgramForFreshmenTermPlanPresent = (termPlans) => {
  const fpfPlan = termPlans.find((tp) => {
    return tp.academicCareerCode == "UGRD" && tp.academicPlanCode == "25000U";
  });
  return fpfPlan !== undefined;
};

function LearnMore({
  term,
  isSummerVisitor,
  isCourseworkOnly,
  isDegreeSeeking,
  presentation,
  studentCareers,
}) {
  const fpfPresent = fallProgramForFreshmenTermPlanPresent(
    presentation.termPlans
  );
  const isSummer = term.semester === "Summer";

  const classDropForm =
    !isSummer && studentCareers.includes("Undergraduate") && isDegreeSeeking;

  const lateChangePetition =
    isSummer &&
    (isSummerVisitor ||
      isCourseworkOnly ||
      studentCareers.includes("Graduate"));

  const links = [
    UC_SR_SS_BERK_ACAD_GUIDE,
    UC_SR_SS_BERK_TIME,
    UC_SR_SS_ENRL_RULES,
    UC_SR_SS_ENRL_CAL,
    UC_SR_SS_ENRL_FAQS,
    UC_SR_SS_FINAL_EXAM_SCH,
    UC_SR_SS_TERM_WITH_CANC,
  ];

  return (
    <div data-testid="enrollment-learn-more">
      <EnrollmentInfoMessage />
      <MediumSpacer />
      <h2 style={{ fontSize: `13px` }}>Resources</h2>
      <BulletList>
        {fpfPresent && (
          <li>
            <WithAPILink urlID={UC_SR_SS_ENRL_FPFCAT} />
          </li>
        )}
        {links.map((urlID) => (
          <li key={urlID}>
            <WithAPILink urlID={urlID} />
          </li>
        ))}

        {classDropForm && (
          <li>
            <WithAPILink urlID={UC_CX_GT_SRLATEDROP_ADD} />
          </li>
        )}

        {lateChangePetition && (
          <li>
            <WithAPILink urlID={UC_CX_GT_GRADEOPT_ADD} />
          </li>
        )}
      </BulletList>
    </div>
  );
}

const TermPlanShape = PropTypes.shape({
  academicCareerCode: PropTypes.string,
  academicPlanCode: PropTypes.string,
  academicProgramCode: PropTypes.string,
});

LearnMore.displayName = "LearnMore";
LearnMore.propTypes = {
  term: PropTypes.shape({
    semester: PropTypes.string,
  }),
  presentation: PropTypes.shape({
    design: PropTypes.string,
    termId: PropTypes.string,
    termPlans: PropTypes.arrayOf(TermPlanShape),
  }),
  isCourseworkOnly: PropTypes.bool,
  isSummerVisitor: PropTypes.bool,
  isDegreeSeeking: PropTypes.bool,
  studentCareers: PropTypes.arrayOf(PropTypes.string),
};

function mapStateToProps(state) {
  const { myAcademics: { collegeAndLevel: { careers = [] } = {} } = {} } =
    state;

  const {
    myStatus: {
      academicRoles: {
        current: {
          degreeSeeking: isDegreeSeeking,
          courseworkOnly: isCourseworkOnly,
          summerVisitor: isSummerVisitor,
        } = {},
      } = {},
    } = {},
  } = state;

  return {
    isSummerVisitor,
    isCourseworkOnly,
    isDegreeSeeking,
    studentCareers: careers,
  };
}

export default connect(mapStateToProps)(LearnMore);
