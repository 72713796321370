import { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { fetchMyAcademics } from "data/actions/myAcademicsActions";

export default function WithMyAcademics({ children }) {
  const dispatch = useDispatch();

  const currentUID = useSelector((state) => {
    const { currentUID } = state;
    return currentUID;
  });

  useEffect(() => {
    if (currentUID) {
      dispatch(fetchMyAcademics());
    }
  }, [dispatch, currentUID]);

  return children;
}

WithMyAcademics.propTypes = {
  children: PropTypes.node,
};
