import React from "react";
import PropTypes from "prop-types";
import AdvisorCardSection from "./AdvisorCardSection";
import APILink from "components/ui/APILink";
import { MailtoLink } from "components/ui/MailtoLink";

import { useSelector } from "react-redux";
import currentUserState from "utils/currentUserState";

export default function Advisor({ advisor, isAdvisorLookup }) {
  const { collegeAdvisingNeighborhoodLink, isDirectlyAuthenticated } =
    useSelector((state) => {
      const {
        academicAdvising: {
          feed: { links: { collegeAdvisingNeighborhoodLink } = {} } = {},
        } = {},
      } = currentUserState(state);

      const { myStatus: { isDirectlyAuthenticated } = {} } = state;

      return {
        collegeAdvisingNeighborhoodLink,
        isDirectlyAuthenticated,
      };
    });

  if (advisor) {
    return (
      <AdvisorCardSection data-testid="academic-advisor">
        <div>
          <strong>{advisor.assignedAdvisorType}</strong>
          {advisor.assignedAdvisorProgram && (
            <span>{" (" + advisor.assignedAdvisorProgram + ")"}</span>
          )}
        </div>
        <div>{advisor.assignedAdvisorName}</div>

        {advisor.debug &&
          (advisor.debug.assignedAdvisorId !== "UCBUCLS" ||
            (advisor.debug.assignedAdvisorId === "UCBUCLS" &&
              !collegeAdvisingNeighborhoodLink)) && (
            <div>
              <MailtoLink
                emailAddress={advisor.assignedAdvisorEmail}
                isDisabled={
                  !advisor.assignedAdvisorEmail ||
                  !isDirectlyAuthenticated ||
                  isAdvisorLookup
                }
              >
                {advisor.assignedAdvisorEmail}
              </MailtoLink>
            </div>
          )}
        {locationDisplay(advisor.assignedAdvisorLocation1, advisor.assignedAdvisorLocation2)}
        {advisor.assignedAdvisorAddlInfo && (
          <div>{advisor.assignedAdvisorAddlInfo}</div>
        )}
        {advisor.debug.assignedAdvisorId === "UCBUCLS" &&
          collegeAdvisingNeighborhoodLink && (
            <div>
              <APILink link={collegeAdvisingNeighborhoodLink} />
            </div>
          )}
      </AdvisorCardSection>
    );
  } else {
    return null;
  }
}

Advisor.displayName = "Advisor";
Advisor.propTypes = {
  advisor: PropTypes.object,
  isAdvisorLookup: PropTypes.bool,
};

function locationDisplay(location1, location2) {
  let separator = '';
  let displayLocation1 = location1 || '';
  let displayLocation2 = location2 || '';
  if (location1 && location2) {
    separator = ', ';
  }
  return displayLocation1 + separator + displayLocation2;
}
