import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { termInstructionShape } from "../propTypes";
import { parseISO, endOfDay } from "date-fns";
import { FormattedTimeCell } from "../TimeCell";

function EarlyDropDeadline({ termInstructions, termId }) {
  const termInstruction = termInstructions.find((ti) => ti.term_id === termId);
  const { earlyDropDeadline } = termInstruction || {};

  if (earlyDropDeadline) {
    const time = endOfDay(parseISO(earlyDropDeadline));
    return <FormattedTimeCell zonedTime={time} />;
  }

  return null;
}

EarlyDropDeadline.displayName = "EarlyDropDeadline";
EarlyDropDeadline.propTypes = {
  termInstructions: PropTypes.arrayOf(termInstructionShape),
  termId: PropTypes.string,
};

const mapStateToProps = ({ currentUID, users }) => {
  const { enrollmentResources: { termInstructions = [] } = {} } =
    users[currentUID] || {};

  return { termInstructions };
};

export default connect(mapStateToProps)(EarlyDropDeadline);
