import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { react2angular } from "react2angular";
import { ReduxProvider } from "components/data/ReduxProvider";
import { CampusCardTitle } from "components/pages/CampusPage/CampusCardTitle";

function NgCampusCardTitle() {
  return (
    <ReduxProvider>
      <Router>
        <Routes>
          <Route path="/campus/:categoryId" element={<CampusCardTitle />} />
        </Routes>
      </Router>
    </ReduxProvider>
  );
}

angular
  .module("calcentral.react")
  .component("campusCardTitle", react2angular(NgCampusCardTitle));
