import React, { useState } from "react";
import PropTypes from "prop-types";
import ButtonAsLink from "components/ui/ButtonAsLink";
import { ChevronDownIcon, ChevronUpIcon } from "components/ui/Icons";

export function ShowMore({ text, view }) {
  const [expanded, setExpanded] = useState(false);

  return (
    <>
      <div
        data-testid="show-more"
        style={{ padding: `var(--large-spacing)`, textAlign: `center` }}
      >
        <ShowMoreButton
          text={text}
          expanded={expanded}
          setExpanded={setExpanded}
        />
      </div>

      {expanded && view}
    </>
  );
}
ShowMore.displayName = "ShowMore";
ShowMore.propTypes = {
  text: PropTypes.string.isRequired,
  view: PropTypes.node.isRequired,
};

export function ShowMoreButton({ expanded, setExpanded, text = "Show More" }) {
  return (
    <ButtonAsLink onClick={() => setExpanded(!expanded)}>
      {expanded ? (
        <>
          Show Less <ChevronUpIcon />
        </>
      ) : (
        <>
          {text} <ChevronDownIcon />
        </>
      )}
    </ButtonAsLink>
  );
}

ShowMoreButton.propTypes = {
  expanded: PropTypes.bool,
  setExpanded: PropTypes.func,
  text: PropTypes.string,
};
