import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

export function GradingPeriodDates({
  semester: {
    gradingPeriodStartMidpoint,
    gradingPeriodEndMidpoint,
    gradingPeriodStartMidpointGrad,
    gradingPeriodEndMidpointGrad,
    gradingPeriodStartFinal,
    gradingPeriodEndFinal,
    gradingPeriodStartFinalGrad,
    gradingPeriodEndFinalGrad,
  },
}) {
  return (
    <div>
      {gradingPeriodStartMidpoint && gradingPeriodEndMidpoint && (
        <div>
          <LightText>
            Midpoint Grades{" "}
            {gradingPeriodStartMidpointGrad && gradingPeriodEndMidpointGrad && (
              <>(Undergraduate)</>
            )}
          </LightText>{" "}
          Start: <strong>{gradingPeriodStartMidpoint}</strong> | Due:{" "}
          <strong>{gradingPeriodEndMidpoint}</strong>
        </div>
      )}

      {gradingPeriodStartMidpointGrad && gradingPeriodEndMidpointGrad && (
        <div>
          <LightText>Midpoint Grades (Graduate)</LightText> Start:{" "}
          <strong>{gradingPeriodStartMidpointGrad}</strong> | Due:{" "}
          <strong>{gradingPeriodEndMidpointGrad}</strong>
        </div>
      )}

      {gradingPeriodStartFinal && gradingPeriodEndFinal && (
        <div>
          <LightText>
            Final Grades{" "}
            {gradingPeriodStartFinalGrad && gradingPeriodEndFinalGrad && (
              <>(Undergraduate)</>
            )}
          </LightText>{" "}
          Start: <strong>{gradingPeriodStartFinal}</strong> | Due:{" "}
          <strong>{gradingPeriodEndFinal}</strong>
        </div>
      )}

      {gradingPeriodStartFinalGrad && gradingPeriodEndFinalGrad && (
        <div>
          <LightText>Final Grades (Graduate)</LightText> Start:{" "}
          <strong>{gradingPeriodStartFinalGrad}</strong> | Due:{" "}
          <strong>{gradingPeriodEndFinalGrad}</strong>
        </div>
      )}
    </div>
  );
}

GradingPeriodDates.propTypes = {
  semester: PropTypes.shape({
    gradingPeriodStartMidpoint: PropTypes.string,
    gradingPeriodEndMidpoint: PropTypes.string,
    gradingPeriodStartMidpointGrad: PropTypes.string,
    gradingPeriodEndMidpointGrad: PropTypes.string,
    gradingPeriodStartFinal: PropTypes.string,
    gradingPeriodEndFinal: PropTypes.string,
    gradingPeriodStartFinalGrad: PropTypes.string,
    gradingPeriodEndFinalGrad: PropTypes.string,
  }),
};

const LightText = styled.span`
  color: var(--dove-gray);
`;
