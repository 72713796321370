import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import APILink from "components/ui/APILink";
import Pipe from "components/ui/Pipe";
import WithAPILink from "components/ui/WithAPILink";
import WithAPILinkData from "components/ui/WithAPILinkData";
import HasAccessTo from "./HasAccessTo";
import ItemIfAccess from "./ItemIfAccess";

export default connect(mapStateTopProps)(TuitionAndFPP);

function mapStateTopProps({ myStatus: { isActingAs } }) {
  return {
    isActingAs,
  };
}

function TuitionAndFPP({ isActingAs }) {
  return (
    <ItemIfAccess urlID="UC_FA_FINRES_TUIT_AND_FPP">
      <WithAPILink urlID="UC_FA_FINRES_TUIT_AND_FPP" />
      <HasAccessTo urlIDs={["UC_FA_FINRES_ACTIVATE_FPP"]}>
        <Pipe />
        <strong>
          <WithAPILinkData urlID="UC_FA_FINRES_ACTIVATE_FPP">
            {(link) => <APILink link={{ ...link, disabled: isActingAs }} />}
          </WithAPILinkData>
        </strong>
      </HasAccessTo>
    </ItemIfAccess>
  );
}

TuitionAndFPP.displayName = "TuitionAndFPP";
TuitionAndFPP.propTypes = {
  isActingAs: PropTypes.bool,
};
