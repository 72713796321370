import React from "react";
import { Helmet } from "react-helmet";

import { useTopCategoryName } from "./useTopCategoryName";
import { useUser } from "components/hooks/useUser";

export function CampusCardTitle() {
  const categoryName = useTopCategoryName();
  const { loadState } = useUser((user) => user.campusLinks);

  if (loadState === "success") {
    return (
      <>
        <Helmet>
          <title>Campus - {categoryName} | CalCentral</title>
        </Helmet>

        <h1
          className="cc-heading-page-title"
          style={{
            fontWeight: `normal`,
            margin: 0,
          }}
        >
          {categoryName}
        </h1>
      </>
    );
  }

  return null;
}
