import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import DisclosureChevron from "components/../react/components/DisclosureChevron";
import HasAccessTo from "./HasAccessTo";

export default function ResourceContainer({
  expanded: initallyExpanded = false,
  title,
  children,
  testid = "resource-container",
  urlIDs = [],
}) {
  const [expanded, setExpanded] = useState(initallyExpanded);

  return (
    <HasAccessTo urlIDs={urlIDs}>
      <ContainerStyles data-testid={testid}>
        <h3 onClick={() => setExpanded(!expanded)}>
          <DisclosureChevron expanded={expanded} />
          {title}
        </h3>

        {expanded && <div>{children}</div>}
      </ContainerStyles>
    </HasAccessTo>
  );
}

ResourceContainer.displayName = "ResourceContainer";
ResourceContainer.propTypes = {
  expanded: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.node,
  testid: PropTypes.string,
  urlIDs: PropTypes.arrayOf(PropTypes.string),
};

const ContainerStyles = styled.div`
  border-bottom: 1px solid var(--gallery);
  padding-top: var(--medium-spacing);
  padding-bottom: var(--medium-spacing);
  cursor: pointer;

  h3 {
    display: flex;
    margin: 0;
    font-size: var(--base-font-size);
    gap: 5px;
  }

  &:hover {
    background-color: var(--foam);
  }

  a[disabled] {
    color: var(--dusty-gray);
  }

  > div {
    padding: 10px 10px 0 15px;
  }
`;
