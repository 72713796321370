import React, { useState } from "react";
import ButtonAsLink from "components/ui/ButtonAsLink";
import { formatGPACumulative } from "components/../react/helpers/gpa";
import { useAcademicRoles } from "components/hooks/useAcademicRoles";
import { useUser } from "components/hooks/useUser";

import { DefinitionList } from "components/ui/DefinitionList";

export function GPAToggle() {
  const currentlySummerVisitor = useAcademicRoles((r) => r.summerVisitor);
  const { gpa = [] } = useUser((user) => user.academicProfile);
  const hasNonLawRole = gpa?.find((item) => item.role !== "law");
  const [visible, setVisible] = useState(false);

  if (!currentlySummerVisitor && hasNonLawRole) {
    return (
      <>
        <dt data-testid="gpa-toggle">GPA</dt>
        <dd>
          {visible ? (
            <>
              {gpa.length === 1 ? (
                formatGPACumulative(gpa[0])
              ) : (
                <DefinitionList>
                  {gpa.map((theGPA) => (
                    <div key={theGPA.roleDescr}>
                      <dt>{theGPA.roleDescr}</dt>
                      <dd>{formatGPACumulative(theGPA)}</dd>
                    </div>
                  ))}
                </DefinitionList>
              )}
              <>
                {" "}
                (
                <ButtonAsLink onClick={() => setVisible(false)}>
                  Hide
                </ButtonAsLink>
                )
              </>
            </>
          ) : (
            <ButtonAsLink onClick={() => setVisible(true)}>
              Show GPA
            </ButtonAsLink>
          )}
        </dd>
      </>
    );
  }

  return null;
}

GPAToggle.displayName = "GPAToggle";
