import React from "react";
import { react2angular } from "react2angular";
import { ReduxProvider } from "components/data/ReduxProvider";
import { TransferCreditCard } from "components/cards/TransferCreditCard";

const NgTransferCreditCard = () => (
  <ReduxProvider>
    <TransferCreditCard />
  </ReduxProvider>
);

angular
  .module("calcentral.react")
  .component("transferCreditCard", react2angular(NgTransferCreditCard));
