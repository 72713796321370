import React from "react";
import { ReduxProvider } from "components/data/ReduxProvider";
import { react2angular } from "react2angular";
import { DelegateStudentsCard } from "components/cards/DelegateStudentsCard";

const NgDelegateStudentsCard = () => {
  return (
    <ReduxProvider>
      <DelegateStudentsCard />
    </ReduxProvider>
  );
};

angular
  .module("calcentral.react")
  .component("delegateStudentsCard", react2angular(NgDelegateStudentsCard));
