import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import schedulePlanner from "icons/schedule-planner.svg";

export default function SchedulePlannerLink({
  careerCode,
  termId,
  useRightArrow,
}) {
  const isViewingAs = useSelector((state) => {
    const { myStatus: { actingAsUid: isViewingAs } = {} } = state;
    return isViewingAs;
  });

  const onClick = (event) => {
    if (isViewingAs) {
      event.preventDefault();
      alert(`While viewing someone's information, you can't visit this link.`);
    }
  };

  return (
    <a
      data-testid="schedule-planner-link"
      href={`/college_scheduler/student/${careerCode}/${termId}`}
      target="_blank"
      rel="noopener noreferrer"
      onClick={onClick}
    >
      {useRightArrow ? null : <img src={schedulePlanner} alt="" />} Schedule
      Planner {useRightArrow ? <i className="fa fa-arrow-right"></i> : null}
    </a>
  );
}

SchedulePlannerLink.displayName = "SchedulePlannerLink";
SchedulePlannerLink.propTypes = {
  careerCode: PropTypes.string,
  termId: PropTypes.string,
  useRightArrow: PropTypes.bool,
};
