import { createGlobalStyle } from "styled-components";

// Hex to color naming obtained from http://chir.ag/projects/name-that-color

// ATTENTION: Make sure to update src/docs/components/ColorsDocs.js when updating color variables

export default createGlobalStyle`
  :root {
    --max-width: 1200px;

    --helvetica: "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
    --arial: Arial, sans-serif;

    // Spacing
    --tiny-spacing: 2px;
    --small-spacing: 5px;
    --sm: var(--small-spacing);
    --medium-spacing: 10px;
    --md: var(--medium-spacing);
    --large-spacing: 15px;
    --lg-spacing: var(--large-spacing);
    --lg: var(--large-spacing);
    --xl-spacing: 30px;
    --xl: var(--xl-spacing);

    // Radii
    --tiny-border-radius: 3px;
    --small-border-radius: 5px;

    // Colors: Grayscale
    --black: #000;
    --mine-shaft: #292929;
    --tundora: #474747;
    --dove-gray: #666;
    --ironside-gray: #71706B;
    --dusty-gray: #949494;
    --silver: #ccc;
    --gallery: #eee;
    --wild-sand: #f6f6f6;
    --white: #fff;

    // Colors: Blues
    --aquamarine-blue: #80c7de;
    --bismark: #4a6a8a;
    --calypso: #2b6281;
    --denim: #0f71ae;
    --foam: #f2f9fe;
    --hoki: #61889e;
    --mako: #474f56;
    --matisse: #1f7099;
    --midnight-blue: #003262;
    --mystic: #e6ebef;
    --orient: #025a86;
    --pale-sky: #6e7a83;
    --prussian-blue: #002e5b;
    --regent-st-blue: #a0d3e8;
    --san-juan: #2a4f6a;
    --skeptic: #c8ead6;
    --steel-blue: #4d88c4;
    --wedgewood: #427694;
    --link-water: #c9e1f2;
    --venice-blue: #055989;

    // Colors: Blue/Grays
    --casper: #afc5d2;
    --gumbo: #8098a5;

    // Colors: Reds
    --cinderella: #fce3e5;
    --guardsman-red: #c10006;
    --thunderbird: #cf1715;
    --chablis: #fff5f5;

    // Oranges
    --buttercup: #f1a91e;
    --karry: #ffe9d9;

    // Colors: Yellows
    --bianca: #fcfcf3;
    --colonial-white: #fff2bd;
    --half-dutch-white: #fef7db;
    --lemon-ginger: #a79526;
    --yellow: #ff0;
    --solitaire: #fef8e0;

    // Colors: Greens
    --jewel: #137e3e;
    --skeptic: #c8ead6;
    --spring-rain: #b3c8b4;
    --clear-day: #ebfff5;
    --granny-apple: #d5f5e2;

    // Transparent/Translucent Colors
    --transparent-black: rgba(0, 0, 0, 0.2);
    --transparent-ironside-gray: rgba(113, 112, 107, 0.1);
    --translucent-white: rgba(255, 255, 255, 0.5);
    --transparent-white: rgba(255, 255, 255, 0);

    // Brand Colors
    --facebook-blue: #3c599b;
    --twitter-blue: #69bcf0;

    // Font sizes with line-heights
    --tiny-font-size: 11px;
    --tiny-line-height: 16px;
    --small-font-size: 13px;
    --medium-font-size: 15px;
    --medium-line-height: 17px;
    --large-font-size: 18px;

    // Text
    --base-color: var(--tundora);
    --base-font-family: var(--arial);
    --base-line-height: 1.5;
    --base-font-size: var(--small-font-size);

    // Headings
    --heading-color: var(--mine-shaft);
    --heading-font-family: var(--helvetica);
    --heading-line-height: 1.4;

    // Purposed variables
    --link-color: var(--denim);
    --text-color: var(--tundora);
    --disabled-text-color: var(--silver);
    --page-background-color: var(--wild-sand);
    --card-background-color: var(--white);
    --base-border-color: var(--silver);

    // Buttons
    --button-font-size: 11px;
    --button-font-weight: bold;
    --button-line-height: 1;
    --button-border-width: 2px;
    --button-padding: 6px 10px;
    --button-border-radius: var(--small-border-radius);

    --button-border-color: var(--silver);
    --button-focus-border-color: var(--button-border-color);
    --button-disabled-border-color: var(--button-border-color);

    --button-text-color: var(--mine-shaft);
    --button-focus-text-color: var(--button-text-color);
    --button-disabled-text-color: var(--disabled-text-color);

    --button-background: linear-gradient(var(--white), var(--gallery)), var(--white);
    --button-focus-background: linear-gradient(var(--white), var(--silver)), var(--silver);
    --button-disabled-background: var(--wild-sand);

    // Generic purpose light border
    --border-hairline: 1px solid var(--gallery);
  }
`;
