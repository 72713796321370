import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import currentUserState from "utils/currentUserState";

export default function HoldsWarning() {
  const hasHolds = useSelector((state) => {
    const { enrollmentResources: { hasHolds } = {} } = currentUserState(state);
    return hasHolds;
  });

  if (hasHolds) {
    return (
      <HoldsStyles data-testid="holds-warning">
        <img
          src="/assets/images/exclamation-circle.svg"
          height="12"
          width="12"
          alt=""
        />
        <div>
          You have a hold that may affect your ability to enroll in classes.
          View your &quot;Active Holds&quot; for more details.
        </div>
      </HoldsStyles>
    );
  }

  return null;
}
HoldsWarning.displayName = "HoldsWarning";

const HoldsStyles = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 15px;

  img {
    margin-right: 6px;
    margin-top: 3px;
  }
`;
HoldsStyles.displayName = "HoldsStyles";
