import React from "react";
import PropTypes from "prop-types";
import VisuallyHidden from "components/ui/VisuallyHidden";
import { TriangleIcon } from "./TriangleIcon";

export function IncompleteGradingStatus({ hasIncompleteGrades }) {
  if (hasIncompleteGrades) {
    return (
      <>
        <TriangleIcon />
        <VisuallyHidden>Incomplete grading present</VisuallyHidden>
      </>
    );
  }

  return <VisuallyHidden>Incomplete grades not present</VisuallyHidden>;
}

IncompleteGradingStatus.displayName = "IncompleteGradingStatus";
IncompleteGradingStatus.propTypes = {
  hasIncompleteGrades: PropTypes.bool,
};
