import { useMatchingLinks } from "./useMatchingLinks";
import { useTopCategoryName } from "./useTopCategoryName";

export function useGroupedLinks() {
  const topCategoryName = useTopCategoryName();
  const matchingLinks = useMatchingLinks();

  return matchingLinks.reduce((accumulator, current) => {
    const subcategoryName = current.categories.find(
      (category) => category.topcategory === topCategoryName
    ).subcategory;

    return {
      ...accumulator,
      [subcategoryName]: [...(accumulator[subcategoryName] || []), current],
    };
  }, {});
}
