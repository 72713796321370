import React from "react";
import PropTypes from "prop-types";

import { CardBody } from "components/ui/Card";
import { CCTable, TableHead } from "components/ui/Table";
import { HairlineRule } from "components/ui/HairlineRule";

import SubTitle from "./SubTitle";
import { GradingStatusLegend } from "./GradingStatusLegend";
import { GradingAssistanceLinks } from "./GradingAssistanceLinks";
import { GradingPeriodDates } from "./GradingPeriodDates";
import { SectionHeader, SectionRow } from "./SectionRow";

import {
  semesterHasMidpointClass,
  isSummer,
  semesterCoursesHaveTopics,
} from "utils/teachingSemester";

export function SemesterGrading({ semester }) {
  const {
    gradingAssistanceLinkMidpoint,
    gradingAssistanceLink,
    gradingAssistanceLinkLaw,
  } = semester;

  const showCard =
    gradingAssistanceLinkMidpoint ||
    gradingAssistanceLink ||
    gradingAssistanceLinkLaw;

  const hasMidpointClass = semesterHasMidpointClass(semester);
  const isSummerSemester = isSummer(semester);
  const teachingCoursesHaveTopics = semesterCoursesHaveTopics(semester);

  if (showCard) {
    return (
      <>
        <SubTitle><h3>Grading</h3></SubTitle>
        <CardBody style={{ display: `flex`, justifyContent: `space-between` }}>
          <GradingAssistanceLinks semester={semester} />
          <GradingPeriodDates semester={semester} />
        </CardBody>
        <HairlineRule style={{ margin: 0 }} />
        <CardBody>
          <CCTable>
            <TableHead>
              <SectionHeader
                hasMidpointClass={hasMidpointClass}
                teachingCoursesHaveTopics={teachingCoursesHaveTopics}
                isSummerSemester={isSummerSemester}
              />
            </TableHead>
            {semester.classes.map((course, index) => (
              <GradingTableBody
                key={index}
                course={course}
                hasMidpointClass={hasMidpointClass}
                teachingCoursesHaveTopics={teachingCoursesHaveTopics}
                isSummerSemester={isSummerSemester}
              />
            ))}
          </CCTable>
        </CardBody>
        <HairlineRule style={{ margin: 0 }} />
        <GradingStatusLegend semester={semester} />
      </>
    );
  }

  return null;
}

SemesterGrading.displayName = "SemesterGrading";
SemesterGrading.propTypes = {
  semester: PropTypes.object,
};

function GradingTableBody({ course, hasMidpointClass, isSummerSemester }) {
  const { sections, listings } = course;
  return (
    <tbody>
      {sections.map((section, index) => (
        <SectionRow
          key={index}
          course={course}
          section={section}
          listings={listings}
          hasMidpointClass={hasMidpointClass}
          isSummerSemester={isSummerSemester}
        />
      ))}
    </tbody>
  );
}

GradingTableBody.displayName = "GradingTableBody";
GradingTableBody.propTypes = {
  course: PropTypes.object,
  hasMidpointClass: PropTypes.bool,
  isSummerSemester: PropTypes.bool,
};
