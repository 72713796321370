import React from "react";
import PropTypes from "prop-types";
import { ExternalLink } from "components/ui/ExternalLink";
import { useAdvisingStudent } from "components/hooks/useAdvisingStudent";
import { useSlice } from "components/hooks/useSlice";

export const SchedulePlannerLink = () => {
  const { userId: advisingStudentUid } = useSlice("advising");
  const { plans: studentPlans = [], latestRegistrationTerm = {} } =
    useAdvisingStudent(
      (student) => student.advisingStudentAcademicProfile || {}
    );

  const { id: currentRegistrationTermId = null } = latestRegistrationTerm || {};

  const schedulePlannerUrl = buildSchedulePlannerUrl(
    studentPlans,
    currentRegistrationTermId,
    advisingStudentUid
  );

  const title = "View this student's planned schedule for this term.";

  if (schedulePlannerUrl) {
    return (
      <ExternalLink
        href={schedulePlannerUrl}
        title={title}
        target="_blank"
        rel="noopener noreferrer"
      >
        Schedule Planner
      </ExternalLink>
    );
  }

  return null;
};
SchedulePlannerLink.displayName = "SchedulePlannerLink";
SchedulePlannerLink.propTypes = {
  studentUid: PropTypes.string,
};

/**
 * Returns unique set of career codes extracted from collection of student plans
 * @param  {Array} studentPlans  iHub Academic Status student plans
 * @return {Array}               career codes
 */
const getUniqueCareerCodes = function (studentPlans) {
  var careerCodes = studentPlans.map(function (plan) {
    const { careerCode = null } = plan;
    return careerCode;
  });
  const compactedCareerCodes = careerCodes.filter(Boolean);
  const uniqCareerCodes = [...new Set(compactedCareerCodes)];
  return uniqCareerCodes;
};

function buildSchedulePlannerUrl(
  studentPlans,
  currentRegistrationTermId,
  advisingStudentUid
) {
  if (studentPlans && currentRegistrationTermId && advisingStudentUid) {
    const careerCode = getUniqueCareerCodes(studentPlans)[0];

    if (careerCode && currentRegistrationTermId && advisingStudentUid) {
      return [
        "/college_scheduler/advisor",
        careerCode,
        currentRegistrationTermId,
        advisingStudentUid,
      ].join("/");
    }
  }

  return null;
}
