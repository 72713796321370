import { useAcademicRoles } from "components/hooks/useAcademicRoles";
import { useRoles } from "components/hooks/useRoles";

export function useCanViewLink() {
  const roles = useRoles();
  const currentAcademicRoles = useAcademicRoles((roles) => roles);

  return (link) => canViewLink(link, roles, currentAcademicRoles);
}

export function canViewLink(link, roles, academicRoles) {
  return allowListed(link, roles) && !blockListed(link, academicRoles);
}

export function allowListed(link, roles) {
  if (roles) {
    return Object.keys(link.roles).some(
      (role) => roles[role] && roles[role] === link.roles[role]
    );
  }
}

export function blockListed(link, academicRoles) {
  return Object.keys(academicRoles).some(
    (role) => academicRoles[role] && link.roles[role] === false
  );
}
