import React from "react";
import { react2angular } from "react2angular";

import { ReduxProvider } from "components/data/ReduxProvider";
import { AcademicDatesCard } from "components/cards/AcademicDatesCard";

const NgAcademicDatesAndDeadlinesCard = () => (
  <ReduxProvider>
    <AcademicDatesCard />
  </ReduxProvider>
);

angular
  .module("calcentral.react")
  .component(
    "academicDatesAndDeadlinesCard",
    react2angular(NgAcademicDatesAndDeadlinesCard)
  );
