import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import CurrentSemesterContext from "../contexts/SemestersContext";

export default function WithSemesters({
  children,
  semesterId: semesterIdParam,
}) {
  // If semesterId/teachingSemester is not set by params, default to the
  // semesterId prop (allows for more straightforward testing outside of the
  // full app context)
  const { semesterId = semesterIdParam } = useParams();

  const { semesters, loadState, teachingSemesters } = useSelector((state) => {
    const {
      myAcademics: { semesters = [], teachingSemesters = [], loadState } = {},
    } = state;

    return { loadState, semesters, teachingSemesters };
  });

  const currentTeachingSemester = semesterId
    ? teachingSemesters.find(matchCurrentSemesterId(semesterId))
    : teachingSemesters.find(matchCurrentTimeBucket());

  const currentStudentSemester = semesterId
    ? semesters.find(matchCurrentSemesterId(semesterId))
    : semesters.find(matchCurrentTimeBucket());

  const currentSemester = currentStudentSemester || currentTeachingSemester;

  return (
    <CurrentSemesterContext.Provider
      value={{
        currentSemester,
        currentStudentSemester,
        currentTeachingSemester,
        semesters,
        teachingSemesters,
        loadState,
      }}
    >
      {children}
    </CurrentSemesterContext.Provider>
  );
}

WithSemesters.propTypes = {
  children: PropTypes.node,
  semesterId: PropTypes.string,
};

const matchCurrentSemesterId = (semesterId) => (semester) =>
  semester.slug === semesterId;

const matchCurrentTimeBucket = () => (semester) => {
  return semester.timeBucket === "current";
};
