import React from "react";
import { useAdvisingStudent } from "components/hooks/useAdvisingStudent";

export const Majors = () => {
  const { majors = [] } = useAdvisingStudent(
    (student) => student.advisingStudentAcademicProfile || {}
  );

  if (majors.length > 0) {
    return (
      <>
        <dt>{majors.length === 1 ? "Major" : "Majors"}</dt>
        <dd>
          {majors.map((major, index) => (
            <div key={index}>
              <div style={{ color: "var(--dusty-gray)" }}>{major.college}</div>
              <div>{major.description}</div>
              {major.subPlans.map((subPlan, index) => (
                <div key={index}>{subPlan}</div>
              ))}
            </div>
          ))}
        </dd>
      </>
    );
  }
  return null;
};
Majors.displayName = "Majors";
