import React from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import * as Yup from "yup";
import styled from "styled-components";

const uidSchema = Yup.string()
  .matches(/^[0-9]{1,10}$/)
  .required("Required");

const validationSchema = Yup.object({
  uid: uidSchema,
});

const FieldError = styled.span`
  color: var(--thunderbird);
`;

const Submit = styled.input`
  background: var(--denim);
  border: 1px solid transparent;
  color: var(--white);

  border-radius: 5px;
  font-size: 11px;
  font-weight: bold;
  line-height: 1;
  padding: 6px 10px;

  transition: background-color 300ms ease-out;

  &[disabled] {
    background: var(--wild-sand);
    border: 1px solid var(--silver);
    color: var(--silver);
    cursor: auto;
    opacity: 1;
  }
`;

const Label = styled.label`
  font-size: 12px;
  display: block;
  text-align: left;
`;

const TextInput = styled.input`
  border: 1px solid var(--silver);
  box-shadow: inset 0 1px 2px var(--gallery);
  width: 100%;
  margin: 0 !important;

  font-size: 12px;
  padding: 3px;
`;

const LookupFormStyles = styled.form`
  // overrides to remove after React transition
  background: none;
  border: none;
  padding: 0;
`;

const InputGroup = styled.div`
  padding-bottom: 8px;
`;

const DisplayedForm = ({
  handleBlur,
  handleChange,
  handleSubmit,
  errors,
  values,
  buttonLabel,
  includeCampusDir = true,
}) => {
  const locallyValid = uidSchema.isValidSync(values.uid);

  return (
    <LookupFormStyles onSubmit={handleSubmit} disabled={!locallyValid}>
      <InputGroup>
        {errors.uid && errors.uid.match(/valid/) && (
          <FieldError>
            <i className="cc-icon-red fa fa-exclamation-circle"></i>&nbsp;
            {errors.uid}
          </FieldError>
        )}

        <Label>UID/SID</Label>
        <TextInput
          placeholder="Enter UID or SID here"
          type="text"
          name="uid"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.uid}
          onClick={(e) => e.target.select()}
        />
      </InputGroup>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Submit
          type="submit"
          value={buttonLabel || "Submit"}
          disabled={!locallyValid}
        />
        {includeCampusDir && (
          <a
            href="http://www.berkeley.edu/directory"
            target="_blank"
            rel="noopener noreferrer"
          >
            Campus Directory
          </a>
        )}
      </div>
    </LookupFormStyles>
  );
};
DisplayedForm.displayName = "DisplayedForm";
DisplayedForm.propTypes = {
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  values: PropTypes.object,
  errors: PropTypes.object,
  buttonLabel: PropTypes.string,
  includeCampusDir: PropTypes.bool,
};

export default function UIDLookupForm({
  recentUsers = [],
  viewAsUser,
  setUsers,
  buttonLabel,
  includeCampusDir,
}) {
  const { ldapUid = "" } = recentUsers[0] || {};

  return (
    <Formik
      initialValues={{ uid: ldapUid }}
      validationSchema={validationSchema}
      onSubmit={(values, actions) => {
        if (setUsers) {
          setUsers([]);
        }

        fetch(`/api/search_users/${values.uid}`)
          .then((res) => {
            if (res.ok) {
              return res.json();
            } else {
              actions.setSubmitting(false);
              actions.setFieldError("uid", "Unable to retrieve results");
            }
          })
          .then(({ users = [] }) => {
            if (users.length) {
              if (setUsers) {
                setUsers(users);
              } else {
                viewAsUser(users[0]);
              }
            } else {
              actions.setSubmitting(false);
              actions.setFieldError(
                "uid",
                "That does not appear to be a valid UID or SID."
              );
            }
          });
      }}
    >
      {(props) => (
        <DisplayedForm
          {...props}
          buttonLabel={buttonLabel}
          includeCampusDir={includeCampusDir}
        />
      )}
    </Formik>
  );
}
UIDLookupForm.displayName = "UIDLookupForm";
UIDLookupForm.propTypes = {
  recentUsers: PropTypes.array,
  viewAsUser: PropTypes.func,
  setUsers: PropTypes.func,
  buttonLabel: PropTypes.string,
  includeCampusDir: PropTypes.bool,
};
