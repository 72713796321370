import React from "react";
import { useAdvisingStudent } from "components/hooks/useAdvisingStudent";

export const Level = () => {
  const { latestLevels = [] } = useAdvisingStudent(
    (student) => student.advisingStudentAcademicProfile || {}
  );

  const {
    academicRoles: { historical: { degreeSeeking, summerVisitor } = {} } = {},
  } = useAdvisingStudent((student) => student.studentData || {});

  const isNonDegreeSeekingSummerVisitor = summerVisitor && !degreeSeeking;

  if (!isNonDegreeSeekingSummerVisitor && latestLevels.length > 0) {
    return (
      <>
        <dt>Level</dt>
        <dd>
          {latestLevels.map((level, index) => (
            <div key={index}>
              <span>{level.description}</span>
            </div>
          ))}
        </dd>
      </>
    );
  }
  return null;
};
Level.displayName = "Level";
