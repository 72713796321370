import React from "react";
import { useAdvisingStudent } from "components/hooks/useAdvisingStudent";

export const DesignatedEmphases = () => {
  const { designatedEmphases = [] } = useAdvisingStudent(
    (student) => student.advisingStudentAcademicProfile || {}
  );

  if (designatedEmphases.length) {
    return (
      <>
        <dt>
          {designatedEmphases.length === 1
            ? "Designated Emphasis"
            : "Designated Emphases"}
        </dt>
        <dd>
          {designatedEmphases.map((designatedEmphasis, index) => (
            <div key={index}>
              <div style={{ color: "var(--dusty-gray)" }}>
                {designatedEmphasis.college}
              </div>
              <div>{designatedEmphasis.description}</div>
              {designatedEmphasis.subPlans.map((subPlan, index) => (
                <div key={index}>{subPlan}</div>
              ))}
            </div>
          ))}
        </dd>
      </>
    );
  }
  return null;
};
DesignatedEmphases.displayName = "DesignatedEmphases";
