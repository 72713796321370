import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTestMode } from "./useTestMode";
import { useAdvisingStudentUID } from "./useAdvisingStudentUID";

export function dispatchAdvisingStudentSlice(fetchFunction) {
  const isTestMode = useTestMode();
  const dispatch = useDispatch();
  const advisingStudentUID = useAdvisingStudentUID();

  useEffect(() => {
    if (!isTestMode) {
      if (advisingStudentUID) {
        if (typeof fetchFunction === "function") {
          dispatch(fetchFunction(advisingStudentUID));
        } else if (typeof fetchFunction === "object") {
          fetchFunction.map((func) => dispatch(func(advisingStudentUID)));
        }
      }
    }
  }, [isTestMode, dispatch, advisingStudentUID]);
}
