import React from "react";
import PropTypes from "prop-types";
import { useCurrentBreakpointName } from "react-socks";
import { orderedBreakpointNames } from "config/media";

export function BreakpointFragment({ children, up, down, only, ...rest }) {
  const currentBreakpoint = useCurrentBreakpointName();

  if (shouldRender({ currentBreakpoint, up, down, only, ...rest })) {
    return <React.Fragment>{children}</React.Fragment>;
  }

  return null;
}

BreakpointFragment.propTypes = {
  up: PropTypes.bool,
  down: PropTypes.bool,
  only: PropTypes.bool,
  children: PropTypes.node,
};

function shouldRender({ currentBreakpoint, up, down, only, ...rest }) {
  const matchedBreakpoint = orderedBreakpointNames.find((bp) =>
    Object.hasOwn(rest, bp)
  );

  const matchedIndex = orderedBreakpointNames.findIndex((bp) =>
    Object.hasOwn(rest, bp)
  );

  if (matchedBreakpoint) {
    if (
      up &&
      orderedBreakpointNames.slice(matchedIndex).includes(currentBreakpoint)
    ) {
      return true;
    }

    if (
      down &&
      orderedBreakpointNames
        .slice(0, matchedIndex + 1)
        .includes(currentBreakpoint)
    ) {
      return true;
    }

    if (only && currentBreakpoint === matchedBreakpoint) {
      return true;
    }
  }

  return false;
}
