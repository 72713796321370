import { buildFinaidSlice } from "data/utils/buildSlice";
import { finaidDataThunk } from "data/utils/thunks";
import { routes } from "data/routes";
import { finaidRestRequest } from "data/utils/requests";

const key = "finaidCostOfAttendance";

export const slice = buildFinaidSlice(key);
export const { reducer: finaidCostOfAttendance } = slice;
export const fetchFinaidCostOfAttendance = finaidDataThunk(key, {
  request: finaidRestRequest({ url: routes[key] }),
  dataHandler:
    ({ actions, dispatch, uid, finaidYearId }) =>
    (data) => {
      dispatch(actions.success({ uid, finaidYearId, data: data }));
    },

  slice,
});
