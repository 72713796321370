import React from "react";
import { useAdvisingStudent } from "components/hooks/useAdvisingStudent";

export const Career = () => {
  const { latestCareers = [] } = useAdvisingStudent(
    (student) => student.advisingStudentAcademicProfile
  );

  if (latestCareers && latestCareers.length > 0) {
    return (
      <>
        <dt>
          {latestCareers.length === 1 ? "Academic Career" : "Academic Careers"}
        </dt>
        <dd>
          {latestCareers.map((career) => (
            <div key={career}>{career}</div>
          ))}
        </dd>
      </>
    );
  }
  return null;
};
Career.displayName = "Career";
