import React from "react";
import PropTypes from "prop-types";
import ButtonAsLink from "components/ui/ButtonAsLink";
import DisclosureChevron from "components/../react/components/DisclosureChevron";

export default function EnrollmentExpander({ title, expanded, onClick }) {
  return (
    <div style={{ display: `flex`, justifyContent: `center` }}>
      <ButtonAsLink onClick={onClick}>
        {title} <DisclosureChevron expanded={expanded} />
      </ButtonAsLink>
    </div>
  );
}

EnrollmentExpander.displayName = "EnrollmentExpander";
EnrollmentExpander.propTypes = {
  title: PropTypes.string.isRequired,
  expanded: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};
