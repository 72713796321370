import React from "react";
import PropTypes from "prop-types";
import SectionRows from "./SectionRows.js";

const SemesterClass = ({
  canViewGrades,
  isAdvisingView,
  isInactiveTerm,
  semesterClass,
}) => {
  return (
    <>
      {semesterClass.sections.map((section) => (
        <SectionRows
          key={section.ccn}
          isAdvisingView={isAdvisingView}
          isInactiveTerm={isInactiveTerm}
          canViewGrades={canViewGrades}
          section={section}
          semesterClass={semesterClass}
        />
      ))}
    </>
  );
};

SemesterClass.displayName = "SemesterClass";
SemesterClass.propTypes = {
  canViewGrades: PropTypes.bool,
  isAdvisingView: PropTypes.bool,
  isInactiveTerm: PropTypes.bool,
  semesterClass: PropTypes.object,
};

export default SemesterClass;
