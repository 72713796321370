import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

export const SVG = styled.svg.attrs({
  version: "1.1",
  xmlns: "http://www.w3.org/2000/svg",
  xmlnsXlink: "http://www.w3.org/1999/xlink",
})``;

// Font Awesome Pro 6.1.2 by @fontawesome
// https://fontawesome.com/icons/award
// License - https://fontawesome.com/license (Commercial License)
// Copyright 2022 Fonticons, Inc.
export const AwardIcon = ({ className }) => (
  <SVG viewBox="0 0 384 512" className={className}>
    <path
      fill="currentColor"
      d="M288 358.3c13.98-8.088 17.53-30.04 28.88-41.39c11.35-11.35 33.3-14.88 41.39-28.87c7.98-13.79 .1658-34.54 4.373-50.29C366.7 222.5 383.1 208.5 383.1 192c0-16.5-17.27-30.52-21.34-45.73c-4.207-15.75 3.612-36.5-4.365-50.29c-8.086-13.98-30.03-17.52-41.38-28.87c-11.35-11.35-14.89-33.3-28.87-41.39c-13.79-7.979-34.54-.1637-50.29-4.375C222.5 17.27 208.5 0 192 0C175.5 0 161.5 17.27 146.3 21.34C130.5 25.54 109.8 17.73 95.98 25.7C82 33.79 78.46 55.74 67.11 67.08C55.77 78.43 33.81 81.97 25.72 95.95C17.74 109.7 25.56 130.5 21.35 146.2C17.27 161.5 .0008 175.5 .0008 192c0 16.5 17.27 30.52 21.34 45.73c4.207 15.75-3.615 36.5 4.361 50.29C33.8 302 55.74 305.5 67.08 316.9c11.35 11.35 14.89 33.3 28.88 41.4c13.79 7.979 34.53 .1582 50.28 4.369C161.5 366.7 175.5 384 192 384c16.5 0 30.52-17.27 45.74-21.34C253.5 358.5 274.2 366.3 288 358.3zM112 192c0-44.27 35.81-80 80-80s80 35.73 80 80c0 44.17-35.81 80-80 80S112 236.2 112 192zM1.719 433.2c-3.25 8.188-1.781 17.48 3.875 24.25c5.656 6.75 14.53 9.898 23.12 8.148l45.19-9.035l21.43 42.27C99.46 507 107.6 512 116.7 512c.3438 0 .6641-.0117 1.008-.0273c9.5-.375 17.65-6.082 21.24-14.88l33.58-82.08c-53.71-4.639-102-28.12-138.2-63.95L1.719 433.2zM349.6 351.1c-36.15 35.83-84.45 59.31-138.2 63.95l33.58 82.08c3.594 8.797 11.74 14.5 21.24 14.88C266.6 511.1 266.1 512 267.3 512c9.094 0 17.23-4.973 21.35-13.14l21.43-42.28l45.19 9.035c8.594 1.75 17.47-1.398 23.12-8.148c5.656-6.766 7.125-16.06 3.875-24.25L349.6 351.1z"
    />
  </SVG>
);

AwardIcon.displayName = "AwardIcon";
AwardIcon.propTypes = {
  className: PropTypes.string,
};

export const BCalIcon = ({ className }) => (
  <SVG
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 256 256"
  >
    <path
      fill="#438DFB"
      d="M256 178.49v-149.038c0-8.805-7.14-15.944-15.944-15.944h-224.111c-8.807 0-15.945 7.139-15.945 15.944v197.095c0 8.805 7.138 15.945 15.945 15.945h175.389l64.003-64.002h.663z"
    />
    <circle fill="#fff" cx="201.964" cy="51.597" r="10.63" />
    <circle fill="#fff" cx="53.147" cy="51.597" r="10.63" />
    <g fill="#fff">
      <path d="M59.801 188.541c-5.9-7.187-8.849-15.932-8.849-26.236h14.529c.618 7.161 1.957 12.364 4.019 15.61 3.605 5.822 10.124 8.732 19.552 8.732 7.315 0 13.188-1.957 17.62-5.873 4.43-3.915 6.646-8.965 6.646-15.146 0-7.624-2.332-12.957-6.994-15.997-4.664-3.04-11.142-4.56-19.436-4.56-.927 0-1.868.013-2.821.038-.954.026-1.92.065-2.898.116v-12.288c1.442.154 2.653.259 3.632.31.978.052 2.035.077 3.168.077 5.203 0 9.479-.823 12.828-2.473 5.874-2.885 8.81-8.037 8.81-15.456 0-5.512-1.958-9.763-5.873-12.751-3.916-2.987-8.476-4.482-13.679-4.482-9.273 0-15.688 3.091-19.243 9.273-1.958 3.4-3.066 8.244-3.323 14.529h-13.754c0-8.243 1.648-15.25 4.946-21.02 5.667-10.304 15.636-15.456 29.907-15.456 11.283 0 20.016 2.512 26.198 7.535 6.183 5.023 9.273 12.301 9.273 21.832 0 6.801-1.829 12.314-5.487 16.538-2.268 2.627-5.204 4.688-8.81 6.182 5.821 1.598 10.367 4.676 13.64 9.235 3.271 4.56 4.907 10.138 4.907 16.731 0 10.562-3.478 19.165-10.433 25.812-6.955 6.646-16.822 9.969-29.598 9.969-13.086-.001-22.578-3.594-28.477-10.781zM150.334 117.946v-10.665c10.047-.978 17.053-2.614 21.021-4.907 3.967-2.292 6.929-7.715 8.887-16.268h10.974v110.201h-14.837v-78.362h-26.045z" />
    </g>
    <path fill="#BED5FC" d="M191.334 242.492l64.666-64.002h-64.666z" />
    <path fillOpacity=".3" d="M191.334 178.49l-64.666 64.002h64.666z" />
    <path fill="#fff" d="M191.334 178.49v62.781" />
  </SVG>
);
BCalIcon.displayName = "BCalIcon";
BCalIcon.propTypes = {
  className: PropTypes.string,
};

export const BDriveIcon = ({ className }) => (
  <SVG
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 256 256"
  >
    <path
      fill="#3777E3"
      d="M42.669 238.875l42.664-73.916h170.667l-42.667 73.916z"
    />
    <path fill="#FFCF63" d="M170.669 164.959h85.331l-85.331-147.834h-85.336z" />
    <path
      fill="#11A861"
      d="M0 164.959l42.669 73.916 85.331-147.833-42.667-73.917z"
    />
  </SVG>
);
BDriveIcon.displayName = "BDriveIcon";
BDriveIcon.propTypes = {
  className: PropTypes.string,
};

export const BMailIcon = ({ className }) => (
  <SVG
    className={className}
    viewBox="0 0 256 256"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="#E75A4D"
      d="M234.994 35.393c11.534 0 20.972 9.438 20.972 20.972v6.08l-29.138 20.813-99.195 68.331-99.195-69.025v138.04399999999998h-7.466c-11.534-.001-20.972-9.438-20.972-20.973v-143.264c0-11.535 9.438-20.972 20.972-20.972l106.661 79.081 107.355-79.081.006-.006z"
    />
    <path
      fill="#E7E4D7"
      d="M28.438 82.564v138.043h198.396v-137.35l-99.201 68.331z"
    />
    <path
      fill="#B8B7AE"
      d="M28.438 220.607l99.195-69.019-.448-.317-98.747 67.819z"
    />
    <path fill="#B7B6AD" d="M226.834 83.257l.058 137.35-99.259-69.019z" />
    <path
      fill="#B2392F"
      d="M255.966 62.444l.034 137.208c-.5 15.25-7.108 20.693-29.108 20.949l-.058-137.35 29.138-20.813-.006.006z"
    />
    <path fill="#F7F5ED" d="M20.972 35.393l106.661 79.081 107.361-79.081z" />
  </SVG>
);
BMailIcon.displayName = "BMailIcon";
BMailIcon.propTypes = {
  className: PropTypes.string,
};

// Font Awesome Pro 6.1.2 by @fontawesome
// https://fontawesome.com/icons/external-link
// License - https://fontawesome.com/license (Commercial License)
// Copyright 2022 Fonticons, Inc.
export const NewWindowIcon = () => (
  <svg
    aria-hidden="true"
    focusable="false"
    data-prefix="fas"
    data-icon="external-link"
    className="svg-inline--fa fa-external-link"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    width="14"
    style={{ paddingLeft: "3px" }}
  >
    <path
      fill="currentColor"
      d="M320 0c-17.7 0-32 14.3-32 32s14.3 32 32 32h82.7L201.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L448 109.3V192c0 17.7 14.3 32 32 32s32-14.3 32-32V32c0-17.7-14.3-32-32-32H320zM80 32C35.8 32 0 67.8 0 112V432c0 44.2 35.8 80 80 80H400c44.2 0 80-35.8 80-80V320c0-17.7-14.3-32-32-32s-32 14.3-32 32V432c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16H192c17.7 0 32-14.3 32-32s-14.3-32-32-32H80z"
    ></path>
  </svg>
);

NewWindowIcon.displayName = "NewWindowIcon";
NewWindowIcon.propTypes = {
  className: PropTypes.string,
};

// Font Awesome Pro 6.1.2 by @fontawesome
// https://fontawesome.com/icons/envelope
// License - https://fontawesome.com/license (Commercial License)
// Copyright 2022 Fonticons, Inc.
export const EnvelopeIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="10"
    width="10"
    viewBox="0 0 512 512"
  >
    <path
      fill="#878787"
      d="M64 112c-8.8 0-16 7.2-16 16v22.1L220.5 291.7c20.7 17 50.4 17 71.1 0L464 150.1V128c0-8.8-7.2-16-16-16H64zM48 212.2V384c0 8.8 7.2 16 16 16H448c8.8 0 16-7.2 16-16V212.2L322 328.8c-38.4 31.5-93.7 31.5-132 0L48 212.2zM0 128C0 92.7 28.7 64 64 64H448c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128z"
    />
  </svg>
);

EnvelopeIcon.displayName = "EnvelopeIcon";
EnvelopeIcon.propTypes = {
  className: PropTypes.string,
};
