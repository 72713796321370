import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { react2angular } from "react2angular";
import { ReduxProvider } from "components/data/ReduxProvider";
import { BillingSummaryCard } from "components/cards/BillingSummaryCard";

function NgBillingSummaryCard() {
  return (
    <Router>
      <ReduxProvider>
        <BillingSummaryCard />
      </ReduxProvider>
    </Router>
  );
}

angular
  .module("calcentral.react")
  .component("billingSummaryCard", react2angular(NgBillingSummaryCard));
