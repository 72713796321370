import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { CardBody } from "components/ui/Card";
import SubTitle from "./SubTitle";
import CourseTable from "./CourseTable";

export default function TeachingCoursesTable({ semester }) {
  return (
    <div data-testid="teaching-courses-table">
      <SubTitle><h3>Teaching</h3></SubTitle>
      <CardBody>
        <CourseTable>
          <thead>
            <tr>
              <th>Course</th>
              <th>Class Number</th>
              <th>Title</th>
              <th>Sections</th>
            </tr>
          </thead>
          <tbody>
            {semester.classes.map((course) => (
              <TeachingCourseRow key={course.cs_course_id} course={course} />
            ))}
          </tbody>
        </CourseTable>
      </CardBody>
    </div>
  );
}

TeachingCoursesTable.displayName = "TeachingCoursesTable";
TeachingCoursesTable.propTypes = {
  semester: PropTypes.shape({
    classes: PropTypes.arrayOf(
      PropTypes.shape({
        cs_course_id: PropTypes.string,
      })
    ),
  }),
};

function TeachingCourseRow({ course }) {
  return (
    <tr data-testid="teaching-course-row">
      <td>
        {course.listings.map((listing) => (
          <Link key={listing.course_code} to={course.url}>
            {listing.course_code}
          </Link>
        ))}
      </td>
      <td>{course.sections[0].ccn}</td>
      <td>{course.title}</td>
      <td>
        {course.scheduledSections.map((section, index) => (
          <div key={index}>
            {section.count} {section.format}
          </div>
        ))}
      </td>
    </tr>
  );
}

TeachingCourseRow.displayName = "TeachingCourseRow";
TeachingCourseRow.propTypes = {
  course: PropTypes.shape({
    listings: PropTypes.arrayOf(
      PropTypes.shape({
        course_code: PropTypes.string,
      })
    ),
    scheduledSections: PropTypes.arrayOf(
      PropTypes.shape({
        count: PropTypes.number,
        format: PropTypes.string,
      })
    ),
    sections: PropTypes.arrayOf(
      PropTypes.shape({
        ccn: PropTypes.string,
      })
    ),
    title: PropTypes.string,
    url: PropTypes.string,
  }),
};
