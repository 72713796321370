import { CCTable } from "components/ui/Table";

import styled from "styled-components";

const CourseTable = styled(CCTable)`
  thead,
  tbody {
    tr {
      th,
      td {
        padding: var(--small-spacing) 0;
        /*
        &:first-child {
          padding-left: var(--large-spacing);
        }

        &:last-child {
          padding-right: var(--large-spacing);
        }
        */
      }
    }
  }

  tbody {
    tr:nth-child(even) {
      td {
        background-color: var(--gallery);
      }
    }
  }
`;

export default CourseTable;
