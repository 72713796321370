import styled from "styled-components";

export const TriangleIcon = styled.div`
  border-bottom: 10px solid var(--thunderbird);
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  height: 0 !important;
  width: 0 !important;
  display: inline-block;
`;
