import { fetchSirStatusSuccess } from "data/actions/sirStatusActions";
import { OPTION_DECLINE } from "../statusProps";

export const onSubmit =
  (dispatch, csrfToken, setMessageObj) => async (values) => {
    const {
      acadCareer,
      stdntCarNbr,
      admApplNbr,
      applProgNbr,
      chklstItemCd,
      responseReason,
      responseDescription,
      progAction,
      actionReason,
    } = values;

    const basePayload = {
      acadCareer,
      studentCarNbr: stdntCarNbr,
      admApplNbr,
      applProgNbr,
      chklstItemCd,
      actionReason: actionReason === "" ? null : actionReason,
      progAction,
    };

    const declinePayload =
      responseDescription === ""
        ? { responseReason }
        : { responseReason, responseDescription };

    const payload =
      progAction === OPTION_DECLINE
        ? { ...basePayload, ...declinePayload }
        : basePayload;

    setMessageObj({
      messageText: values.messageText,
      progAction: values.progAction,
    });

    const res = await fetch("/api/campus_solutions/sir_response", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-TOKEN": csrfToken,
      },
      body: JSON.stringify(payload),
    });

    if (res.ok) {
      const refresh = await fetch("/api/my/sir_statuses");
      if (refresh.ok) {
        const data = await refresh.json();
        dispatch(fetchSirStatusSuccess(data));
      }
    }
  };
