import React from "react";
import { react2angular } from "react2angular";
import ReduxProvider from "components/ReduxProvider";

import { EnrollmentVerificationCard } from "components/cards/EnrollmentVerificationCard";

const NgEnrollmentVerificationCard = () => (
  <ReduxProvider>
    <EnrollmentVerificationCard />
  </ReduxProvider>
);

angular
  .module("calcentral.react")
  .component(
    "enrollmentVerificationCard",
    react2angular(NgEnrollmentVerificationCard)
  );
