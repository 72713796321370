import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import AdvisingCard from "./AdvisingCard/AdvisingCard";

import { fetchAcademicAdvising } from "data/slices/users/academicAdvising";
import currentUserState from "utils/currentUserState";

export default function AcademicAdvisingCard() {
  const dispatch = useDispatch();

  const {
    actionItems,
    advisors,
    advisingLinksEnabled,
    currentStudentAcademicRoles,
    currentUID,
    isDirectlyAuthenticated,
    isErrored,
    links,
    loadState,
    showAdvising,
  } = useSelector((state) => {
    const {
      academicAdvising: {
        errored: isErrored,
        feed: { actionItems, advisors, links } = {},
        loadState,
      } = {},
      currentUID,
    } = currentUserState(state);

    const {
      myStatus: {
        academicRoles: {
          historical: {
            summerVisitor: userIsHistoricalSummerVisitor,
            degreeSeeking: userIsHistoricalDegreeSeeking,
          } = {},
          current: currentStudentAcademicRoles,
        } = {},
        delegateActingAsUid,
        features: {
          advising: advisingFeatureEnabled,
          csAdvisingLinks: advisingLinksEnabled,
        } = {},
        isDirectlyAuthenticated,
        roles: {
          graduate: userIsGraduate,
          law: userIsLawStudent,
          student: userIsStudent,
          undergraduate: userIsUndergraduate,
        },
      } = {},
    } = state;

    const delegateActingAs = !!delegateActingAsUid;
    const studentAffiliationsOnlyLaw =
      userIsLawStudent && !userIsUndergraduate && !userIsGraduate;
    const isNonDegreeSeekingSummerVisitor =
      userIsHistoricalSummerVisitor && !userIsHistoricalDegreeSeeking;
    const showAdvising =
      !delegateActingAs &&
      advisingFeatureEnabled &&
      userIsStudent &&
      !studentAffiliationsOnlyLaw &&
      !isNonDegreeSeekingSummerVisitor;

    return {
      actionItems,
      advisors,
      advisingLinksEnabled,
      currentUID,
      currentStudentAcademicRoles,
      isDirectlyAuthenticated,
      isErrored,
      links,
      loadState,
      showAdvising,
    };
  });

  useEffect(() => {
    if (currentUID) {
      dispatch(fetchAcademicAdvising(currentUID));
    }
  }, [dispatch, currentUID]);

  if (showAdvising) {
    return (
      <AdvisingCard
        actionItems={actionItems}
        advisors={advisors}
        advisingLinksEnabled={advisingLinksEnabled}
        currentStudentAcademicRoles={currentStudentAcademicRoles}
        id="student-academic-advising-card"
        isAdvisorLookup={false}
        isDirectlyAuthenticated={isDirectlyAuthenticated}
        isErrored={isErrored}
        links={links}
        loadState={loadState}
        showAdvising={showAdvising}
        testid="academic-advising-card"
      />
    );
  } else {
    return null;
  }
}
AcademicAdvisingCard.displayName = "AcademicAdvisingCard";
