import PropTypes from "prop-types";

export const scheduleShape = PropTypes.shape({
  courseCareerCode: PropTypes.string,
  courseRole: PropTypes.string,
  crossListedCourseNames: PropTypes.array,
  displaySectionLabel: PropTypes.bool,
  examDate: PropTypes.string,
  examDateInstructor: PropTypes.string,
  examLocations: PropTypes.arrayOf(PropTypes.string),
  examSlot: PropTypes.string,
  examTime: PropTypes.string,
  examType: PropTypes.string,
  exception: PropTypes.string,
  finalized: PropTypes.string,
  name: PropTypes.string,
  sectionLabel: PropTypes.string,
  timeConflict: PropTypes.bool,
  waitlisted: PropTypes.bool,
});

export const examShape = PropTypes.shape({
  courseCareerCodes: PropTypes.arrayOf(PropTypes.string),
  schedule: PropTypes.arrayOf(scheduleShape),
});

export const semesterShape = PropTypes.shape({
  name: PropTypes.string,
  slug: PropTypes.string,
  exams: examShape,
  termCode: PropTypes.string,
  termId: PropTypes.string,
  termYear: PropTypes.string,
  timeBucket: PropTypes.string,
  totalLawUnits: PropTypes.string,
  totalUnits: PropTypes.number,
});
