import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import StyledMessage from "components/ui/StyledMessage";

function SummerLawEnrollmentInfo({ message }) {
  const feesWarning = true;

  return (
    <StyledMessage>
      <div dangerouslySetInnerHTML={{ __html: message.descrlong }} />
      {feesWarning && (
        <p style={{ marginTop: `10px` }}>
          If you drop all your summer sessions classes, you will be subject to{" "}
          <a href="#">summer fees</a>
        </p>
      )}
    </StyledMessage>
  );
}

SummerLawEnrollmentInfo.displayName = "SummerLawEnrollmentInfo";
SummerLawEnrollmentInfo.propTypes = {
  message: PropTypes.shape({
    descrlong: PropTypes.string,
  }),
};

function mapStateToProps({ currentUID, users }) {
  const { enrollmentResources: { messages: { summerLawInfo } = {} } = {} } =
    users[currentUID] || {};

  return { message: summerLawInfo };
}

export default connect(mapStateToProps)(SummerLawEnrollmentInfo);
