import React from "react";
import PropTypes from "prop-types";

import AdvisorCardSection from "./AdvisorCardSection";
import Advisor from "./Advisor";
export default function AdvisorsList({
  advisors,
  currentStudentAcademicRoles,
  isAdvisorLookup,
}) {
  const {
    haasFullTimeMba: isCurrentHassFullTimeMba,
    haasEveningWeekendMba: isCurrentHassEveningWeekendMba,
    haasExecMba: isCurrentHassExecMba,
    haasMastersFinEng: isCurrentHassMastersFinEng,
    haasMbaPublicHealth: iscurrentHaasMbaPublicHealth,
    haasMbaJurisDoctor: isCurrentHaasMbaJurisDoctor,
  } = currentStudentAcademicRoles || {};

  const isNotHaasStudent = !(
    isCurrentHassFullTimeMba ||
    isCurrentHassEveningWeekendMba ||
    isCurrentHassExecMba ||
    isCurrentHassMastersFinEng ||
    iscurrentHaasMbaPublicHealth ||
    isCurrentHaasMbaJurisDoctor
  );

  if (isNotHaasStudent) {
    return (
      <>
        {advisors.length > 0 && (
          <>
            {advisors.map((advisor, advisorIndex) => (
              <Advisor
                key={advisorIndex}
                advisor={advisor}
                isAdvisorLookup={isAdvisorLookup}
              />
            ))}
          </>
        )}
        {advisors.length === 0 && (
          <AdvisorCardSection data-testid="no-advisors-assigned-notice">
            No advisors are currently assigned to you
          </AdvisorCardSection>
        )}
      </>
    );
  } else {
    return null;
  }
}

AdvisorsList.displayName = "AdvisorsList";
AdvisorsList.propTypes = {
  advisors: PropTypes.array,
  currentStudentAcademicRoles: PropTypes.object,
  isAdvisorLookup: PropTypes.bool,
};
