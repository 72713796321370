import React from "react";
import PropTypes from "prop-types";
import { termFromId } from "components/../react/helpers/terms";

import styled from "styled-components";

const SwitcherStyles = styled.div`
  display: flex;
  margin-bottom: 15px;
`;

const TermLink = styled.a`
  flex: 1;
  color: #666;
  border-bottom: 2px solid #ccc;
  text-align: center;

  &:hover {
    text-decoration: none;
  }

  padding-bottom: 10px;
  font-weight: bold;

  &.active {
    color: #117cbe;
    border-bottom: 2px solid #117cbe;
  }
`;

export default function TermSwitcher({
  termIds = [],
  activeTermId,
  setActiveTermId,
}) {
  const terms = termIds.map(termFromId);

  if (termIds.length > 1) {
    return (
      <SwitcherStyles>
        {terms.map((term) => (
          <TermLink
            key={term.id}
            onClick={() => setActiveTermId(term.id)}
            className={term.id == activeTermId ? "active" : null}
          >
            {term.semester} {term.year}
          </TermLink>
        ))}
      </SwitcherStyles>
    );
  }

  return null;

  // TODO return null if
  // return null;
}

TermSwitcher.displayName = "TermSwitcher";
TermSwitcher.propTypes = {
  termIds: PropTypes.array,
  activeTermId: PropTypes.string,
  setActiveTermId: PropTypes.func,
};
