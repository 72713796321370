import React from "react";
import { currentUserState } from "utils/currentUserState";
import { useSelector } from "react-redux";

const StatusAsOf = () => {
  const { isStudent, isCurrentCollegeAndLevel, termName } = useSelector(
    (state) => {
      const { myStatus: { roles: { student: isStudent } = {} } = {} } = state;

      const {
        academicProfile: { termName, isCurrent: isCurrentCollegeAndLevel } = {},
      } = currentUserState(state) || {};

      return {
        isStudent,
        isCurrentCollegeAndLevel,
        termName,
      };
    }
  );

  if (!isCurrentCollegeAndLevel && isStudent && termName) {
    return (
      <h3 data-testid="status-as-of-notice">
        Academic status as of {termName}
      </h3>
    );
  } else {
    return null;
  }
};

StatusAsOf.displayName = "StatusAsOf";
export default StatusAsOf;
