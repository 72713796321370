import styled from "styled-components";

const WidgetList = styled.ul`
  font-size: 12px;
  margin: 0;
  overflow-x: hidden;
  padding: 0 15px;

  > li {
    border-bottom: 1px solid var(--gallery);
    &:last-child {
      border-bottom: 0;
    }
    > a {
      display: block;
    }
  }
`;

WidgetList.displayName = "WidgetList";

export default WidgetList;
