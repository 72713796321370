import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import qs from "querystringify";

// Uses the render-props pattern to take a API link, appends referrer to the
// URL and transforms the elements ready for rendering into a normal <a>
// link by spreading the properties onto the link element (or a styled
// variation).
//
// Note: Should be used with AccessibleLinkContent to render "opens in new
// window" message automatically.
//
// This is a low-level UI component. Consider using an alternative like
// AccessibleAPILink or BlueButtonLink (which do this work by default) or
// implementing a variation.
//
// Example:
//  <WithAPILink urlID="SOME_API_LINK_ID">
//    {link => (
//      <TransformsAPILink link={link}>
//        {({ props, name, showNewWindow }) => (
//          <a {...props}>
//            <AccessibleLinkContent
//              link={link}
//              showNewWindow={showNewWindow}
//             />
//          </a>
//        )
//      </TransformsAPILink>
//    )}
//  <WithAPILink>
export default function TransformsAPILink({
  children,
  link,
  urlParameters = false,
}) {
  const currentRoute = useSelector(({ currentRoute }) => {
    if (currentRoute.url) {
      return currentRoute;
    }

    return {
      name: document.title,
      url: window.location.href,
      path: window.location.pathname,
    };
  });
  if (link) {
    const { url, ucFrom, ucUpdateCache, showNewWindow } = link;

    // Replace template values in URL with parameters (if applicable)
    const evaluatedURL = urlParameters
      ? evaluateURLTemplate(url, urlParameters)
      : url;

    const href = appendReferrerToURL({
      url: evaluatedURL,
      ucFrom,
      ucUpdateCache,
      currentRoute,
    });
    const target = showNewWindow ? "_blank" : "_self";
    const rel = showNewWindow ? "noopener noreferrer" : null;
    const transformed = {
      disabled: link.disabled,
      title: link.title,
      href,
      target,
      rel,
      "data-testid": "api-link",
      "data-url-id": link.urlId,
    };

    return children({
      text: link.name || link.title,
      showNewWindow,
      props: transformed,
    });
  }
  return null;
}

TransformsAPILink.propTypes = {
  children: PropTypes.func,
  link: PropTypes.shape({
    disabled: PropTypes.bool,
    name: PropTypes.string,
    title: PropTypes.string,
    showNewWindow: PropTypes.bool,
    ucFrom: PropTypes.string,
    ucUpdateCache: PropTypes.string,
    url: PropTypes.string,
    urlId: PropTypes.string,
  }),
  urlParameters: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
};

// Transforms an incoming API link by appending "ucFrom" properties that Campus Solutions expects.
const appendReferrerToURL = ({ url, ucFrom, ucUpdateCache, currentRoute }) => {
  if (ucFrom) {
    const queryStringPrefix = url.includes("?") ? "&" : true;
    return (
      url +
      qs.stringify(
        {
          ucFrom: "CalCentral",
          ucFromText: currentRoute.name,
          ucFromLink: returnURL(currentRoute.url, ucUpdateCache),
        },
        queryStringPrefix
      )
    );
  }

  return url;
};

// the ucFromLink value may need to have a 'ucUpdateCache' parameter appended to it,
// if we get the value from the Link API the following will append it to the ucFromLink
const returnURL = (url, updateCache) => {
  const queryStringPrefixFromLink = url.includes("?") ? "&" : true;
  return updateCache && !url.includes("ucUpdateCache")
    ? url +
        qs.stringify({ ucUpdateCache: updateCache }, queryStringPrefixFromLink)
    : url;
};

/**
 * Replaces template values in URL string with those provided in urlParameters
 * object.
 *
 * Example
 *    Input:
 *      url: "https://example.com/financial_aid?emplid={EMPLID}&ucAidYear={AID_YEAR}"
 *      urlParameters: { EMPLID: '1234', AID_YEAR: '2025' }
 *    Output:
 *      "https://example.com/financial_aid?emplid=1234&ucAidYear=2025"
 *
 * @param {string} url
 * @param {Object} urlParameters
 * @returns string
 */
const evaluateURLTemplate = (url, urlParameters) => {
  // create copy to avoid mutation
  let evaluatedURL = url.slice();
  // replace each template value in URL
  for (const parameter in urlParameters) {
    evaluatedURL = evaluatedURL.replaceAll(
      "{" + parameter + "}",
      urlParameters[parameter]
    );
  }
  return evaluatedURL;
};
