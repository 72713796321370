import React from "react";
import PropTypes from "prop-types";

import { CreditsContainer } from "./CreditsContainer";
import { TransferCreditTable } from "./TransferCreditTable";
import { MediumSpacer } from "components/ui/VerticalSpacers";
import HairlineRule from "components/ui/HairlineRule";

export function GraduateTransferCredit({ transferCredits, hasHairline }) {
  const { summary, detailed } = transferCredits;
  const { careerDescr, totalTransferUnits } = summary || {};

  if (summary) {
    return (
      <>
        <MediumSpacer />
        <TransferCreditTable>
          <thead>
            <tr>
              <th>Type</th>
              <th>Units</th>
            </tr>
          </thead>
          <tbody>
            <CreditsContainer
              title={careerDescr + " Course Units"}
              value={totalTransferUnits.toFixed(3)}
              detailedRows={detailed}
            />
          </tbody>
        </TransferCreditTable>
        {hasHairline && <HairlineRule />}
      </>
    );
  }

  return null;
}

GraduateTransferCredit.propTypes = {
  transferCredits: PropTypes.object,
  hasHairline: PropTypes.bool,
};
