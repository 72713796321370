import React from "react";
import { useAdvisingStudent } from "components/hooks/useAdvisingStudent";

export const StudentID = () => {
  const { campusSolutionsId = "", studentId = "" } = useAdvisingStudent(
    (student) => student.advisingStudentProfile
  );

  if (studentId || campusSolutionsId) {
    return (
      <>
        <dt>SID</dt>
        <dd>
          <strong>{studentId || campusSolutionsId}</strong>
        </dd>
      </>
    );
  }
  return null;
};
StudentID.displayName = "StudentID";
