import React, { useState } from "react";
import PropTypes from "prop-types";
import { Card, CardTitle, CardBody } from "components/ui/Card";
import RequireSuperuser from "components/RequireSuperuser";
import { UnstyledList } from "components/ui/Lists";
import { BlueButton } from "components/ui/Buttons";
import { MediumSpacer } from "components/ui/VerticalSpacers";

function IconForResult({ succeeded }) {
  if (succeeded) {
    return <i title="Success" className="fa fa-check-circle" />;
  } else {
    return <i title="Failed" className="fa fa-check-circle" />;
  }
}

IconForResult.displayName = "IconForResult";
IconForResult.propTypes = {
  succeeded: PropTypes.bool,
};

function resultColor({ succeeded }) {
  if (succeeded) {
    return "var(--jewel)";
  } else {
    return "var(--thunderbird)";
  }
}

export function DataSourceVerificationCard() {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [failed, setFailed] = useState(false);

  const start = () => {
    setLoading(true);
    fetch("/api/admin/data_source_verification")
      .then((res) => {
        if (res.ok) {
          setLoading(false);
          return res.json();
        } else {
          setLoading(false);
          setFailed(true);
        }
      })
      .then((results) => {
        setResults(results);
      });
  };

  return (
    <RequireSuperuser>
      <Card>
        <CardTitle>
          <h2>Data Source Verification</h2>
        </CardTitle>
        <CardBody>
          <BlueButton onClick={start} disabled={loading}>
            Run
          </BlueButton>
          <MediumSpacer />

          {failed && <p>Request failed. Try again later</p>}

          {!failed && (
            <div>
              {loading && (
                <>
                  <i title="Pending" className="fa fa-spinner fa-spin" />
                  <span> Loading...</span>
                </>
              )}
              {!loading && results.length > 0 && (
                <UnstyledList>
                  {results.map((result, index) => (
                    <li
                      key={index}
                      style={{ color: resultColor(result), fontSize: "10px" }}
                    >
                      <IconForResult result={result} />
                      {result && result.requester && (
                        <span style={{ marginLeft: `5px` }}>
                          {result.requester}
                        </span>
                      )}
                      {result && !result.succeeded && (
                        <span style={{ marginLeft: `5px` }}>
                          {result.error}
                        </span>
                      )}
                    </li>
                  ))}
                </UnstyledList>
              )}
            </div>
          )}
        </CardBody>
      </Card>
    </RequireSuperuser>
  );
}
DataSourceVerificationCard.displayName = "DataSourceVerificationCard";
