import React from "react";
import { react2angular } from "react2angular";
import { ReduxProvider } from "components/data/ReduxProvider";
import { DelegateIntroCard } from "components/cards/DelegateIntroCard";

const NgDelegateIntroCard = () => (
  <ReduxProvider>
    <DelegateIntroCard />
  </ReduxProvider>
);

angular
  .module("calcentral.react")
  .component("delegateIntroCard", react2angular(NgDelegateIntroCard));
