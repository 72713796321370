import React from "react";
import PropTypes from "prop-types";

import { CreditsContainer } from "./CreditsContainer";
import { TransferCreditTable } from "./TransferCreditTable";
import { MediumSpacer } from "components/ui/VerticalSpacers";

export function UgdTransferCredit({ transferCredits }) {
  const { summary, detailed } = transferCredits;
  const {
    totalTransferUnits,
    careerDescr,
    totalTransferUnitsNonAdjusted,
    alevelTestUnits,
    apTestUnits,
    ibTestUnits,
    otherCreditUnits,
  } = summary || {};

  const examUnits = alevelTestUnits + apTestUnits + ibTestUnits;
  const hasExamUnits = Boolean(alevelTestUnits || apTestUnits || ibTestUnits);

  if (summary) {
    return (
      <>
        <MediumSpacer />
        <TransferCreditTable>
          <thead>
            <tr>
              <th>Type</th>
              <th>Units</th>
            </tr>
          </thead>

          <tbody>
            {totalTransferUnits ? (
              <CreditsContainer
                title={careerDescr + " Course Units"}
                value={totalTransferUnits.toFixed(3)}
                detailedRows={detailed}
                nonAdjusted={totalTransferUnitsNonAdjusted}
              ></CreditsContainer>
            ) : null}
          </tbody>

          {hasExamUnits && (
            <tbody>
              <CreditsContainer
                title={careerDescr + " Exam Units"}
                value={examUnits.toFixed(3)}
                detailedRows={[
                  {
                    exam: "Advanced Placement (AP)",
                    units: apTestUnits,
                  },
                  {
                    exam: "International Baccalaureate (IB)",
                    units: ibTestUnits,
                  },
                  {
                    exam: "GCE Advanced-Level (A-Level)",
                    units: alevelTestUnits,
                  },
                ]}
              />
            </tbody>
          )}

          {otherCreditUnits > 0 && (
            <tbody>
              <tr>
                <td>{careerDescr} Other Credits</td>
                <td
                  style={{
                    paddingRight: `var(--medium-spacing)`,
                    textAlign: `right`,
                  }}
                >
                  {otherCreditUnits.toFixed(3)}
                </td>
              </tr>
            </tbody>
          )}
        </TransferCreditTable>
      </>
    );
  }

  return null;
}

UgdTransferCredit.propTypes = {
  transferCredits: PropTypes.object,
};
