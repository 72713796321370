import styled from "styled-components";
import { mediumBreakpoint } from "config/media";

const ButtonLinkGroup = styled.div`
  font-size: 11px;
  text-wrap: nowrap;

  @media screen and (max-width: ${mediumBreakpoint}px) {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    width: 100%;
    padding: 0 var(--md);

    > a {
      flex: 1;
      text-align: center;
    }
  }

  a {
    border: 1px solid var(--silver);
    border-right-width: 0;
    border-left-width: 0;
    color: var(--dove-gray);
    background: var(--wild-sand);
    font-weight: bold;
    padding: 6px 10px;

    &:hover,
    &:active,
    &:focus {
      color: var(--denim);
      text-decoration: none;
    }

    &.active {
      color: var(--denim);
      border: 1px solid var(--denim);
      background: var(--foam);
    }

    :not(&.active) + :not(&.active) {
      border-left-width: 1px;
    }

    &.active + :not(&.active) {
      border-left-width: 0;
    }

    &:first-child {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      border-left-width: 1px;
    }

    &:last-child {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      border-right-width: 1px;
    }
  }
`;
ButtonLinkGroup.displayName = "ButtonLinkGroup";

export default ButtonLinkGroup;
