import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { connect } from "react-redux";

import { UnstyledList } from "components/ui/Lists";
import { GreenCheck, YellowWarning, RedWarning } from "components/ui/Icons";
import { Card, CardBody, CardTitle } from "components/ui/Card";

export default connect(mapStateToProps)(AdvisorGraduateDegreeProgressCard);

function mapStateToProps({
  advising: {
    graduateDegreeProgress: { degreeProgress: degreeProgresses = [] },
  },
}) {
  return { degreeProgresses };
}

function AdvisorGraduateDegreeProgressCard({ degreeProgresses }) {
  return (
    <Card data-testid="advisor-graduate-degree-progress-card">
      <CardTitle>
        <h2>Degree Progress</h2>
      </CardTitle>
      <CardBody>
        <GraduateDegreeProgresses progresses={degreeProgresses} />
      </CardBody>
    </Card>
  );
}

AdvisorGraduateDegreeProgressCard.displayName =
  "AdvisorGraduateDegreeProgressCard";
AdvisorGraduateDegreeProgressCard.propTypes = {
  degreeProgresses: PropTypes.array.isRequired,
};

function GraduateDegreeProgresses({ progresses }) {
  if (progresses.length) {
    return (
      <UnstyledList>
        {progresses.map((progress) => (
          <GradDegreeProgress key={progress.acadPlanCode} progress={progress} />
        ))}
      </UnstyledList>
    );
  } else {
    return (
      <div>
        This student has not completed any Graduate Division Milestones.
      </div>
    );
  }
}

GraduateDegreeProgresses.displayName = "GraduateDegreeProgresses";
GraduateDegreeProgresses.propTypes = {
  progresses: PropTypes.array.isRequired,
};

function GradDegreeProgress({ progress }) {
  return (
    <li>
      <h3>
        {progress.acadPlan && <>{progress.acadPlan} &mdash;</>} Graduate
        Division Milestones
      </h3>

      <ProgressRequirements requirements={progress.requirements} />
    </li>
  );
}

GradDegreeProgress.displayName = "GradDegreeProgress";
GradDegreeProgress.propTypes = {
  progress: PropTypes.shape({
    acadPlan: PropTypes.string,
    requirements: PropTypes.array,
  }),
};

function ProgressRequirements({ requirements }) {
  const orderedRequirements = [...requirements].sort(
    (a, b) => a.orderNumber - b.orderNumber
  );

  return (
    <UnstyledList>
      {orderedRequirements.map((req) => (
        <Requirement key={req.code} requirement={req} />
      ))}
    </UnstyledList>
  );
}

ProgressRequirements.displayName = "ProgressRequirements";
ProgressRequirements.propTypes = {
  requirements: PropTypes.array,
};

function Requirement({
  requirement: {
    name,
    status,
    statusCode,
    formNotification,
    proposedExamDate,
    attempts,
    candidacyTermStatus,
    dateCompleted,
  },
}) {
  return (
    <RequirementStyles>
      <div className="media">
        <RequirementStatusIcon statusCode={statusCode} attempts={attempts} />
      </div>
      <div className="body">
        <h4>{name}</h4>

        {attempts.length === 0 && (
          <div>
            {status} {statusCode === "Y" && dateCompleted}
            {formNotification}
          </div>
        )}

        {proposedExamDate && <div>Proposed Exam Date: {proposedExamDate}</div>}

        <ul>
          {attempts.map((attempt, index) => (
            <li key={index}>{attempt.display}</li>
          ))}
        </ul>

        {candidacyTermStatus && (
          <div>
            <div>
              Candidacy End Term: {candidacyTermStatus.endTermDescription}
            </div>
            <div>Candidacy Status: {candidacyTermStatus.statusDescription}</div>
          </div>
        )}
      </div>
    </RequirementStyles>
  );
}

Requirement.displayName = "Requirement";
Requirement.propTypes = {
  requirement: PropTypes.shape({
    name: PropTypes.string,
    status: PropTypes.string,
    statusCode: PropTypes.string,
    formNotification: PropTypes.string,
    proposedExamDate: PropTypes.string,
    attempts: PropTypes.arrayOf(
      PropTypes.shape({
        display: PropTypes.string,
      })
    ),
    candidacyTermStatus: PropTypes.shape({
      endTermDescription: PropTypes.string,
      statusDescription: PropTypes.string,
    }),
    dateCompleted: PropTypes.string,
  }),
};

const RequirementStyles = styled.li`
  display: flex;
  margin-top: 5px;

  .media {
    width: 20px;
  }

  .body {
    h4 {
      margin: 0;
    }
  }
`;

function RequirementStatusIcon({ statusCode, attempts }) {
  if (statusCode === "Y" || statusCode === "P") {
    return <GreenCheck />;
  } else if (statusCode === "PF" || statusCode === "F") {
    if (attempts && attempts.length <= 1) {
      return <YellowWarning />;
    } else if (attempts && attempts.length > 1) {
      return <RedWarning />;
    } else {
      return null;
    }
  } else {
    return null;
  }
}

RequirementStatusIcon.displayName = "RequirementStatusIcon";
RequirementStatusIcon.propTypes = {
  statusCode: PropTypes.oneOf(["Y", "P", "PF", "F", "N"]),
  attempts: PropTypes.array,
};
