import React from "react";
import { useUser } from "../hooks/useUser";
import { react2angular } from "react2angular";
import { ReduxProvider } from "components/data/ReduxProvider";
import { Spinner } from "components/ui/Spinner";

function NgCampusLoading() {
  return (
    <ReduxProvider>
      <CampusLoading />
    </ReduxProvider>
  );
}

function CampusLoading() {
  const { loadState } = useUser((user) => user.campusLinks);

  if (loadState === "pending") {
    return (
      <div style={{ width: `100%`, padding: `var(--lg)`, textAlign: `center` }}>
        <Spinner />
      </div>
    );
  }

  return null;
}

angular
  .module("calcentral.react")
  .component("campusLoading", react2angular(NgCampusLoading));
