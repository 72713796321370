import React from "react";
import { Card, CardBody, CardTitle } from "components/ui/Card";
import { fetchAcademicProfile } from "data/slices/users/academicProfile";
import { useUser } from "components/hooks/useUser";
import { dispatchUserSlice } from "components/hooks/dispatchUserSlice";
import { ListBullets } from "components/ui/Lists";

import Spinner from "components/ui/Spinner";
import styled from "styled-components";
import WithMessage from "components/WithMessage";
import { ErrorBoundary } from "components/ui/ErrorBoundary";

const AlignedListHeader = styled.h3`
  margin: 10px 10px 10px -15px;
`;

export function GraduationChecklistCard() {
  return (
    <ErrorBoundary title="Graduation Checklist">
      <GraduationChecklistCardUI />
    </ErrorBoundary>
  );
}

function GraduationChecklistCardUI() {
  dispatchUserSlice(fetchAcademicProfile);

  const termName = useUser((user) => {
    const { graduation: { undergraduate: { termName } = {} } = {} } =
      user.academicProfile;

    return termName;
  });

  if (termName === undefined) {
    return <Spinner />;
  }

  return (
    <Card>
      <CardTitle>
        <h2>Graduation Checklist</h2>
      </CardTitle>
      <CardBody>
        <div style={{ padding: `var(--small-spacing) 0` }}>
          Your expected graduation term is:&nbsp;
          <strong>{termName}</strong>
        </div>

        <p>It is expected that you complete the following before graduation:</p>

        <ListBullets>
          <AlignedListHeader>Required Tasks</AlignedListHeader>
          <WithMessage set="28000" number="210">
            {(message) => (
              <div
                dangerouslySetInnerHTML={{
                  __html: message.description,
                }}
              ></div>
            )}
          </WithMessage>
        </ListBullets>

        <ListBullets>
          <AlignedListHeader>For Students with Loans</AlignedListHeader>
          <WithMessage set="28000" number="211">
            {(message) => (
              <div
                dangerouslySetInnerHTML={{
                  __html: message.description,
                }}
              ></div>
            )}
          </WithMessage>
        </ListBullets>

        <ListBullets>
          <AlignedListHeader>Recommended Tasks</AlignedListHeader>
          <WithMessage set="28000" number="212">
            {(message) => (
              <div
                dangerouslySetInnerHTML={{
                  __html: message.description,
                }}
              ></div>
            )}
          </WithMessage>
        </ListBullets>
      </CardBody>
    </Card>
  );
}

GraduationChecklistCard.displayName = "GraduationChecklistCard";
