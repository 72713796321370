import React from "react";
import PropTypes from "prop-types";

export const FormatCurrency = ({ value }) => {
  if (value === null) {
    return "N/A";
  }

  const [sign, formatted] = formatCurrency(value, 0);
  return (
    <>
      {sign}&nbsp;{formatted}
    </>
  );
};

FormatCurrency.displayName = "FormatCurrency";
FormatCurrency.propTypes = {
  value: PropTypes.number,
};

const formatCurrency = (amount, digits = 2) => {
  const sign = Math.abs(amount) === amount ? "$" : "- $";

  const formatted = Math.abs(amount)
    .toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: digits,
    })
    .slice(1);

  return [sign, formatted];
};
