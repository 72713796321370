import styled from "styled-components";

export const Sidebar = styled.div`
  background-color: var(--calypso);
  color: white;
  min-height: 100%;

  h3 {
    color: inherit;
    padding: 0 0 0 5px;
    margin: 0;
    font-weight: normal;
    font-size: 16px;
  }

  a {
    color: inherit;
    display: block;
  }
`;

export const NavigationGroupItem = styled.li`
  padding: var(--md);
  border-top: 1px solid var(--casper);

  a {
    padding: 2px 5px;
    border-radius: 5px;

    &[aria-current="page"] {
      font-weight: bold;
    }

    &[aria-current="page"],
    &:hover {
      background-color: var(--wedgewood);
      text-decoration: none;
    }
  }

  ul {
    margin: 10px 0;
    li + li {
      margin-top: 2px;
    }
  }
`;
