import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import {
  AccordionButton,
  AccordionItem,
  AccordionPanel,
} from "components/ui/Accordion";

export const ActionItemAccordionItem = styled(AccordionItem)`
  overflow: hidden;
  padding: 8px 20px 8px 0;
`;

export default function ActionItem({ actionItem }) {
  if (actionItem) {
    return (
      <li>
        <ActionItemAccordionItem>
          <AccordionButton style={{ padding: "0" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "0",
              }}
            >
              <div>{actionItem.actionItemTitle}</div>
              {actionItem.actionItemDueDate && (
                <div>Due {actionItem.actionItemDueDate.dateString}</div>
              )}
            </div>

            <AccordionPanel style={{ paddingRop: "10px" }}>
              {actionItem.actionItemAssignedDate && (
                <div style={{ paddingTop: "var(--medium-spacing)" }}>
                  Assigned on {actionItem.actionItemAssignedDate.dateString}
                  {actionItem.actionItemAssignedAdvisor && (
                    <span>by {actionItem.actionItemAssignedAdvisor}</span>
                  )}
                </div>
              )}
              <div
                style={{
                  paddingTop: "var(--medium-spacing)",
                  fontSize: "13px",
                  lineHeight: "19.5px",
                }}
                dangerouslySetInnerHTML={{
                  __html: actionItem.actionItemDescription,
                }}
              ></div>
            </AccordionPanel>
          </AccordionButton>
        </ActionItemAccordionItem>
      </li>
    );
  } else {
    return null;
  }
}

ActionItem.displayName = "ActionItem";
ActionItem.propTypes = {
  actionItem: PropTypes.object,
};
