import React from "react";
import { useSelector } from "react-redux";
import { TabList, Tab } from "components/ui/Tabs";

import currentUserState from "utils/currentUserState";

export function TaskTabs() {
  const { incompleteCount, completeCount } = useSelector((state) => {
    const {
      agreements: { incompleteAgreements = [], completedAgreements = [] } = {},
      checklistItems: { completedItems = [], incompleteItems = [] } = {},
      bCoursesTodos: { bCoursesTodos = [] } = {},
    } = currentUserState(state);

    return {
      incompleteCount:
        incompleteAgreements.length +
        incompleteItems.length +
        bCoursesTodos.length,
      completeCount: completedAgreements.length + completedItems.length,
    };
  });

  return (
    <TabList>
      <Tab data-testid="incomplete-tasks-tab">Incomplete ({incompleteCount})</Tab>
      <Tab data-testid="completed-tasks-tab">Completed ({completeCount})</Tab>
    </TabList>
  );
}
