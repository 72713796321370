import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { react2angular } from "react2angular";
import { ReduxProvider } from "components/data/ReduxProvider";
import { CampusRedirect } from "components/pages/CampusPage/CampusRedirect";

function NgCampusRedirect() {
  return (
    <ReduxProvider>
      <Router>
        <Routes>
          <Route path="/campus/" element={<CampusRedirect />} />
          <Route path="/campus/:categoryId" element={<CampusRedirect />} />
        </Routes>
      </Router>
    </ReduxProvider>
  );
}

angular
  .module("calcentral.react")
  .component("campusRedirect", react2angular(NgCampusRedirect));
