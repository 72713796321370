import React from "react";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import { useSlice } from "components/hooks/useSlice";
import { useFeatures } from "components/hooks/useFeatures";
import { OPTION_DECLINE } from "../statusProps";

export const SubmitButton = ({ isUndergraduate, depositRequired }) => {
  const {
    isSubmitting,
    isValid,
    values: { progAction },
  } = useFormikContext();
  const { isDirectlyAuthenticated } = useSlice("myStatus");
  const { preventActingAsUsersFromPosting } = useFeatures();
  const canPost = preventActingAsUsersFromPosting
    ? isDirectlyAuthenticated
    : true;

  if (isSubmitting) {
    return (
      <button disabled={true} className="cc-button cc-button-blue">
        Submitting...
      </button>
    );
  } else if (progAction === OPTION_DECLINE) {
    return (
      <button
        disabled={!canPost || !isValid}
        className="cc-button cc-button-blue"
      >
        I Decline This Offer
      </button>
    );
  } else if (isUndergraduate && depositRequired) {
    return (
      <button
        disabled={!canPost || !isValid}
        className="cc-button cc-button-blue"
      >
        Continue to Payment
      </button>
    );
  }

  return (
    <button
      disabled={!canPost || !isValid}
      className="cc-button cc-button-blue"
    >
      Confirm
    </button>
  );
};

SubmitButton.propTypes = {
  progAction: PropTypes.string,
  isUndergraduate: PropTypes.bool,
  depositRequired: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  isValid: PropTypes.bool,
};
