import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { DenimArrowRightIcon } from "components/ui/Icons";

const SemesterHeaderStyles = styled.div`
  margin: 10px 0px 2px;
  width: 100%;

  &:after {
    content: "";
    display: table;
    clear: both;
  }

  > div {
    border: 1px solid var(--silver);
    padding: 10px;
  }

  h3 {
    font-size: 14px;
    margin: 0;
  }

  .leftAlign {
    float: left;
  }

  .rightAlign {
    float: right;
  }

  .textbooksLink {
    margin-top: 0px;
  }

  .notation {
    color: var(--dusty-gray);
    margin-left: 10px;
  }

  .status {
    border: 0;
    clear: both;
    font-size: 15px;
    margin: 0;
    padding: 10px 0 0;
    > span {
      display: inline-block;
      vertical-align: middle;
    }
  }
`;

const SemesterHeader = ({ semester, isAdvisingView }) => {
  return (
    <SemesterHeaderStyles data-testid="semester-header">
      {semester.hasEnrollmentData &&
        !semester.hasWithdrawalData &&
        !semester.filteredForDelegate &&
        !isAdvisingView && (
          <h3 data-testid="semester-header-name">
            <a
              className="leftAlign"
              data-testid="semester-header-name-link"
              href={"/academics/semester/" + semester.slug}
            >
              {semester.name} <DenimArrowRightIcon />
            </a>
          </h3>
        )}
      {((!semester.hasEnrollmentData && semester.hasStudyProgData) ||
        semester.hasWithdrawalData ||
        !semester.hasEnrollmentData ||
        semester.filteredForDelegate ||
        isAdvisingView) && (
        <h3 className="leftAlign" data-testid="semester-header-name">
          {semester.name}
        </h3>
      )}
      {semester.hasWithdrawalData && (
        <div className="status" data-testid="semester-header-withdrawal">
          <span>{semester.withdrawalStatus.withcnclTypeDescr}</span>&nbsp;
          <span>{semester.withdrawalStatus.withcnclFromDate}</span>
        </div>
      )}
      {semester.hasStudyProgData && (
        <span className="notation" data-testid="semester-study-program">
          {semester.studyProg.studyprogTypeDescr}
        </span>
      )}
      {semester.hasEnrollmentData &&
        !semester.filteredForDelegate &&
        semester.timeBucket !== "past" &&
        !isAdvisingView && (
          <a
            className="rightAlign textbooksLink"
            href={"/academics/booklist/" + semester.slug}
            data-testid="semester-header-textbooks-link"
          >
            Textbooks
          </a>
        )}
    </SemesterHeaderStyles>
  );
};

SemesterHeader.displayName = "SemesterHeader";
SemesterHeader.propTypes = {
  isAdvisingView: PropTypes.bool,
  semester: PropTypes.object,
};

export default SemesterHeader;
