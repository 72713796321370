import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { advisorViewAsUID, viewAsUID } from "utils/requests";
import { useSelector } from "react-redux";

import ButtonAsLink from "components/ui/ButtonAsLink";
import { IconWrapper } from "components/cards/StudentSummaryCard/IconWrapper";
import { EyeIcon, RedWarning, TimesIcon } from "components/ui/Icons";

import InfoIcon from "components/ui/InfoIcon";
import WithMessage from "components/WithMessage";

const CloseButtonIcon = styled(TimesIcon)`
  color: var(--dusty-gray);
  cursor: pointer;
  height: 10px;
  width: 10px;
`;

export const AlumniViewAsIcon = styled(RedWarning)`
  margin-left: 4px;
`;

const ViewAsButton = styled(ButtonAsLink)`
  margin-top: 5px;
`;

const ViewAsAlumniButton = styled(InfoIcon)`
  color: var(--hoki);
  padding-left: 12px;
`;

export const ViewAs = ({ ldapUid }) => {
  const {
    csrfToken,
    isAdvisorOnly,
    isDirectlyAuthenticated,
    isHistoricalLaw,
    roles,
  } = useSelector((state) => {
    const {
      csrfToken,
      advising: {
        studentData: {
          academicRoles: { historical: { law: isHistoricalLaw } = {} } = {},
          attributes: { roles = {} } = {},
        } = {},
      } = {},
      myStatus: {
        isSuperuser,
        isViewer,
        roles: { advisor: isAdvisor },
        isDirectlyAuthenticated,
      },
    } = state;

    const isAdvisorOnly = isAdvisor && !isSuperuser && !isViewer;

    return {
      csrfToken,
      isAdvisorOnly,
      isDirectlyAuthenticated,
      isHistoricalLaw,
      roles,
    };
  });

  const viewAs = !roles.alumniFormer || isHistoricalLaw;
  const viewAsAlum = roles.alumniFormer && !isHistoricalLaw;

  const [showAlumnInfo, setShowAlumnInfo] = useState(false);

  const viewAsUser = async (userId) => {
    const req = isAdvisorOnly
      ? advisorViewAsUID(csrfToken)
      : viewAsUID(csrfToken);

    const res = await req(userId);
    if (res.ok) {
      window.location = "/";
    }
  };

  if (viewAs || viewAsAlum) {
    return (
      <div>
        <IconWrapper>
          <EyeIcon />
        </IconWrapper>
        <ViewAsButton
          onClick={() => {
            viewAsUser(ldapUid);
          }}
          disabled={!isDirectlyAuthenticated}
        >
          View As
        </ViewAsButton>
        {viewAsAlum && <AlumniViewAsIcon />}
        {viewAsAlum && !showAlumnInfo && (
          <ViewAsAlumniButton
            aria-label="View As Alumni?"
            role="button"
            tabindex="0"
            onClick={() => {
              setShowAlumnInfo(true);
            }}
          />
        )}
        <ActsAsAlumInfo
          showAlumnInfo={showAlumnInfo}
          setShowAlumnInfo={setShowAlumnInfo}
        />
      </div>
    );
  }
  return null;
};
ViewAs.displayName = "ViewAs";
ViewAs.propTypes = {
  ldapUid: PropTypes.string,
};

const ActAsAlumniInfoContainer = styled.div`
  background-color: var(--wild-sand);
  margin-bottom: 5px;
  padding-bottom: 5px;
  padding-left: 15px;
  padding-right: 12px;
  padding-top: 12px;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .message {
    padding-top: 7px;
  }
`;

export const ActsAsAlumInfo = ({ showAlumnInfo, setShowAlumnInfo }) => {
  if (showAlumnInfo) {
    return (
      <ActAsAlumniInfoContainer>
        <div className="header">
          <div className="title">
            <strong>View As</strong>
          </div>
          <div className="closeButton">
            <ButtonAsLink onClick={() => setShowAlumnInfo(false)}>
              <CloseButtonIcon />
            </ButtonAsLink>
          </div>
        </div>

        <WithMessage set="32000" number="30">
          {(message) => (
            <div
              dangerouslySetInnerHTML={{
                __html: message.description,
              }}
            ></div>
          )}
        </WithMessage>
      </ActAsAlumniInfoContainer>
    );
  } else {
    return null;
  }
};
ActsAsAlumInfo.displayName = "ActsAsAlumInfo";
ActsAsAlumInfo.propTypes = {
  showAlumnInfo: PropTypes.bool,
  setShowAlumnInfo: PropTypes.func,
};
