import { combineReducers, createSlice } from "@reduxjs/toolkit";
import { delegateToReducerIfKey } from "data/utils/delegateToReducerIfKey";

import { courseInfo } from "./courses/courseInfo";
import { courseCaptures } from "./courses/courseCaptures";
import { roster } from "./courses/roster";

const courseReducer = combineReducers({
  courseInfo,
  courseCaptures,
  roster,
});

const byCourse = createSlice({
  name: "byCourse",
  initialState: {},
  reducers: {},
  extraReducers: (builder) => {
    builder.addDefaultCase(delegateToReducerIfKey(courseReducer, "courseId"));
  },
});

const { reducer } = byCourse;

export default reducer;
