import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import DisclosureChevron from "components/../react/components/DisclosureChevron";

export const CreditsContainer = ({
  expanded: initallyExpanded = false,
  title,
  detailedRows = [],
  value,
  nonAdjusted = null,
}) => {
  const [expanded, setExpanded] = useState(initallyExpanded);
  var rows = [];
  if (detailedRows) {
    rows = detailedRows.filter((detailedRow) => detailedRow.units !== 0.0);
  }
  return (
    <>
      <ContainerStyles onClick={() => setExpanded(!expanded)}>
        <td>{title}</td>
        <td align="right">
          {rows.length > 0 ? (
            <>
              <span>{value}</span> <DisclosureChevron expanded={expanded} />
            </>
          ) : (
            <span style={{ paddingRight: `14px` }}>{value}</span>
          )}
        </td>
      </ContainerStyles>
      {expanded && (
        <>
          {rows.map((detail, index) => {
            if (!detail.exam) {
              return (
                <DetailedRowStyles key={index}>
                  <td>
                    <i>
                      <span>{detail.school}</span>
                      {detail.requirementDesignation && (
                        <span>{detail.requirementDesignation}</span>
                      )}
                    </i>
                  </td>
                  <td align="right">{detail.units.toFixed(3)}</td>
                </DetailedRowStyles>
              );
            } else {
              return (
                <DetailedRowStyles key={index}>
                  <td>
                    <i>{detail.exam}</i>
                  </td>
                  <td align="right">{detail.units.toFixed(3)}</td>
                </DetailedRowStyles>
              );
            }
          })}
          {nonAdjusted > 0 && (
            <DetailedRowStyles>
              <td colSpan={2} align="right">
                <i>
                  {"(Non-Adjusted Course Units: " +
                    nonAdjusted.toFixed(3) +
                    ")"}
                </i>
              </td>
            </DetailedRowStyles>
          )}
        </>
      )}
    </>
  );
};

CreditsContainer.displayName = "CreditsContainer";
CreditsContainer.propTypes = {
  expanded: PropTypes.bool,
  title: PropTypes.string,
  detailedRows: PropTypes.array,
  nonAdjusted: PropTypes.number,
  value: PropTypes.string,
};

const ContainerStyles = styled.tr`
  border-bottom: 1px solid var(--gallery);
  padding-top: var(--medium-spacing);

  &:hover {
    background-color: var(--foam);
  }
`;

const DetailedRowStyles = styled.tr`
  border-bottom: 1px solid var(--gallery);
  padding-right: 14px;
`;
