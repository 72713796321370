import React from "react";
import { useSelector } from "react-redux";
import { fetchAcademicsDiploma } from "data/actions/academics/diplomaActions";
import { fetchAcademicProfile } from "data/slices/users/academicProfile";
import { fetchEnrollments } from "data/slices/users/enrollments";
import { fetchLawAwards } from "data/actions/lawAwardsActions";
import { fetchTransferCredit } from "data/actions/transferCreditActions";
import { fetchUserProfile } from "data/slices/userProfile";

import { Card, CardTitle, CardBody } from "components/ui/Card";
import Spinner from "components/../react/components/Spinner";

import { AcademicProfileList } from "./AcademicProfileCard/AcademicProfileList";
import { CollegeAndLevelError } from "./AcademicProfileCard/CollegeAndLevelError";
import ConcurrentEnrollmentMessage from "./AcademicProfileCard/ConcurrentEnrollmentMessage";
import StatusAsOf from "./AcademicProfileCard/StatusAsOf";
import { NameAndPhoto } from "./AcademicProfileCard/NameAndPhoto";
import { Major } from "./AcademicProfileCard/Major";
import { Minor } from "./AcademicProfileCard/Minor";
import { Emphases } from "./AcademicProfileCard/Emphases";
import { Careers } from "./AcademicProfileCard/Careers";
import { Levels } from "./AcademicProfileCard/Levels";
import GradLawGraduation from "./AcademicProfileCard/GradLawGraduation";
import { UndergraduateGraduation } from "./AcademicProfileCard/UndergraduateGraduation";
import { GPAToggle } from "./AcademicProfileCard/GPAToggle";
import { CumulativeUnits } from "./AcademicProfileCard/CumulativeUnits";
import { Degrees } from "./AcademicProfileCard/Degrees";
import { LawAwards } from "./AcademicProfileCard/LawAwards";
import { Diploma } from "./AcademicProfileCard/Diploma.js";
import { dispatchFunction } from "../hooks/dispatchFunction";
import { dispatchUserSlice } from "../hooks/dispatchUserSlice";
import { useUser } from "components/hooks/useUser";
import { loadStateForSlices } from "utils/loadStateForSlices";

export function AcademicProfileCard() {
  dispatchFunction([
    fetchAcademicsDiploma,
    fetchTransferCredit,
    fetchLawAwards,
  ]);

  dispatchUserSlice([fetchUserProfile, fetchAcademicProfile, fetchEnrollments]);

  const {
    academics: { diploma } = {},
    myLawAwards,
    myStatus,
    userProfile,
  } = useSelector((state) => state);

  const { academicProfile, enrollments } = useUser((user) => user);

  const loadState = loadStateForSlices([
    diploma,
    myLawAwards,
    myStatus,
    userProfile,
    academicProfile,
    enrollments,
  ]);

  return (
    <Card data-testid="academic-profile-card">
      <CardTitle>
        <h2>Academic Profile</h2>
      </CardTitle>
      <CardBody>
        {loadState === "pending" && <Spinner />}
        {loadState === "success" && (
          <>
            <CollegeAndLevelError />
            <ConcurrentEnrollmentMessage />
            <StatusAsOf />
            <NameAndPhoto />

            <AcademicProfileList>
              <Major />
              <Minor />
              <Emphases />
              <Careers />
              <Levels />
              <UndergraduateGraduation showCheckListLink={true} />
              <GradLawGraduation />
              <CumulativeUnits />
              <GPAToggle />
              <Degrees />
              <LawAwards showLink={true} />
              <Diploma />
            </AcademicProfileList>
          </>
        )}
      </CardBody>
    </Card>
  );
}
AcademicProfileCard.displayName = "AcademicProfileCard";
