import React from "react";
import { useSlice } from "components/hooks/useSlice";
import { useRoles } from "components/hooks/useRoles";
import { ErrorBoundary } from "components/ui/ErrorBoundary";
import { SIRCardUI } from "./SIRCard/SIRCardUI";

export function SIRCard() {
  const { delegateActingAsUid } = useSlice();
  const { releasedAdmit } = useRoles();

  if (!delegateActingAsUid && releasedAdmit) {
    return (
      <ErrorBoundary title="SIR Card">
        <SIRCardUI />
      </ErrorBoundary>
    );
  }

  return null;
}
