import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import StyledMessage from "components/ui/StyledMessage";

function SummerEnrollmentMessage({ summerInfoMessage: { descrlong } }) {
  return (
    <StyledMessage data-testid="summer-enrollment-message">
      <p>
        <strong>About Summer Enrollment</strong>
      </p>
      <div dangerouslySetInnerHTML={{ __html: descrlong }} />
    </StyledMessage>
  );
}

SummerEnrollmentMessage.displayName = "SummerEnrollmentMessage";
SummerEnrollmentMessage.propTypes = {
  summerInfoMessage: PropTypes.shape({
    descrlong: PropTypes.string,
  }),
};

function mapStateToProps({ currentUID, users }) {
  const { enrollmentResources: { messages: { summerInfo } = {} } = {} } =
    users[currentUID] || {};

  return { summerInfoMessage: summerInfo };
}

export default connect(mapStateToProps)(SummerEnrollmentMessage);
