import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useWindowWidth } from "@react-hook/window-size";

import { BlueButtonLink } from "components/ui/ButtonLink";
import WithAPILink from "components/ui/WithAPILink";

import {
  UC_SR_SS_ENROLLMENT_CENTER,
  UC_SR_SS_ENROLLMENT_CENTER_SFF,
} from "functions/urlIds";

const SMALL_FORM_FACTOR_MAX_WIDTH = 519;
export default function EnrollmentCenterButton({ name: textOverride }) {
  const urlID =
    useWindowWidth() > SMALL_FORM_FACTOR_MAX_WIDTH
      ? UC_SR_SS_ENROLLMENT_CENTER
      : UC_SR_SS_ENROLLMENT_CENTER_SFF;

  return <WithAPILink urlID={urlID} as={CustomButton} text={textOverride} />;
}

EnrollmentCenterButton.displayName = "EnrollmentCenterButton";
EnrollmentCenterButton.propTypes = {
  name: PropTypes.string,
};

const CustomButton = styled(BlueButtonLink)`
  --button-font-size: 13px;
`;
