import React from "react";
import { react2angular } from "react2angular";
import { ReduxProvider } from "components/data/ReduxProvider";
import { TeachingButtonGroup } from "components/ui/TeachingButtonGroup";

const NgTeachingButtonGroup = () => (
  <ReduxProvider>
    <TeachingButtonGroup />
  </ReduxProvider>
);

angular
  .module("calcentral.react")
  .component("teachingButtonGroup", react2angular(NgTeachingButtonGroup));
