import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Card, CardBody, CardTitle } from "components/ui/Card";
import { Spinner } from "components/ui/Spinner";
import currentUserState from "utils/currentUserState";
import { fetchGradDegreeProgress } from "data/slices/users/gradDegreeProgress";
import PropTypes from "prop-types";
import styled from "styled-components";
import APILink from "components/ui/APILink";
import { UnstyledList } from "components/ui/Lists";
import { GreenCheck, YellowWarning, RedWarning } from "components/ui/Icons";

export default function GraduateDegreeProgressCard() {
  const dispatch = useDispatch();

  const {
    canViewGradDegreeProgressCard,
    academicProgressReportGrad,
    academicProgressReportLaw,
    degreeProgresses,
    currentUID,
    loadState,
    isAdvisor,
  } = useSelector((state) => {
    const {
      myStatus: {
        roles: {
          student: isStudent,
          releasedAdmit: isReleasedAdmit,
          advisor: isAdvisor,
        },
        delegateActingAsUid: isDelegateActingAsUid,
        hasStudentHistory: hasStudentHistory,
        features: { csDegreeProgressGradStudent: csDegreeProgressGradStudent },
        academicRoles: {
          historical: { degreeSeeking: degreeSeekingHistory },
        },
      },
      currentUID: currentUID,
    } = state || {};

    const {
      gradDegreeProgress: {
        loadState: loadState,
        feed: {
          degreeProgress: degreeProgresses = [],
          links: {
            academicProgressReportGrad: academicProgressReportGrad,
            academicProgressReportLaw: academicProgressReportLaw,
          } = {},
        } = {},
      },
    } = currentUserState(state) || {};

    const canViewGradDegreeProgressCard =
      csDegreeProgressGradStudent &&
      !isDelegateActingAsUid &&
      degreeSeekingHistory &&
      (isStudent || hasStudentHistory || isReleasedAdmit) &&
      (academicProgressReportGrad || academicProgressReportLaw);

    return {
      canViewGradDegreeProgressCard,
      academicProgressReportGrad,
      academicProgressReportLaw,
      degreeProgresses,
      currentUID,
      loadState,
      isAdvisor,
    };
  });

  useEffect(() => {
    if (currentUID) {
      dispatch(fetchGradDegreeProgress(currentUID));
    }
  }, [dispatch, currentUID]);

  if (canViewGradDegreeProgressCard) {
    return (
      <Card data-testid="grad-degree-progress-card">
        <CardTitle>
          <h2>Degree Progress</h2>
        </CardTitle>
        <CardBody>
          {loadState === "pending" && <Spinner />}

          {loadState === "failure" && (
            <p>There was an error retrieving graduate degree progress data.</p>
          )}

          {loadState === "success" && (
            <>
              {academicProgressReportLaw && (
                <APILink link={academicProgressReportLaw} />
              )}
              {academicProgressReportGrad && (
                <APILink link={academicProgressReportGrad} />
              )}
              {degreeProgresses.length === 0 &&
                (isAdvisor ? (
                  <p>
                    This student has not completed any Graduate Division
                    Milestones.
                  </p>
                ) : (
                  <p>
                    You have not completed any Graduate Division Milestones.
                    Please contact your department if you believe this is an
                    error.
                  </p>
                ))}
              <GraduateDegreeProgresses progresses={degreeProgresses} />
            </>
          )}
        </CardBody>
      </Card>
    );
  }
  return null;
}

function GraduateDegreeProgresses({ progresses }) {
  if (progresses.length) {
    return (
      <UnstyledList>
        {progresses.map((progress) => (
          <GradDegreeProgress key={progress.acadPlanCode} progress={progress} />
        ))}
      </UnstyledList>
    );
  }
  return null;
}

GraduateDegreeProgresses.displayName = "GraduateDegreeProgresses";
GraduateDegreeProgresses.propTypes = {
  progresses: PropTypes.array.isRequired,
};

function GradDegreeProgress({ progress }) {
  return (
    <li>
      <h3>
        {progress.acadPlan && <>{progress.acadPlan} &mdash;</>} Graduate
        Division Milestones
      </h3>

      <ProgressRequirements requirements={progress.requirements} />
    </li>
  );
}

GradDegreeProgress.displayName = "GradDegreeProgress";
GradDegreeProgress.propTypes = {
  progress: PropTypes.shape({
    acadPlan: PropTypes.string,
    requirements: PropTypes.array,
  }),
};

function ProgressRequirements({ requirements }) {
  const orderedRequirements = [...requirements].sort(
    (a, b) => a.orderNumber - b.orderNumber
  );

  return (
    <UnstyledList>
      {orderedRequirements.map((req) => (
        <Requirement key={req.code} requirement={req} />
      ))}
    </UnstyledList>
  );
}

ProgressRequirements.displayName = "ProgressRequirements";
ProgressRequirements.propTypes = {
  requirements: PropTypes.array,
};

function Requirement({
  requirement: {
    name,
    status,
    statusCode,
    formNotification,
    proposedExamDate,
    attempts,
    candidacyTermStatus,
    dateCompleted,
  },
}) {
  return (
    <RequirementStyles>
      <div className="media">
        <RequirementStatusIcon statusCode={statusCode} attempts={attempts} />
      </div>
      <div className="body">
        <h4>{name}</h4>

        {attempts.length === 0 && (
          <div>
            {status} {statusCode === "Y" && dateCompleted}
            {formNotification}
          </div>
        )}

        {proposedExamDate && <div>Proposed Exam Date: {proposedExamDate}</div>}

        <ul style={{ paddingLeft: `0` }}>
          {attempts.map((attempt, index) => (
            <li key={index}>{attempt.display}</li>
          ))}
        </ul>

        {candidacyTermStatus && (
          <div>
            <div>
              Candidacy End Term: {candidacyTermStatus.endTermDescription}
            </div>
            <div>Candidacy Status: {candidacyTermStatus.statusDescription}</div>
          </div>
        )}
      </div>
    </RequirementStyles>
  );
}

Requirement.displayName = "Requirement";
Requirement.propTypes = {
  requirement: PropTypes.shape({
    name: PropTypes.string,
    status: PropTypes.string,
    statusCode: PropTypes.string,
    formNotification: PropTypes.string,
    proposedExamDate: PropTypes.string,
    attempts: PropTypes.arrayOf(
      PropTypes.shape({
        display: PropTypes.string,
      })
    ),
    candidacyTermStatus: PropTypes.shape({
      endTermDescription: PropTypes.string,
      statusDescription: PropTypes.string,
    }),
    dateCompleted: PropTypes.string,
  }),
};

const RequirementStyles = styled.li`
  display: flex;
  margin-top: 5px;

  .media {
    width: 20px;
  }

  .body {
    h4 {
      margin: 0;
    }
  }
`;

function RequirementStatusIcon({ statusCode, attempts }) {
  if (statusCode === "Y" || statusCode === "P") {
    return <GreenCheck />;
  } else if (statusCode === "PF" || statusCode === "F") {
    if (attempts && attempts.length <= 1) {
      return <YellowWarning />;
    } else if (attempts && attempts.length > 1) {
      return <RedWarning />;
    } else {
      return null;
    }
  } else {
    return null;
  }
}

RequirementStatusIcon.displayName = "RequirementStatusIcon";
RequirementStatusIcon.propTypes = {
  statusCode: PropTypes.oneOf(["Y", "P", "PF", "F", "N"]),
  attempts: PropTypes.array,
};
