import React, { useEffect } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";

import { Card, CardBody, CardTitle } from "components/ui/Card";
import { Spinner } from "components/ui/Spinner";
import StyledMessage from "components/ui/StyledMessage";
import APILink from "components/ui/APILink";
import AccessibleLinkContent from "components/ui/AccessibleLinkContent";
import currentUserState from "utils/currentUserState";

import { fetchEnrollmentVerification } from "data/slices/users/enrollmentVerification";

export const enrollmentMessageEnums = {
  nonLawExclusive: "1",
  requestOfficial: "2",
  lawVerification: "3",
  lawExclusive: "5",
};

export function EnrollmentVerificationCard() {
  const dispatch = useDispatch();

  const {
    currentUID,
    isDirectlyAuthenticated,
    isExStudent,
    loadState,
    messages,
    requestURL,
    currentAcademicRoles,
  } = useSelector((state) => {
    const {
      myStatus: {
        isDirectlyAuthenticated,
        roles: { exStudent: isExStudent } = {},
        academicRoles: { current: currentAcademicRoles = {} } = {},
      } = {},
    } = state;

    const {
      currentUID,
      enrollmentVerification: { loadState, messages = [], requestURL } = {},
    } = currentUserState(state);

    return {
      currentUID,
      isDirectlyAuthenticated,
      isExStudent,
      loadState,
      messages,
      requestURL,
      currentAcademicRoles,
    };
  });

  const isLawExclusive = isRoleExclusive("law", currentAcademicRoles);
  const hasMessages = messages.length > 0;

  const viewOnline = isLawExclusive
    ? messages.find(
        (message) => message.messageNbr === enrollmentMessageEnums.lawExclusive
      )
    : messages.find(
        (message) =>
          message.messageNbr === enrollmentMessageEnums.nonLawExclusive
      );

  const requestOfficial = messages.find(
    (message) => message.messageNbr === enrollmentMessageEnums.requestOfficial
  );
  const lawVerification = messages.find(
    (message) => message.messageNbr === enrollmentMessageEnums.lawVerification
  );

  useEffect(() => {
    if (currentUID) {
      dispatch(fetchEnrollmentVerification(currentUID));
    }
  }, [dispatch, currentUID]);

  return (
    <Card data-testid="enrollment-verification-card">
      <CardTitle>
        <h2>
          {isLawExclusive
            ? "Law Enrollment Verification"
            : "Select Enrollment Verification Method"}
        </h2>
      </CardTitle>
      <CardBody>
        <EnrollmentVerificationCardStyles>
          {loadState === "pending" && <Spinner />}
          {loadState === "success" && hasMessages && (
            <>
              <SeparatedByRules>
                <h3>{viewOnline.messageText}</h3>

                <StyledMessage>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: viewOnline.descrlong,
                    }}
                  ></div>
                </StyledMessage>

                {isDirectlyAuthenticated ? (
                  <p>
                    <strong>
                      <a
                        href="/clearing_house/clearing_house_url"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <AccessibleLinkContent showNewWindow={true}>
                          View or Print Enrollment via Self Service
                        </AccessibleLinkContent>
                      </a>
                    </strong>
                  </p>
                ) : (
                  <p style={{ color: `var(--dove-gray)` }}>
                    <strong>View or Print Enrollment via Self Service</strong>
                    <i> (Functionality is not available in view-as mode.)</i>
                  </p>
                )}
              </SeparatedByRules>

              {(currentAcademicRoles.law || isExStudent) && (
                <SeparatedByRules>
                  <h3>{lawVerification.messageText}</h3>
                  <StyledMessage>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: lawVerification.descrlong,
                      }}
                    ></div>
                  </StyledMessage>
                </SeparatedByRules>
              )}

              {!isLawExclusive && (
                <>
                  <SeparatedByRules>
                    <h3>{requestOfficial.messageText}</h3>
                    <StyledMessage>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: requestOfficial.descrlong,
                        }}
                      ></div>
                    </StyledMessage>
                    {isDirectlyAuthenticated ? (
                      <p>
                        <strong>
                          <APILink link={requestURL} />
                        </strong>
                      </p>
                    ) : (
                      <p style={{ color: `var(--dove-gray)` }}>
                        <strong>Request Official Verification</strong>{" "}
                        <i>(Functionality is not available in view-as mode.)</i>
                      </p>
                    )}
                  </SeparatedByRules>
                  <SeparatedByRules>
                    <>
                      Learn more about{" "}
                      <a
                        href="http://registrar.berkeley.edu/academic-records/verification-enrollment-degrees"
                        title="Learn more about enrollment verification services"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <AccessibleLinkContent showNewWindow={true}>
                          enrollment verification services.
                        </AccessibleLinkContent>
                      </a>
                    </>
                  </SeparatedByRules>
                </>
              )}
            </>
          )}

          {((loadState === "success" && !hasMessages) ||
            loadState === "failure") && (
            <p>
              There was a problem reaching campus services. Please try again
              later.
            </p>
          )}
        </EnrollmentVerificationCardStyles>
      </CardBody>
    </Card>
  );
}
EnrollmentVerificationCard.displayName = "EnrollmentVerificationCard";

const EnrollmentVerificationCardStyles = styled.div`
  p {
    margin: var(--md) 0;
  }
`;

const SeparatedByRules = styled.div`
  padding-top: 0;

  & + & {
    padding-top: var(--lg);
    border-top: 1px solid var(--gallery);
  }
`;

const isRoleExclusive = function (roleKey, roles) {
  if (!_.get(roles, roleKey)) {
    return false;
  }

  return !_.find(roles, function (roleValue, currentRoleKey) {
    return currentRoleKey !== roleKey && roleValue;
  });
};
