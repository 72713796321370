import React from "react";
import PropTypes from "prop-types";
export { Disclosure, DisclosurePanel } from "@reach/disclosure";
import { DisclosureButton as ReachDisclosureButton } from "@reach/disclosure";
import { ButtonAsLinkStyles } from "./ButtonAsLink";
import styled from "styled-components";
import chevronDouble from "icons/chevron-double.svg";

export const DisclosureButton = styled(ReachDisclosureButton)`
  width: 100%;
  text-align: left;
  padding-left: 0;

  padding-top: var(--md);
  padding-bottom: var(--md);

  border: none;
  background: transparent;
  cursor: pointer;
  color: var(--base-color);
`;
DisclosureButton.displayName = "DisclosureButton";

const DoubleChevronStyles = styled(ReachDisclosureButton)`
  padding: 0;

  border-width: 0;
  display: flex;
  width: 20px;
  height: 20px;
  border-radius: 10px;

  &:hover {
    background-color: var(--link-water);
  }

  background: url("${chevronDouble}") center center / auto no-repeat;

  &[data-state="open"] {
    transform: scaleY(-1);
  }
`;

export const DoubleChevronDisclosureButton = ({ children, setHoverActive }) => {
  return (
    <DoubleChevronStyles
      onFocus={() => setHoverActive("true")}
      onBlur={() => setHoverActive("false")}
      onMouseEnter={() => setHoverActive("true")}
      onMouseLeave={() => setHoverActive("false")}
    >
      {children}
    </DoubleChevronStyles>
  );
};

DoubleChevronDisclosureButton.propTypes = {
  children: PropTypes.node,
  setHoverActive: PropTypes.func,
};

export const TextDisclosureButton = styled(DisclosureButton)`
  ${ButtonAsLinkStyles}

  width: auto;
`;
TextDisclosureButton.displayName = "TextDisclosureButton";
