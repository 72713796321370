import React from "react";
import PropTypes from "prop-types";
import { CCTable } from "components/ui/Table";

export default function SummerDeadlinesTable({ children }) {
  return (
    <CCTable>
      <thead>
        <tr>
          <th width="44">Law Add/Drop Deadlines</th>
          <th width="23%"></th>
          <th width="33%"></th>
        </tr>
      </thead>
      <tbody>{children}</tbody>
    </CCTable>
  );
}

SummerDeadlinesTable.displayName = "SummerDeadlinesTable";
SummerDeadlinesTable.propTypes = {
  children: PropTypes.node.isRequired,
};
