import React from "react";
import PropTypes from "prop-types";

export const classLabelDataCell = {
  padding: "12px 0px 2px",
  width: "110px",
};

const LinkIfPresentAndNotAdvisor = ({ children, url, isAdvisingView }) => {
  if (isAdvisingView) {
    return children;
  }

  if (url) {
    return <a href={url}>{children}</a>;
  }

  return children;
};

LinkIfPresentAndNotAdvisor.displayName = "LinkIfPresentAndNotAdvisor";
LinkIfPresentAndNotAdvisor.propTypes = {
  children: PropTypes.node,
  url: PropTypes.string,
  isAdvisingView: PropTypes.bool,
};

const ClassLabel = ({ isAdvisingView, semesterClass, section }) => {
  const { url: sectionUrl = null, section_label: sectionLabel = null } =
    section;

  const {
    multiplePrimaries = false,
    course_code: courseCode = null,
    session_description: sessionDescription = null,
    url: classUrl = null,
  } = semesterClass;

  const courseAndSectionLabel = multiplePrimaries
    ? `${courseCode} ${sectionLabel}`
    : courseCode;

  const url = multiplePrimaries ? sectionUrl : classUrl;

  return (
    <td style={classLabelDataCell}>
      <LinkIfPresentAndNotAdvisor url={url} isAdvisingView={isAdvisingView}>
        {courseAndSectionLabel}
        {sessionDescription && (
          <span className="cc-nowrap"> ({sessionDescription})</span>
        )}
      </LinkIfPresentAndNotAdvisor>
    </td>
  );
};

ClassLabel.displayName = "ClassLabel";
ClassLabel.propTypes = {
  isAdvisingView: PropTypes.bool,
  semesterClass: PropTypes.object,
  section: PropTypes.object,
};

export default ClassLabel;
