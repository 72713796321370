import React from "react";
import { useAdvisingStudent } from "components/hooks/useAdvisingStudent";

export const Minors = () => {
  const { minors = [] } = useAdvisingStudent(
    (student) => student.advisingStudentAcademicProfile || {}
  );

  if (minors && minors.length > 0) {
    return (
      <>
        <dt>{minors.length === 1 ? "Minor" : "Minors"}</dt>
        <dd>
          {minors.map((minor, index) => (
            <div key={index}>
              <div style={{ color: "var(--dusty-gray)" }}>{minor.college}</div>
              <div>{minor.description}</div>
              {minor.subPlans.map((subPlan, index) => (
                <div key={index}>{subPlan}</div>
              ))}
            </div>
          ))}
        </dd>
      </>
    );
  }
  return null;
};
Minors.displayName = "Minors";
