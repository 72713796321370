import styled from "styled-components";

export const HairlineRule = styled.hr`
  margin-top: var(--large-spacing);
  margin-bottom: var(--large-spacing);
  border: 0;
  border-bottom: 1px solid var(--gallery);
`;
HairlineRule.displayName = "HairlineRule";
export default HairlineRule;
