import React from "react";
import PropTypes from "prop-types";
import { CardBody } from "components/ui/Card";
import { UnstyledList } from "components/ui/Lists";
import { TriangleIcon } from "./TriangleIcon";
import styled from "styled-components";
import AccessibleLinkContent from "components/ui/AccessibleLinkContent";
import {
  semesterHasMidpointClass,
  semesterHasLawClass,
} from "utils/teachingSemester";

export function GradingStatusLegend({ semester }) {
  const hasLawClass = semesterHasLawClass(semester);
  const hasMidpointClass = semesterHasMidpointClass(semester);

  return (
    <CardBody>
      <h3>Grading Status Legend</h3>
      <LegendList>
        <li>
          <LegendIcon name="ban" />
          <div>
            Grade entry period not open
            {hasLawClass && (
              <>
                <strong>Law classes:</strong> Instructor may need to submit raw
                scores.
              </>
            )}
          </div>
        </li>
        <li>
          <LegendIcon name="circle" />
          <div>
            {hasMidpointClass ? (
              <>
                Law class (no midpoint grades) <strong>OR</strong> you do not
                have grading permissions
              </>
            ) : (
              <>You do not have grading permissions</>
            )}
          </div>
        </li>
        <li>
          <LegendIcon name="file-o" />
          <div>Grade entry period open; grades not approved or posted</div>
        </li>
        <li>
          <LegendIcon name="check" style={{ color: `var(--jewel)` }} />
          <div>Grades approved</div>
        </li>
        <li>
          <LegendIcon name="check-circle" style={{ color: `var(--jewel)` }} />
          <div>
            Grades posted <strong>Note: </strong>All classes, with or without
            midpoint grades, will post.
          </div>
        </li>
        <li>
          <LegendIcon
            name="exclamation-circle"
            style={{ color: `var(--thunderbird)` }}
          />
          <div>Final grade entry period closed; grades overdue</div>
        </li>
        <li>
          <span>
            <TriangleIcon />
          </span>
          <div>
            Incomplete Grades{" "}
            <a
              href="https://registrar.berkeley.edu/faculty-staff/grading/incomplete-grades/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <AccessibleLinkContent showNewWindow={true}>
                Learn More
              </AccessibleLinkContent>
            </a>
          </div>
        </li>
      </LegendList>
    </CardBody>
  );
}

const LegendList = styled(UnstyledList)`
  li {
    display: flex;
    gap: 5px;
    align-items: center;

    > :first-child {
      width: 15px;
    }
  }
`;

GradingStatusLegend.propTypes = {
  semester: PropTypes.object,
};

function LegendIcon({ name, style = {} }) {
  return (
    <i className={`fa fa-${name}`} aria-hidden={true} style={{ ...style }}></i>
  );
}

LegendIcon.propTypes = {
  name: PropTypes.string,
  style: PropTypes.object,
};
