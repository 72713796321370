import React, { useEffect, useState } from "react";

export function PayDepositButton() {
  // The Higher One URL expires after 5 minutes, so we refresh it every 4.5 minutes
  const refreshInterval = 4.5 * 60 * 1000;

  const [url, setURL] = useState("");

  const refreshURL = async () => {
    const res = await fetch(
      "/api/campus_solutions/higher_one_url?refreshCache=true"
    );

    if (res.ok) {
      const data = await res.json();
      const url = data?.feed?.root?.higherOneURL?.url;
      setURL(url);
    }
  };

  useEffect(() => {
    refreshURL();
    const interval = setInterval(refreshURL, refreshInterval);
    return () => clearInterval(interval);
  }, [refreshInterval, refreshURL]);

  return (
    <a
      href={url}
      className="cc-button cc-button-blue"
      target="_blank"
      rel="noreferrer"
    >
      Pay Deposit
    </a>
  );
}
