import React from "react";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { connect } from "react-redux";

import DefaultEnrollmentCard from "./EnrollmentCards/DefaultEnrollmentCard";
import LawEnrollmentCard from "./EnrollmentCards/LawEnrollmentCard";
import ConcurrentEnrollmentCard from "./EnrollmentCards/ConcurrentEnrollmentCard";

import { fetchEnrollmentResources } from "data/actions/academics/enrollmentResourcesActions";
import MBAEnrollmentCard from "./EnrollmentCards/MBAEnrollmentCard";

function groupPresentationsByDesign(presentations) {
  return presentations.reduce(
    (acc, current) => {
      acc[current.design] = acc[current.design]
        ? [...acc[current.design], current]
        : [current];
      return acc;
    },
    {
      haasEveningWeekendMba: [],
      haasFullTimeMba: [],
      haasExecMba: [],
    }
  );
}

export function EnrollmentCards({
  fetchEnrollmentResources,
  enrollmentResources,
  uid,
}) {
  useEffect(() => {
    fetchEnrollmentResources(uid);
  }, [uid]);

  const { presentations = [], termInstructions = [] } =
    enrollmentResources || {};
  const presentationsByDesign = groupPresentationsByDesign(presentations);

  const mbaPresentations = [
    ...presentationsByDesign.haasEveningWeekendMba,
    ...presentationsByDesign.haasFullTimeMba,
    ...presentationsByDesign.haasExecMba,
  ];

  return (
    <>
      {presentationsByDesign.default && (
        <DefaultEnrollmentCard
          presentations={presentationsByDesign.default}
          termInstructions={termInstructions}
        />
      )}

      {presentationsByDesign.law && (
        <LawEnrollmentCard
          presentations={presentationsByDesign.law}
          termInstructions={termInstructions}
        />
      )}

      {presentationsByDesign.concurrent && (
        <ConcurrentEnrollmentCard
          presentations={presentationsByDesign.concurrent}
          termInstructions={termInstructions}
        />
      )}

      {mbaPresentations.length > 0 && (
        <MBAEnrollmentCard
          presentations={mbaPresentations}
          termInstructions={termInstructions}
        />
      )}
    </>
  );
}

const presentationPropShape = PropTypes.shape({
  termId: PropTypes.string,
  academicCareerCode: PropTypes.string,
});

const termInstructionPropShape = PropTypes.shape({});

EnrollmentCards.displayName = "EnrollmentCards";
EnrollmentCards.propTypes = {
  uid: PropTypes.string,
  fetchEnrollmentResources: PropTypes.func.isRequired,
  enrollmentResources: PropTypes.shape({
    presentations: PropTypes.arrayOf(presentationPropShape),
    termInstructions: PropTypes.arrayOf(termInstructionPropShape),
  }),
};
// Setting default data fetching to no-op makes testing an unconnected component
// easier to work with allowing the 'enrollmentResources' data property to be
// set directly.
EnrollmentCards.defaultProps = {
  fetchEnrollmentResources: () => {},
  uid: "",
};

function mapStateToProps({ currentUID, users }) {
  const { enrollmentResources = {} } = users[currentUID] || {};
  return { uid: currentUID, enrollmentResources };
}

const mapDispatchToProps = (dispatch) => ({
  fetchEnrollmentResources: (uid) => {
    dispatch(fetchEnrollmentResources(uid));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EnrollmentCards);
