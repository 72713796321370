import React from "react";
import { react2angular } from "react2angular";
import ReduxProvider from "components/ReduxProvider";
import AcademicAdvisingCard from "components/cards/AcademicAdvisingCard";


const NgAcademicAdvisingCard = () => (
  <ReduxProvider>
    <AcademicAdvisingCard />
  </ReduxProvider>
);

angular
  .module("calcentral.react")
  .component("academicAdvisingCard", react2angular(NgAcademicAdvisingCard));
