import PropTypes from "prop-types";
import { useSelector } from "react-redux";

export function RequireDelegate({ children }) {
  const isDelegateUser = useSelector(({ myStatus: { isDelegateUser } }) => {
    return isDelegateUser;
  });

  if (isDelegateUser) {
    return children;
  }

  return null;
}

RequireDelegate.displayName = "RequireDelegate";
RequireDelegate.propTypes = {
  children: PropTypes.node,
};
