import React from "react";
import { Link, useParams } from "react-router-dom";

import { fetchCampusLinks } from "data/slices/users/campusLinks";
import { dispatchUserSlice } from "components/hooks/dispatchUserSlice";

import { Sidebar, NavigationGroupItem } from "components/ui/Sidebar";
import { UnstyledList } from "components/ui/Lists";
import { useFilteredNav } from "./useFilteredNav";

export function CampusSidebar() {
  dispatchUserSlice(fetchCampusLinks);

  const { categoryId } = useParams();
  const navigation = useFilteredNav();

  return (
    <Sidebar>
      <UnstyledList>
        {navigation.map((item) => (
          <NavigationGroupItem key={item.label}>
            <h3>{item.label}</h3>

            <UnstyledList style={{ marginTop: `10px` }}>
              {item.categories.map((category) => (
                <li key={category.name}>
                  <Link
                    to={`/campus/${category.id}`}
                    className={categoryId === category.id ? "active" : ""}
                    aria-current={categoryId === category.id ? "page" : ""}
                  >
                    {category.name}
                  </Link>
                </li>
              ))}
            </UnstyledList>
          </NavigationGroupItem>
        ))}
      </UnstyledList>
    </Sidebar>
  );
}
