import React, { useState } from "react";
import styled from "styled-components";
import { Card, CardBody, CardTitle } from "components/ui/Card";
import Table from "components/ui/Table";
import UIDLookupForm from "components/UIDLookupForm";
import { useSelector } from "react-redux";

const ResultsTable = styled(Table)`
  margin-top: 20px;

  tbody tr td,
  thead tr th {
    border-bottom: 1px solid var(--gallery);
    font-size: 12px;
    padding: 4px;
  }

  thead tr th {
    text-align: left;
  }
`;

export default function IDLookupCard() {
  const [users, setUsers] = useState([]);
  const hasAccess = useSelector(
    ({ myStatus: { isViewer, isSuperuser, roles: { advisor } = {} } }) => {
      return isViewer || isSuperuser || advisor;
    }
  );

  if (hasAccess) {
    return (
      <Card data-testid="id-lookup-card">
        <CardTitle>
          <h2>UID/SID Lookup</h2>
        </CardTitle>
        <CardBody>
          <UIDLookupForm
            setUsers={setUsers}
            buttonLabel="Look Up"
            includeCampusDir={false}
          />

          {users.length > 0 && (
            <ResultsTable>
              <thead>
                <tr>
                  <th width="50%" scope="col">
                    UID
                  </th>
                  <th width="50%" scope="col">
                    SID
                  </th>
                </tr>
              </thead>
              {users.map((user, userIndex) => (
                <tbody key={userIndex}>
                  <tr>
                    <td>{user.ldapUid}</td>
                    <td>{user.studentId || "This user has no SID"}</td>
                  </tr>
                </tbody>
              ))}
            </ResultsTable>
          )}
        </CardBody>
      </Card>
    );
  }

  return null;
}
IDLookupCard.displayName = "IDLookupCard";
