import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Card, CardBody, CardTitle } from "components/ui/Card";
import Spinner from "components/ui/Spinner";
import { DelegateStudent } from "./DelegateStudentsCard/DelegateStudent";
import { hasNoPrivileges, hasPhoneOnly } from "utils/delegates";
import HairlineRule from "../ui/HairlineRule";

export function DelegateStudentsCard() {
  const [loadState, setLoadState] = useState("pending");
  const [students, setStudents] = useState([]);

  const { currentUID, isDelegateUser } = useSelector(
    ({ myStatus: { isDelegateUser }, currentUID }) => ({
      currentUID,
      isDelegateUser,
    })
  );

  useEffect(() => {
    fetch("/api/campus_solutions/delegate_access/students")
      .then((res) => res.json())
      .then((data) => {
        const { feed: { students: responseStudents = [] } = {} } = data;
        setStudents(responseStudents);
        setLoadState("success");
      });
  }, [isDelegateUser]);

  const noPrivilegesMessage = students.some(hasNoPrivileges);
  const phonePrivilegesMessage = students.some(hasPhoneOnly);
  const accountIssueMessage = students.some((student) => !student.uid);
  const selfDelegateMessage = students.some((s) => s.uid === currentUID);

  if (isDelegateUser) {
    return (
      <Card data-testid="delegate-student-list-card">
        <CardTitle>
          <h2>Students</h2>
        </CardTitle>
        <CardBody>
          {loadState === "pending" && <Spinner />}
          {loadState === "success" && (
            <>
              {students.length > 0 && (
                <>
                  <DelegateStudentListStyles>
                    {students.map((student) => (
                      <DelegateStudent key={student.uid} student={student} />
                    ))}
                  </DelegateStudentListStyles>
                  <HairlineRule />
                </>
              )}

              {phonePrivilegesMessage && (
                <p>
                  <strong>By Phone and In-Person Only:</strong> The indicated
                  student(s) have granted you phone and in-person privileges
                  only. You have no online privileges.
                </p>
              )}

              {noPrivilegesMessage && (
                <p>
                  <strong>No privileges:</strong> The indicated student(s) have
                  not granted you any privileges.
                </p>
              )}

              {accountIssueMessage && (
                <p>
                  <strong>Account Issue:</strong> There is a technical issue
                  with the account for the indicated student(s). It will be
                  resolved as soon as possible. If you have questions, please
                  contact{" "}
                  <a href="https://www.ets.berkeley.edu/calcentral-support">
                    CalCentral Support
                  </a>
                  .
                </p>
              )}

              {selfDelegateMessage && (
                <p>
                  <strong>Cannot Delegate for Self:</strong> A technical issue
                  has caused you to be linked as a delegate to your own account.
                  Please check your delegate settings at{" "}
                  <a href="/profile/delegate">Manage Delegates</a>. If you have
                  questions, please contact{" "}
                  <a href="https://www.ets.berkeley.edu/calcentral-support">
                    CalCentral Support
                  </a>
                  .
                </p>
              )}

              {students.length !== 0 && (
                <p>
                  <strong>Adding other students:</strong> Are you a delegate for
                  other students but don&apos;t see them listed above? To add
                  them, please follow the instructions in the email you received
                  for each of them.
                </p>
              )}

              {students.length === 0 && (
                <div>
                  <strong>
                    You are not currently a delegate for any students.
                  </strong>{" "}
                  If you have been a delegate in the past, your student may have
                  changed your permissions.
                </div>
              )}
            </>
          )}
        </CardBody>
      </Card>
    );
  }

  return null;
}

DelegateStudentsCard.displayName = "DelegateStudentsCard";

const DelegateStudentListStyles = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 14px;
  align-content: center;
`;
