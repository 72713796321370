import React from "react";
import { Status } from "./Status";
import { fetchSirStatus } from "data/actions/sirStatusActions";
import { dispatchSlice } from "components/hooks/dispatchSlice";
import { useSlice } from "components/hooks/useSlice";

export function SIRCardUI() {
  dispatchSlice(fetchSirStatus);

  const { sirStatuses = [] } = useSlice("sirStatus");

  return sirStatuses.map((status, index) => (
    <Status key={index} status={status} />
  ));
}
