import React from "react";
import { react2angular } from "react2angular";
import { ReduxProvider } from "components/data/ReduxProvider";
import { SectionOverviewCard } from "../cards/SectionOverviewCard";

function NgSectionOverviewCard() {
  return (
    <ReduxProvider>
      <SectionOverviewCard />
    </ReduxProvider>
  );
}

angular
  .module("calcentral.react")
  .component("sectionOverviewCard", react2angular(NgSectionOverviewCard));
