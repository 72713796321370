import React, { useState } from "react";
import { StatusReceived } from "./StatusReceived";
import { StatusInitiated } from "./StatusInitiated";
import { StatusCompleted } from "./StatusCompleted";
import {
  STATUS_INITIATED,
  STATUS_COMPLETE,
  STATUS_RECEIVED,
  statusProps,
} from "./statusProps";

export function Status({ status }) {
  // Used to carry state in-memory state from the StatusInitiated ResponseForm
  // to StatusCompleted, as the sir_statuses API doesn't include the
  // appropriate information immediately upon refresh.
  const [messageObj, setMessageObj] = useState(false);

  const {
    itemStatusCode,
    isUndergraduate,
    deposit: { required: depositRequired } = {},
  } = status;

  if (itemStatusCode === STATUS_INITIATED) {
    return <StatusInitiated status={status} setMessageObj={setMessageObj} />;
  }

  if (itemStatusCode === STATUS_COMPLETE) {
    return <StatusCompleted status={status} messageObj={messageObj} />;
  }

  if (itemStatusCode === STATUS_RECEIVED) {
    if (isUndergraduate && !depositRequired) {
      return <StatusCompleted status={status} messageObj={messageObj} />;
    } else {
      return <StatusReceived status={status} />;
    }
  }

  return null;
}

Status.propTypes = statusProps;
