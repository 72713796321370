import React from "react";
import { presentationShape } from "./propTypes";
import StyledNotice from "components/ui/StyledNotice";
import ReadMore from "components/ui/ReadMore";

export default function EnrollmentNotice({
  presentation: { semesterMessage: { description } = {} },
}) {
  if (description) {
    return (
      <StyledNotice background="yellow" icon="bullhorn">
        <ReadMore html={description} />
      </StyledNotice>
    );
  }

  return null;
}

EnrollmentNotice.displayName = "EnrollmentNotice";
EnrollmentNotice.propTypes = {
  presentation: presentationShape,
};
