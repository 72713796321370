import React from "react";
import PropTypes from "prop-types";
import VisuallyHidden from "components/ui/VisuallyHidden";
import { InlineSelect } from "components/ui/Select";
import { BaseButtonLink, BaseButtonLinkDisabled } from "components/ui/ButtonLink";
import { DownloadIcon } from "components/ui/Icons";
import { useReact } from "components/hooks/useReact";

const inlineSelectStyle = {
  height: "26px",
  width: "200px",
};

export const StudentListOptions = function ({
  courseId,
  enrollmentStatus,
  sectionOptions,
  selectedSectionId,
  shownStudentCount,
  updateSelectedSection,
}) {
  const exportUrl =
    "/api/academics/rosters/campus/csv/" +
    courseId +
    ".csv?section_id=" +
    selectedSectionId +
    "&enroll_option=" +
    enrollmentStatus;
  const exportDisabled = shownStudentCount === 0;

  return (
    <div>
      <ExportButton disabled={exportDisabled} exportUrl={exportUrl} />
      <VisuallyHidden>
        <label htmlFor="section-select">Filter by Section</label>
      </VisuallyHidden>

      <InlineSelect
        className="value"
        value={selectedSectionId}
        onChange={(e) => updateSelectedSection(e.target.value)}
        style={inlineSelectStyle}
      >
        {sectionOptions &&
          sectionOptions.map((sectionOption, sectionOptionIndex) => (
            <option value={sectionOption.ccn} key={sectionOptionIndex}>
              {sectionOption.name}
            </option>
          ))}
      </InlineSelect>
    </div>
  );
};

StudentListOptions.displayName = "StudentListOptions";
StudentListOptions.propTypes = {
  courseId: PropTypes.string,
  enrollmentStatus: PropTypes.string,
  sectionOptions: PropTypes.arrayOf(PropTypes.object),
  selectedSectionId: PropTypes.string,
  shownStudentCount: PropTypes.number,
  updateSelectedSection: PropTypes.func,
};

const ExportButton = ({ disabled, exportUrl }) => {
  const isReact = useReact();
  const ButtonComponent = disabled ? BaseButtonLinkDisabled : BaseButtonLink;

  if (isReact) {
    return (
      <ButtonComponent
        href={exportUrl}
        style={{ marginRight: "var(--small-spacing)" }}
      >
        <DownloadIcon />
        Export
      </ButtonComponent>
    );
  }

  return (
    <ButtonComponent
      disabled={disabled}
      style={{ marginRight: "var(--small-spacing)" }}
      onClick={() => (window.location.href = exportUrl)}
    >
      <DownloadIcon />
      Export
    </ButtonComponent>
  );
};

ExportButton.propTypes = {
  disabled: PropTypes.bool,
  exportUrl: PropTypes.string,
};
