import { useEffect } from "react";
import { dispatchUserSlice } from "components/hooks/dispatchUserSlice";
import { fetchCampusLinks } from "data/slices/users/campusLinks";
import { useTopCategory } from "./useTopCategory";
import { useNavigate, useParams } from "react-router-dom";

export function CampusRedirect() {
  dispatchUserSlice(fetchCampusLinks);

  const { categoryId } = useParams();
  const navigate = useNavigate();
  const category = useTopCategory();

  useEffect(() => {
    if (!categoryId && category) {
      navigate(`/campus/${category.id}`);
    }

    if (categoryId && !category) {
      navigate(`/campus`);
    }
  }, [navigate, categoryId, category]);

  return null;
}
