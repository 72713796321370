import styled from "styled-components";
export { Tabs, TabPanels, TabPanel, TabList } from "@reach/tabs";
import { Tab as BaseTab } from "@reach/tabs";
import "@reach/tabs/styles.css";

export const Tab = styled(BaseTab)`
  flex: 1;

  line-height: 42px;
  background-color: var(--white);
  font-weight: bold;
  padding: 0;

  color: var(--dove-gray);
  border-bottom: 2px solid var(--gallery);

  // TODO remove this when default button style text-decoration is removed.
  &:focus,
  &:hover {
    text-decoration: none;
  }

  &[data-selected] {
    color: var(--denim);
    border-bottom: 2px solid var(--denim);
  }
`;
Tab.displayName = "Tab";
