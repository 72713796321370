import styled from "styled-components";

const SimpleList = styled.ul`
  margin: 0;
  padding: 0;

  li {
    margin-left: 1rem;
  }
`;

export default SimpleList;
