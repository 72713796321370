import { useSelector } from "react-redux";
import { useCourseParams } from "./useCourseParams";

export function useCourseData() {
  const { classId } = useCourseParams();

  return useSelector((state) => {
    return state.byCourse[classId] || {};
  });
}
