import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { fetchAcademicProfile } from "data/slices/users/academicProfile";

import { PrimarySection } from "components/cards/AcademicSummaryCard/PrimarySection";
import { useUser } from "components/hooks/useUser";
import { dispatchUserSlice } from "components/hooks/dispatchUserSlice";
import { useSlice } from "components/hooks/useSlice";
import { useRoles } from "components/hooks/useRoles";
import { AcademicSummaryTable } from "./AcademicSummaryTable";
import { LawUnitsFooter } from "./LawUnitsFooter";

const sum = (accumulator, value) => accumulator + value;

const summarizeUnits = (transferCredit, termId) => {
  if (transferCredit && transferCredit.law && transferCredit.law.detailed) {
    const units = transferCredit.law.detailed
      .map((transfer) => {
        if (transfer.termId === termId) {
          return transfer.units;
        } else {
          return 0;
        }
      })
      .reduce(sum);

    const lawUnits = transferCredit.law.detailed
      .map((transfer) => {
        if (transfer.termId === termId) {
          return transfer.lawUnits;
        } else {
          return 0;
        }
      })
      .reduce(sum);

    return { units, lawUnits };
  }

  return { units: 0, lawUnits: 0 };
};

const primarySectionsFromClasses = (classes) =>
  classes
    .map((klass) => {
      const primary = klass.sections.flat().find((section) => {
        return section.is_primary_section && !section.waitlisted;
      });

      if (primary) {
        return { ...primary, class: klass };
      }
    })
    .filter((e) => e !== undefined);

export const SemesterSections = ({
  semester: {
    totalLawUnits,
    classes,
    termId,
    isGradingComplete,
    showUnitsTotal,
    totalUnits,
  } = {},
}) => {
  dispatchUserSlice(fetchAcademicProfile);

  const transferCredit = useSlice("myTransferCredit");
  const isLawStudent = useRoles((roles) => roles.law);
  const academicProfile = useUser((user) => user.academicProfile);

  const {
    units: {
      totalLawUnits: totalCurrentLawUnits,
      totalPreviousCareerLawUnits,
    } = {},
  } = academicProfile || {};

  const { units, lawUnits } = summarizeUnits(transferCredit, termId);
  const primarySections = primarySectionsFromClasses(classes);
  const hasLawUnits =
    totalCurrentLawUnits > 0 || totalPreviousCareerLawUnits > 0;

  return (
    <SemesterSectionsTable data-testid="academic-summary-semester-sections-table">
      <thead>
        <tr>
          <th>Class</th>
          <th>Title</th>
          <th className="text-right">Un.</th>
          {hasLawUnits && <th className="text-right">Law Un.</th>}
          <th>Gr.</th>
          <th>{!hasLawUnits && <>Pts.</>}</th>
        </tr>
      </thead>
      <tbody>
        {primarySections.map((section, index) => (
          <PrimarySection
            key={index}
            showPoints={!hasLawUnits}
            section={section}
            isLawStudent={isLawStudent}
          />
        ))}
      </tbody>

      {hasLawUnits && (
        <LawUnitsFooter
          units={units}
          lawUnits={lawUnits}
          totalLawUnits={totalLawUnits}
          isGradingComplete={isGradingComplete}
          showUnitsTotal={showUnitsTotal}
          totalUnits={totalUnits}
        />
      )}
    </SemesterSectionsTable>
  );
};

SemesterSections.displayName = "SemesterSections";
SemesterSections.propTypes = {
  semester: PropTypes.object.isRequired,
};

const SemesterSectionsTable = styled(AcademicSummaryTable)`
  thead tr th {
    &:nth-child(1) {
      width: 20%;
    }
    &:nth-child(2) {
      width: 45%;
    }
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(6) {
      width: 10%;
    }
    &:nth-child(5) {
      width: 5%;
    }
  }

  tfoot {
    background: none;
    tr td {
      font-weight: normal;
    }
  }
`;
