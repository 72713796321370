import { useState } from "react";

export function useSortable(initialColumn, initialDirection) {
  const [column, setColumn] = useState(initialColumn);
  const [direction, setDirection] = useState(initialDirection);

  return {
    column,
    direction,
    setColumn,
    setDirection,
  };
}
