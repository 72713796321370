export function loadStateForSlices(slices = []) {
  if (slices.every((slice) => slice?.loadState === "success")) {
    return "success";
  } else if (slices.find((slice) => slice?.loadState === "error")) {
    return "error";
  } else if (
    slices.find(
      (slice) => slice?.loadState === "pending" || slice?.loadState === "idle"
    )
  ) {
    return "pending";
  } else {
    return "unknown";
  }
}
