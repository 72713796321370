import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Card, CardBody, CardTitle } from "components/ui/Card";
import Spinner from "components/ui/Spinner";
import { ErrorMessage } from "components/ui/ErrorMessage";

import Semester from "./SemestersCard/Semester";
import { TermLimiter } from "./SemestersCard/TermLimiter";
import { getMyStatus } from "data/slices/myStatus";
import { fetchMyAcademics } from "data/actions/myAcademicsActions";
import ViewAcademicSummaryLink from "./SemestersCard/ViewAcademicSummaryLink";
import { dispatchSlice } from "components/hooks/dispatchSlice";
import { useToggleState } from "components/hooks/useToggleState";

export default function SemestersCard({ isAdvisingView }) {
  dispatchSlice(getMyStatus);
  dispatchSlice(fetchMyAcademics);

  const [termsExpanded, toggleTermsExpanded] = useToggleState(false);

  const { error, loadState, pastSemestersLimit, semesters, hasStudentHistory } =
    useSelector((state) => {
      const {
        myAcademics: {
          isLoading: myAcademicsLoading = true,
          error: myAcademicsError = null,
          loadState,
          semesters = [],
          pastSemestersLimit = 0,
        } = {},
        myStatus: {
          isLoading: myStatusLoading = true,
          hasStudentHistory = false,
          error: myStatusError = null,
        } = {},
      } = state;

      const error = myAcademicsError || myStatusError;
      const isLoading = myAcademicsLoading || myStatusLoading;

      return {
        error,
        isLoading,
        loadState,
        hasStudentHistory,
        pastSemestersLimit,
        semesters,
      };
    });

  if (hasStudentHistory) {
    let displayedSemesters = semesters;
    if (!termsExpanded) {
      displayedSemesters = semesters.slice(0, pastSemestersLimit);
    }

    return (
      <Card data-testid="academic-semesters-card">
        <CardTitle>
          <h2>Semesters</h2>
          <ViewAcademicSummaryLink isAdvisingView={isAdvisingView} />
        </CardTitle>

        <CardBody>
          {loadState === "pending" && <Spinner />}

          {loadState === "failure" && (
            <ErrorMessage message={error.statusText} />
          )}

          {loadState === "success" && (
            <>
              {semesters.length > 0 &&
                displayedSemesters.map((semester) => (
                  <Semester
                    semester={semester}
                    isAdvisingView={isAdvisingView}
                    key={semester.termId}
                  ></Semester>
                ))}
              {semesters.length > 0 && (
                <TermLimiter
                  termsExpanded={termsExpanded}
                  toggleTermsExpanded={toggleTermsExpanded}
                />
              )}
            </>
          )}
        </CardBody>
      </Card>
    );
  }

  return null;
}

SemestersCard.displayName = "SemestersCard";
SemestersCard.propTypes = {
  isAdvisingView: PropTypes.bool,
};
