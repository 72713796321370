import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { mdash } from "functions/symbols";
import { connect } from "react-redux";
import { getMyStatus } from "data/slices/myStatus";

const ClassGrade = ({
  canViewGrades,
  fetchData,
  midpointGradeExpanded,
  setMidpointGradeExpanded,
  userIsLawStudent,
  section,
}) => {
  useEffect(() => {
    fetchData();
  }, []);

  if (!section) {
    return null;
  }

  if (!canViewGrades) {
    return null;
  }

  const toggleMidpointGradeNotice = () => {
    setMidpointGradeExpanded(!midpointGradeExpanded);
  };

  const {
    grading: { grade = null, midpointGrade = null, gradingBasis = null } = {},
    isLaw: sectionIsLaw = false,
  } = section;

  return (
    <>
      {grade && <>{grade}</>}

      {!grade && midpointGrade && (
        <button
          onClick={toggleMidpointGradeNotice}
          className="cc-button-link cc-inherit-font-size"
        >
          <i className="fa fa-exclamation-triangle cc-icon-gold"></i> ?
        </button>
      )}

      {!grade && !midpointGrade && (
        <>
          {!sectionIsLaw && !userIsLawStudent && gradingBasis && (
            <>{section.grading.gradingBasis}</>
          )}
          {(sectionIsLaw || userIsLawStudent || !gradingBasis) && <>{mdash}</>}
        </>
      )}
    </>
  );
};

ClassGrade.displayName = "ClassGrade";
ClassGrade.propTypes = {
  canViewGrades: PropTypes.bool,
  fetchData: PropTypes.func.isRequired,
  midpointGradeExpanded: PropTypes.bool,
  section: PropTypes.object,
  setMidpointGradeExpanded: PropTypes.func,
  userIsLawStudent: PropTypes.bool,
};

function mapState({ myStatus }) {
  const {
    roles: { law: userIsLawStudent = false },
  } = myStatus;

  return { userIsLawStudent };
}

function mapDispatch(dispatch) {
  return {
    fetchData: () => {
      dispatch(getMyStatus());
    },
  };
}

export default connect(mapState, mapDispatch)(ClassGrade);
