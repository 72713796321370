import React, { useState } from "react";
import PropTypes from "prop-types";
import { VisuallyHidden } from "components/ui/VisuallyHidden";
import { HoverTable } from "./HoverTable";
import { Summary } from "./Summary";
import { TermItem } from "./TermItem";

export function CostOfAttendanceTable({ data }) {
  return (
    <HoverTable>
      {data.map(({ headers, items, title: categoryTitle }, index) => {
        return (
          <CostOfAttendanceAccordian
            key={index}
            headers={headers}
            items={items}
            categoryTitle={categoryTitle}
          />
        );
      })}
    </HoverTable>
  );
}

CostOfAttendanceTable.propTypes = {
  data: PropTypes.array,
};

const CostOfAttendanceAccordian = ({
  headers,
  items,
  categoryTitle: originalCategoryTitle = "",
}) => {
  const [expanded, onAccordionClick] = useAccordion();
  const categoryTitle = originalCategoryTitle.replace(" Items", "");

  return (
    <>
      <thead>
        <tr>
          {headers.length > 1 ? (
            <>
              <th>{categoryTitle}</th>
              {headers.map((header, index) => (
                <th key={index}>{header}</th>
              ))}
            </>
          ) : (
            <>
              <th>{categoryTitle}</th>
              <th>{headers[0]}</th>
            </>
          )}
          <th>
            <VisuallyHidden>Controls</VisuallyHidden>
          </th>
        </tr>
      </thead>

      {items &&
        items.map((item, index) => {
          const { subItems = [] } = item;
          if (subItems.length) {
            return (
              <TermItem
                key={index}
                item={item}
                isExpanded={expanded.includes(index)}
                onClick={() => onAccordionClick(index)}
              />
            );
          } else {
            return (
              <Summary key={index} item={item} categoryTitle={categoryTitle} />
            );
          }
        })}
    </>
  );
};

CostOfAttendanceAccordian.propTypes = {
  headers: PropTypes.array,
  items: PropTypes.array,
  categoryTitle: PropTypes.string,
};

const useAccordion = () => {
  const [expanded, setExpanded] = useState([]);

  const onClick = (itemIndex) => {
    const foundAt = expanded.findIndex((index) => index === itemIndex);

    if (foundAt === -1) {
      setExpanded((expanded) => [...expanded, itemIndex]);
    } else {
      setExpanded((expanded) => expanded.toSpliced(foundAt));
    }
  };

  return [expanded, onClick];
};
