import React from "react";
import APILink from "components/ui/APILink";
import { UnstyledList } from "components/ui/Lists";
import { useSlice } from "components/hooks/useSlice";
import { SchedulePlannerLink } from "./SchedulePlannerLink";

export const AdvisingResources = () => {
  const { resources: { resourceLinks = [] } = {} } = useSlice("advising");

  if (resourceLinks.length > 0) {
    return (
      <>
        <dt>Advising Resources</dt>
        <dd>
          <UnstyledList>
            {resourceLinks.map((link, i) => {
              const { name, url } = link;
              return (
                <li key={i}>
                  <APILink
                    link={{
                      url,
                      description: name,
                      showNewWindow: true,
                    }}
                    text={name}
                  />
                </li>
              );
            })}
            <SchedulePlannerLink />
          </UnstyledList>
        </dd>
      </>
    );
  }

  return null;
};
AdvisingResources.displayName = "AdvisingResources";
