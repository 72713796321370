import PropTypes from "prop-types";

export const OPTION_ACCEPT = "DEIN"; // DEclaration of INtent to Register
export const OPTION_DECLINE = "WAPP"; // Withdraw APPlication

export const STATUS_INITIATED = "I";
export const STATUS_RECEIVED = "R";
export const STATUS_COMPLETE = "C";

export const optionShape = PropTypes.shape({
  progAction: PropTypes.string, // DEIN_ WAPP_ DATA maybe use "oneOf"
  descrLong: PropTypes.string,
  value: PropTypes.string,
});

export const responseReasonShape = PropTypes.shape({
  responseReason: PropTypes.string, // The code string
  descr: PropTypes.string, // The long description
});

export const statusShape = PropTypes.shape({
  isUndergraduate: PropTypes.bool,
  config: PropTypes.shape({
    descrProgramLong: PropTypes.string,
  }),
  itemStatusCode: PropTypes.oneOf([
    STATUS_INITIATED,
    STATUS_RECEIVED,
    STATUS_COMPLETE,
  ]),
  header: PropTypes.shape({
    name: PropTypes.string,
    title: PropTypes.string,
    background: PropTypes.string, // TODO: limit to known background strings
    picture: PropTypes.string, // TODO: limit to known picture strings
  }),
  deposit: PropTypes.shape({
    require: PropTypes.bool,
    dueDt: PropTypes.string,
  }),
  responseReasons: PropTypes.arrayOf(responseReasonShape),
});

export const statusProps = {
  status: statusShape,
};
