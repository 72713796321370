import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

export default function Unread({ count }) {
  const message =
    count === 1 ? `1 unread notification` : `${count} unread notifications`;

  return <UnreadBannerStyles>{message}</UnreadBannerStyles>;
}

Unread.displayName = "Unread";
Unread.propTypes = {
  count: PropTypes.number,
};

const UnreadBannerStyles = styled.div`
  background: var(--colonial-white);
  height: 22px;
  line-height: 22px;
  text-align: center;
`;
