import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { LargeSpacer } from "components/ui/VerticalSpacers";
import APILink from "components/ui/APILink";
import WithAPILink from "components/ui/WithAPILink";
import { CardBody } from "components/ui/Card";
import WithMessage from "components/WithMessage";
import SubTitle from "./SubTitle";
import CourseTable from "./CourseTable";
import SimpleList from "./SimpleList";
import SwapIndicator from "./SwapIndicator";

export default function WaitlistsTable({ waitlistedCourses, swapSections }) {
  const lawOnly = waitlistedCourses.every(
    (course) => course.courseCareerCode === "LAW"
  );

  return (
    <div data-testid="waitlists-table">
      <SubTitle>
        <h3>Wait Lists</h3>
      </SubTitle>
      <CardBody>
        <div>
          You are waitlisted for the class(es) listed below.
          {!lawOnly && (
            <>
              {" "}
              Learn more about <WithAPILink urlID="UC_CX_WAITLIST_STDNT_OPTS" />
              .
            </>
          )}
          <WithMessage set="28000" number="216">
            {(message) => (
              <div
                dangerouslySetInnerHTML={{
                  __html: message.description,
                }}
              ></div>
            )}
          </WithMessage>
        </div>
        <CourseTable>
          <thead>
            <tr>
              <th>Course</th>
              <th>Title</th>
              <th>Waitlist Position / Class Size</th>
            </tr>
          </thead>
          <tbody>
            {waitlistedCourses.map((course) => (
              <CourseRow
                key={course.course_id}
                course={course}
                swapSections={swapSections}
              />
            ))}
          </tbody>
        </CourseTable>
      </CardBody>
      <LargeSpacer />
    </div>
  );
}

WaitlistsTable.displayName = "WaitlistsTable";
WaitlistsTable.propTypes = {
  waitlistedCourses: PropTypes.array,
  swapSections: PropTypes.array,
};

function CourseRow({ course, swapSections }) {
  const swapIndex =
    swapSections.findIndex((swap) => {
      return course.sections.find((section) => section.ccn === swap.to.ccn);
    }) + 1;

  return (
    <tr key={course.course_id}>
      <td>
        <div
          style={{
            display: `flex`,
            gap: `var(--medium-spacing)`,
            alignItems: "flex-start",
          }}
        >
          <SwapIndicator index={swapIndex} />
          <div>
            <Link to={course.url}>{course.course_code}</Link>{" "}
            {course.sections.map((section) => (
              <div key={section.section_label}>{section.section_label}</div>
            ))}
          </div>
        </div>
      </td>
      <td>{course.title}</td>
      <td>
        {course.sections.map((section) => (
          <WaitlistSectionCell
            key={section.ccn}
            course={course}
            section={section}
          />
        ))}
      </td>
    </tr>
  );
}

CourseRow.displayName = "CourseRow";
CourseRow.propTypes = {
  course: PropTypes.object,
  swapSections: PropTypes.array,
};

function WaitlistSectionCell({ section }) {
  const { uc_reason_desc: reasonDesc } = section;

  return (
    <div>
      <div>
        {section.waitlisted > 0 && section.waitlistPosition > 0 && (
          <span>
            <strong>{section.waitlistPosition}</strong> on list of class of{" "}
            <strong>{section.enroll_limit}</strong>
          </span>
        )}

        {section.waitlistPosition > 0 && section.hasReservedSeats && (
          <div>
            <i
              className="fa fa-exclamation-circle"
              style={{ color: `var(--thunderbird)` }}
            ></i>{" "}
            <APILink link={section.reservedSeatsInfoLink} />
          </div>
        )}

        {reasonDesc && (
          <div>
            <strong>Reasons Not Enrolled</strong>
            <SimpleList>
              <li>
                <WithAPILink
                  urlID="UC_CX_WAITLIST_REASON_NOT_ENRL"
                  text={reasonDesc}
                />
              </li>
              <li>
                <WithAPILink
                  urlID="UC_CX_WAITLIST_REASON_NOT_ENRL"
                  text="Other conditions"
                />{" "}
                may block future attempts
              </li>
            </SimpleList>
          </div>
        )}
      </div>
    </div>
  );
}

WaitlistSectionCell.displayName = "WaitlistSectionCell";
WaitlistSectionCell.propTypes = {
  section: PropTypes.object,
};
