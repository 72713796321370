import { configureStore } from "@reduxjs/toolkit";
import AppReducer from "./reducers/AppReducer";

export default configureStore({
  reducer: AppReducer,

  // TODO: Remove this after completing the react-only transition
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
    }),
});
