import React from "react";
import PropTypes from "prop-types";
import VisuallyHidden from "components/ui/VisuallyHidden";

export function FinalGradingStatusIcon({ status }) {
  const classNames =
    finalGradingClassNames[status] || finalGradingClassNames["default"];
  const description =
    finalGradingDescriptions[status] || finalGradingDescriptions["default"];
  const style = finalGradingStyles[status] || finalGradingStyles["default"];

  return (
    <>
      <i className={classNames} aria-hidden="true" style={style}></i>
      <VisuallyHidden>{description}</VisuallyHidden>
    </>
  );
}

FinalGradingStatusIcon.propTypes = {
  status: PropTypes.string,
};

const finalGradingDescriptions = {
  periodNotStarted: "Grade entry period not open",
  periodStarted: "Grade entry period open; grades not approved or posted",
  gradesApproved: "Grades approved",
  gradesPosted:
    "Grades posted. Note: All classes, with or without midpoint grades, will post",
  gradesOverdue: "Final grade entry period closed; grades overdue",
  default: "Grade entry period not open",
};

const finalGradingClassNames = {
  periodNotStarted: "fa fa-ban",
  periodStarted: "fa fa-file-o",
  gradesApproved: "fa fa-check",
  gradesPosted: "fa fa-check-circle",
  gradesOverdue: "fa fa-exclamation-circle cc-icon-red",
  default: "fa fa-ban",
};

const finalGradingStyles = {
  gradesPosted: { color: `var(--jewel)` },
  gradesApproved: { color: `var(--jewel)` },
  gradesOverdue: { color: `var(--thunderbird)` },
  default: {},
};
