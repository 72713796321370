import PropTypes from "prop-types";

export const taskShape = PropTypes.shape({
  actionText: PropTypes.string,
  actionURL: PropTypes.string,
  completedDate: PropTypes.string,
  departmentName: PropTypes.string,
  description: PropTypes.string,
  displayCategory: PropTypes.string,
  dueDate: PropTypes.string,
  hasUpload: PropTypes.bool,
  organizationName: PropTypes.string,
  status: PropTypes.string,
  title: PropTypes.string,
  uploadURL: PropTypes.object,
  url: PropTypes.object,
});

export const completedAgreementTaskShape = PropTypes.shape({
  title: PropTypes.string,
  response: PropTypes.string,
  responseDate: PropTypes.string,
  url: PropTypes.object,
  updatesAllowed: PropTypes.bool,
});
