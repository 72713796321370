import React from "react";
import { useSelector } from "react-redux";
import { semesterShape } from "../../propTypes/semesterShape";

export default function WaitlistedClassesTable({ semester }) {
  const canViewGrades = useSelector(
    ({ myStatus: { canViewGrades } = {} }) => canViewGrades
  );

  return (
    <>
      <thead>
        <tr>
          <th width="70%">Waitlisted</th>
          <th width="15%" style={{ textAlign: `right` }}>
            Units
          </th>
          {canViewGrades && <th width="15%">Position</th>}
        </tr>
      </thead>
      {semester.enrolledClasses.map((course) => (
        <tbody key={course.course_code}>
          {course.sections
            .filter(
              (section) => section.is_primary_section && section.waitlisted
            )
            .map((section) => (
              <tr key={course.course_code}>
                <td>
                  {course.course_code}{" "}
                  {course.multiplePrimaries && section.section_label}{" "}
                  {course.session_code && `(Session ${course.session_code})`}
                </td>
                <td style={{ textAlign: `right` }}>
                  {typeof Number.parseFloat(section.units) === "number" &&
                    Number.parseFloat(section.units).toPrecision(2).slice(0, 3)}
                </td>
                {canViewGrades && (
                  <td style={{ textAlign: `right` }}>
                    {section.waitlistPosition}
                  </td>
                )}
              </tr>
            ))}
        </tbody>
      ))}
      <tbody>
        <tr>
          <td style={{ textAlign: `right` }}>Total Units:&nbsp;</td>
          <td style={{ textAlign: `right` }}>
            <strong>{semester.waitlistedUnits}</strong>
          </td>
          {canViewGrades && <td></td>}
        </tr>
      </tbody>
    </>
  );
}

WaitlistedClassesTable.displayName = "WaitlistedClassesTable";
WaitlistedClassesTable.propTypes = { semester: semesterShape };
