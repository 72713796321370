import * as Yup from "yup";
import PropTypes from "prop-types";

export const validationSchema = Yup.object({
  uid: Yup.string().required("Required"),
});

export const userAuthProps = {
  id: PropTypes.number,
  active: PropTypes.bool,
  viewer: PropTypes.bool,
  author: PropTypes.bool,
  superuser: PropTypes.bool,
};

export const userAuthShape = PropTypes.shape(userAuthProps);
