import React from "react";
import PropTypes from "prop-types";
import { Field, useFormikContext } from "formik";
import { OPTION_ACCEPT, optionShape } from "../statusProps";

export const AcceptanceConditions = ({ option, sirConditions }) => {
  const {
    values: { progAction },
  } = useFormikContext();

  if (progAction === OPTION_ACCEPT && option.progAction === OPTION_ACCEPT) {
    return (
      <div
        role="group"
        aria-labelledby="checkbox-group"
        style={{ marginLeft: `25px` }}
      >
        {sirConditions.map((condition) => (
          <label key={condition.seqnum}>
            <Field type="checkbox" name="conditions" value={condition.seqnum} />{" "}
            {condition.messageText}
          </label>
        ))}
      </div>
    );
  }

  return null;
};

AcceptanceConditions.propTypes = {
  sirConditions: PropTypes.array,
  option: optionShape,
};
