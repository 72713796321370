import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

export const LawUnitsFooter = ({
  units,
  lawUnits,
  totalLawUnits,
  isGradingComplete,
  showUnitsTotal,
  totalUnits,
}) => {
  const hasLawTransfer = units > 0 || lawUnits > 0;
  const displayTotal = units > 0 ? (totalUnits - units).toFixed(1) : totalUnits;

  if (showUnitsTotal) {
    return (
      <tfoot>
        {hasLawTransfer && (
          <tr>
            <td colSpan="2">Transfer Units:</td>
            <UnitsCell></UnitsCell>
            <UnitsCell>{lawUnits.toFixed(1)}</UnitsCell>
            <td>CR</td>
            <td></td>
          </tr>
        )}
        <tr>
          <UnitsCell colSpan="2">
            {isGradingComplete ? "Earned Total:" : "Enrolled Total:"}
          </UnitsCell>
          <UnitsCell>
            <strong>{displayTotal}</strong>
          </UnitsCell>

          {totalLawUnits && (
            <UnitsCell>
              <strong>{totalLawUnits}</strong>
            </UnitsCell>
          )}
          <td></td>
          <td></td>
        </tr>
      </tfoot>
    );
  } else {
    return null;
  }
};

LawUnitsFooter.propTypes = {
  hasLawUnits: PropTypes.bool,
  units: PropTypes.number,
  lawUnits: PropTypes.number,
  totalLawUnits: PropTypes.number,
  isGradingComplete: PropTypes.bool,
  showUnitsTotal: PropTypes.bool,
  totalUnits: PropTypes.number,
};

const UnitsCell = styled.td`
  text-align: right;
  padding-right: 15px !important;
`;
