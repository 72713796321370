import styled from "styled-components";
import { CCTable } from "components/ui/Table";

export const SectionOverviewTable = styled(CCTable)`
  thead {
    background-color: var(--dove-gray);
    margin: var(--sm);

    tr {
      td,
      th {
        padding: var(--sm);
        color: var(--white);
        background-color: var(--dove-gray);
        vertical-align: bottom;

        &.header-separator {
          border-left: 1px solid white;
        }

        button {
          background: transparent;
          text-align: left;
          width: 100%;
          border: none;
          color: inherit;
        }
      }
    }
  }

  tbody {
    tr {
      td,
      th {
        padding: var(--sm);
      }

      &:nth-child(even) {
        td,
        th {
          background: var(--gallery);
        }
      }
    }
  }
`;
