import React from "react";
import PropTypes from "prop-types";
import TimeCell from "../TimeCell";

import { CCTable } from "components/ui/Table";
import MissingAppointmentMessage from "../MissingAppointmentMessage";
import { enrollmentPeriodShape } from "../propTypes";

function EnrollmentPeriodRow({ enrollmentPeriod }) {
  const {
    name,
    beginTime: { pacific },
  } = enrollmentPeriod;
  return (
    <tr>
      <td>{name}</td>
      <td>
        <TimeCell time={pacific} />
      </td>
    </tr>
  );
}

EnrollmentPeriodRow.displayName = "EnrollmentPeriodRow";
EnrollmentPeriodRow.propTypes = {
  enrollmentPeriod: enrollmentPeriodShape,
};

function EnrollmentPeriods({ enrollmentPeriods }) {
  if (enrollmentPeriods.length) {
    return (
      <CCTable>
        <thead>
          <tr>
            <th>Law Period</th>
            <th width="33%">Start</th>
          </tr>
        </thead>
        <tbody>
          {enrollmentPeriods.map((period, index) => (
            <EnrollmentPeriodRow key={index} enrollmentPeriod={period} />
          ))}
        </tbody>
      </CCTable>
    );
  }

  return <MissingAppointmentMessage />;
}

EnrollmentPeriods.propTypes = {
  enrollmentPeriods: PropTypes.arrayOf(enrollmentPeriodShape),
};

export default EnrollmentPeriods;
