import React from "react";
import PropTypes from "prop-types";
import { BulletList } from "components/ui/Lists";
import AccessibleLinkContent from "components/ui/AccessibleLinkContent";

export function GradingAssistanceLinks({ semester }) {
  const {
    gradingAssistanceLinkMidpoint: midpoint,
    gradingAssistanceLink: general,
    gradingAssistanceLinkLaw: law,
  } = semester;

  return (
    <BulletList>
      {midpoint && (
        <li>
          <a href={midpoint} target="_blank" rel="noopener noreferrer">
            <AccessibleLinkContent showNewWindow={true}>
              Assistance with Midpoint Grading: General
            </AccessibleLinkContent>
          </a>
        </li>
      )}

      {general && (
        <li>
          <a href={general} target="_blank" rel="noopener noreferrer">
            <AccessibleLinkContent showNewWindow={true}>
              Assistance with Grading: General
            </AccessibleLinkContent>
          </a>
        </li>
      )}

      {law && (
        <li>
          <a href={law} target="_blank" rel="noopener noreferrer">
            <AccessibleLinkContent showNewWindow={true}>
              Midpoint Grades (Graduate)
            </AccessibleLinkContent>
          </a>
        </li>
      )}
    </BulletList>
  );
}

GradingAssistanceLinks.propTypes = {
  semester: PropTypes.object,
};
