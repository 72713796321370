import styled from "styled-components";

const AdvisorCardSection = styled.div`
  &:not(:first-of-type) {
    margin-top: var(--large-spacing);
  }
`;

AdvisorCardSection.displayName = "AdvisorCardSection";
export default AdvisorCardSection;
