import { useContext } from "react";
import { useParams } from "react-router-dom";
import { FakeFinaidYearContext } from "components/contexts/FakeFinaidYearContext";
import { useReact } from "./useReact";

export function useFinaidYearId() {
  const finaidYearId = useReactFinaidYearId();
  const fakeFinaidYearId = useContext(FakeFinaidYearContext);

  if (finaidYearId) return finaidYearId;
  if (fakeFinaidYearId) return fakeFinaidYearId;

  return false;
}

function useReactFinaidYearId() {
  const isReact = useReact();

  if (isReact) {
    const { finaidYearId } = useParams();
    return finaidYearId;
  }

  const path = window.location.pathname;

  if (path.match("/finances/finaid")) {
    const segments = path.split("/");

    return segments[3];
  }
}
