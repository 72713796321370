import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Formik } from "formik";
import { Spinner } from "components/ui/Spinner";
import { Card, CardTitle, CardBody } from "components/ui/Card";
import { LargeSpacer } from "components/ui/VerticalSpacers";
import { UserAuthForm } from "./UserAuthForm";
import { updateUserAuth, destroyUserAuth } from "functions/requests";

export function EditUserAuth() {
  const { id } = useParams();
  const [userAuth, setUserAuth] = useState({});
  const [loadState, setLoadState] = useState("pending");

  useEffect(() => {
    fetch(`/api/user_auths/${id}`)
      .then((response) => response.json())
      .then((data) => {
        setUserAuth(data);
        setLoadState("success");
      });
  }, [id]);

  const onSubmit = (values) => {
    updateUserAuth(id, values).then((response) => response.json());
  };

  const destroy = () => {
    const response = confirm(
      "Are you sure you want to delete this service alert?"
    );

    if (response) {
      destroyUserAuth(id).then((response) => {
        if (response.ok) {
          history.push("/service_alerts");
        }
      });
    }
  };

  return (
    <Card>
      <CardTitle>
        <h2>Edit User Auth</h2>
        <button className="cc-button-link" onClick={destroy}>
          Delete
        </button>
      </CardTitle>
      <CardBody>
        {loadState === "pending" && <Spinner />}
        {loadState === "success" && (
          <>
            <LargeSpacer />
            <Formik initialValues={userAuth} onSubmit={onSubmit}>
              <UserAuthForm />
            </Formik>
          </>
        )}
      </CardBody>
    </Card>
  );
}
EditUserAuth.displayName = "EditUserAuth";
