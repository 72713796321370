export function isSummer(teachingSemester) {
  return teachingSemester.termCode === "C";
}

export function semesterHasMidpointClass(teachingSemester) {
  const { classes } = teachingSemester;

  if (!isSummer(teachingSemester) && classes && classes.length) {
    return !classes.every((course) => course.dept === "LAW");
  }

  return false;
}

export function semesterHasLawClass(teachingSemester) {
  const { classes = [] } = teachingSemester;
  return classes.find((course) => course.dept === "LAW");
}

// Returns boolean. Do any of the courses have a topics array > 0?
export function semesterCoursesHaveTopics(teachingSemester) {
  const courses = teachingSemester.classes;
  return !!courses.find(({ topics = [] }) => topics.length > 0);
}
