import React from "react";
import styled from "styled-components";

import ProfilePicture from "./ProfilePicture";
import { Pronouns } from "./Pronouns";
import { useSlice } from "components/hooks/useSlice";

const NameHeading = styled.h3`
  font-size: 16px;
`;

const NameAndPhotoStyles = styled.div`
  display: grid;
  gap: var(--md) var(--md);
  grid-template-columns: 30% 70%;
`;

export const NameAndPhoto = () => {
  const { fullName } = useSlice("userProfile");

  return (
    <NameAndPhotoStyles data-testid="name-and-photo">
      <ProfilePicture />
      <div>
        <NameHeading>{fullName}</NameHeading>
        <Pronouns />
      </div>
    </NameAndPhotoStyles>
  );
};

NameAndPhoto.displayName = "NameAndPhoto";
