import { useUser } from "components/hooks/useUser";
import { useVisibleLinks } from "./useVisibleLinks";

export function useFilteredNav() {
  const { navigation = [] } = useUser((user) => user.campusLinks);
  const visibleLinks = useVisibleLinks();

  return navigation.map(filterNavigation(visibleLinks));
}

export const filterNavigation = (visibleLinks) => (navItem) => {
  return {
    ...navItem,
    categories: navItem.categories.filter(
      categoriesWithVisibleLinks(visibleLinks)
    ),
  };
};

export const categoriesWithVisibleLinks = (visibleLinks) => (category) => {
  return visibleLinks.find((link) =>
    link.categories.find((c) => c.topcategory === category.name)
  );
};
