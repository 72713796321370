import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { MailtoLink } from "components/ui/MailtoLink";
import { useSlice } from "components/hooks/useSlice";

export const ContactInformation = () => {
  const {
    studentData: {
      contacts: {
        feed: { addresses = [], phones = [], emails = [] } = {},
      } = {},
    } = {},
  } = useSlice("advising");

  const showCard =
    addresses.length > 0 || emails.length > 0 || phones.length > 0;

  if (showCard) {
    return (
      <>
        <dt>Contact Information</dt>
        <dd
          style={{ display: `flex`, flexDirection: `column`, gap: `var(--lg)` }}
        >
          <ContactList>
            {addresses.map((address, index) => (
              <Address address={address} key={index} />
            ))}
          </ContactList>
          <ContactList>
            {phones.map((phone, index) => (
              <Phone phone={phone} key={index} />
            ))}
          </ContactList>
          <ContactList>
            {emails.map((email, index) => (
              <EmailAddress email={email} key={index} />
            ))}
          </ContactList>
        </dd>
      </>
    );
  }

  return null;
};

ContactInformation.displayName = "ContactInformation";

const EmailAddress = ({ email: { type, emailAddress, primary } }) => (
  <>
    <dt>
      {type.description} Email
      {primary && <span> (preferred)</span>}
    </dt>
    <dd>
      <MailtoLink emailAddress={emailAddress}>{emailAddress}</MailtoLink>
    </dd>
  </>
);

EmailAddress.propTypes = {
  email: PropTypes.shape({
    type: PropTypes.shape({
      description: PropTypes.string,
    }),
    primary: PropTypes.bool,
    emailAddress: PropTypes.string,
  }),
};

const Phone = ({ phone: { type, number, primary } }) => (
  <>
    <dt>
      {type.description} Phone
      {primary && <span> (preferred)</span>}
    </dt>
    <dd>{number}</dd>
  </>
);
Phone.propTypes = {
  phone: PropTypes.shape({
    type: PropTypes.shape({
      description: PropTypes.string,
    }),
    primary: PropTypes.bool,
    number: PropTypes.string,
  }),
};

const Address = ({ address: { type, formattedAddress } }) => {
  const [addressLineOne, addressLineTwo] = formattedAddress.split("\\n");
  return (
    <>
      <dt>{type.description} Address</dt>
      <dd>
        <div>{addressLineOne}</div>
        <div>{addressLineTwo}</div>
      </dd>
    </>
  );
};
Address.propTypes = {
  address: PropTypes.shape({
    type: PropTypes.shape({
      description: PropTypes.string,
    }),
    formattedAddress: PropTypes.string,
  }),
};

const ContactList = styled.dl`
  margin: 0;

  dt {
    font-weight: bold;

    span {
      font-weight: normal;
    }
  }

  dd {
    margin: 0;
    margin-bottom: var(--sm);

    &:last-child {
      margin-bottom: 0;
    }
  }
`;
