import React from "react";
import PropTypes from "prop-types";
import ButtonAsLink from "components/ui/ButtonAsLink";
import VisuallyHidden from "components/ui/VisuallyHidden";
import InfoIcon from "components/ui/InfoIcon";

export default function InfoIconButton({ onClick }) {
  return (
    <ButtonAsLink onClick={onClick}>
      <InfoIcon />
      <VisuallyHidden>More Information</VisuallyHidden>
    </ButtonAsLink>
  );
}

InfoIconButton.displayName = "InfoIconButton";
InfoIconButton.propTypes = {
  onClick: PropTypes.func,
};
