import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { Card, CardTitle, CardBody, RightArrowLink } from "components/ui/Card";
import { ModernBlueButtonLink } from "components/ui/ButtonLink";
import Spinner from "components/ui/Spinner";
import WithAPILink from "components/ui/WithAPILink";
import AccessibleLinkContent from "components/ui/AccessibleLinkContent";
import WithLinks from "components/data/WithLinks";
import ShowMore from "components/../react/components/ShowMore";
import { YellowWarning } from "components/ui/Icons";

import { Balances } from "./BillingSummaryCard/Balances";
import { DueNow } from "./BillingSummaryCard/DueNow";

import { getBillingActivity } from "data/slices/billingActivity";
import { dispatchSlice } from "components/hooks/dispatchSlice";
import { useSlice } from "components/hooks/useSlice";

export function BillingSummaryCard() {
  dispatchSlice(getBillingActivity);
  const { loadState } = useSlice("billingActivity");
  const { canActOnFinances } = useSlice("myStatus");
  const { pathname } = useLocation();

  return (
    <WithLinks>
      <Card data-testid="billing-summary-card">
        <CardTitle
          title="Billing Summary"
          right={
            pathname === "/finances" && (
              <RightArrowLink to="/billing/details">
                View Transactions
              </RightArrowLink>
            )
          }
        ></CardTitle>

        {loadState === "pending" ? (
          <CardBody>
            <Spinner />
          </CardBody>
        ) : loadState === "failure" ? (
          <>
            <YellowWarning data-testid="error-notice" />
            There&apos;s a problem displaying your billing information. Please
            try again soon.
          </>
        ) : (
          loadState === "success" && (
            <>
              <DueNow />
              <CardBody style={{ paddingBottom: `0` }}>
                <Balances />
                <PaymentLinkStyles>
                  <ModernBlueButtonLink
                    disabled={!canActOnFinances}
                    href="/higher_one/higher_one_url"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <AccessibleLinkContent showNewWindow={true}>
                      Make Payment
                    </AccessibleLinkContent>
                  </ModernBlueButtonLink>

                  <a
                    className="pdfStatements"
                    disabled={!canActOnFinances}
                    href="/higher_one/higher_one_url"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <AccessibleLinkContent showNewWindow={true}>
                      View PDF Statement
                    </AccessibleLinkContent>
                  </a>
                </PaymentLinkStyles>

                <ShowMore
                  text="Learn more about Billing"
                  view={<LearnMore />}
                />
              </CardBody>
            </>
          )
        )}
      </Card>
    </WithLinks>
  );
}

BillingSummaryCard.displayName = "BillingSummaryCard";
BillingSummaryCard.propTypes = {
  loadState: PropTypes.string,
  canActOnFinances: PropTypes.bool,
};

const LearnMore = () => {
  return (
    <LearnMoreStyles data-testid="billing-learn-more">
      <strong>Due Now</strong>
      <p>
        All charges currently due. Due Now also includes Overdue charges. Learn
        more about <WithAPILink urlID="UC_CX_FA_STU_BILL" />
      </p>
      <strong>Not Yet Due</strong>
      <p>
        All charges with a due date in the future. Learn more about{" "}
        <WithAPILink urlID="UC_CX_FA_STU_BILL" />
      </p>
      <strong>Overdue</strong>
      <p>
        All charges with a due date before today. Overdue charges can result in
        late fees and enrollment cancellation. Learn more about{" "}
        <WithAPILink urlID="UC_CX_FA_DEL_ACC" />
      </p>
      <strong>Making Payments</strong>
      <p>
        Make Payment links to the student payment portal where you can pay your
        bill. Learn more about <WithAPILink urlID="UC_CX_FA_PAY_OPTS" />
      </p>
      <strong>View PDF Statement</strong>
      <p>Links to PDF statements that are easy to download or print.</p>
    </LearnMoreStyles>
  );
};

const LearnMoreStyles = styled.div`
  padding-bottom: var(--large-spacing);

  p {
    margin: 0;
    margin-bottom: 20px;
    line-height: 1.6;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;
LearnMoreStyles.displayName = "LearnMoreStyles";

const PaymentLinkStyles = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
  border-bottom: 1px solid var(--gallery);
  padding: 15px 0;

  a.pdfStatements[disabled] {
    color: var(--dove-gray);
  }
`;
