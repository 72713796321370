import React from "react";
import { Link } from "react-router-dom";
import { SingleCardPage } from "components/ui/SingleCardPage";
import { AcademicSummaryCard } from "components/cards/AcademicSummaryCard";
import { ErrorBoundary } from "components/ui/ErrorBoundary";

export function AcademicSummaryPage() {
  return (
    <SingleCardPage
      title="Academic Summary"
      header={
        <>
          <Link to="/academics">My Academics</Link> &raquo; Academic Summary
        </>
      }
    >
      <ErrorBoundary title="Academic Summary">
        <AcademicSummaryCard />
      </ErrorBoundary>
    </SingleCardPage>
  );
}
AcademicSummaryPage.displayName = "AcademicSummaryPage";
