import { buildCourseSlice } from "data/utils/buildSlice";
import { courseDataThunk } from "data/utils/thunks";

const key = "courseInfo";
export const slice = buildCourseSlice(key);
export const { reducer: courseInfo } = slice;
export const fetchCourseInfo = courseDataThunk(key, slice, {
  dataHandler:
    ({ dispatch, actions, courseId }) =>
    (data) => {
      dispatch(actions.success({ courseId }, { sections: data }));
    },
});
