import React from "react";
import { Diploma as DiplomaContent } from "components/Diploma";
import { fetchAcademicsDiploma } from "data/actions/academics/diplomaActions";
import { useSlice } from "components/hooks/useSlice";
import { dispatchFunction } from "components/hooks/dispatchFunction";

export const Diploma = () => {
  dispatchFunction(fetchAcademicsDiploma);

  const { diploma: { diplomaEligible } = {} } = useSlice("academics");

  if (diplomaEligible) {
    return (
      <>
        <dt data-testid="diploma">Diploma</dt>
        <dd>
          <dl>
            <DiplomaContent />
          </dl>
        </dd>
      </>
    );
  }

  return null;
};
Diploma.displayName = "Diploma";
