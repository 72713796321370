import React from "react";
import { RequireDelegate } from "components/RequireDelegate";
import { BulletList } from "components/ui/Lists";
import { Card, CardBody, CardTitle } from "components/ui/Card";
import { ExternalLink } from "components/ui/ExternalLink";

export function DelegateQuickLinksCard() {
  return (
    <RequireDelegate>
      <Card data-testid="delegate-quick-links-card">
        <CardTitle>
          <h2>Quick Links</h2>
        </CardTitle>
        <CardBody>
          <h3>Reference Websites</h3>
          <BulletList>
            <li>
              <ExternalLink
                href="https://sis.berkeley.edu/delegate-access"
                title="View Delegate Access Setup Guide and FAQs"
              >
                Delegate Access Guide
              </ExternalLink>
            </li>
            <li>
              <ExternalLink href="http://calparents.berkeley.edu">
                CalParents
              </ExternalLink>
              <BulletList>
                <li>
                  <ExternalLink href="http://events.berkeley.edu/index.php/calendar/sn/calparents.html">
                    Important Dates for Parents
                  </ExternalLink>
                </li>
                <li>
                  <ExternalLink href="http://visit.berkeley.edu">
                    Visiting the Campus
                  </ExternalLink>
                </li>
                <li>
                  <ExternalLink href="https://career.berkeley.edu/Infolab/JobSites">
                    Jobs &amp; Careers
                  </ExternalLink>
                </li>
                <li>
                  <ExternalLink href="http://www.berkeley.edu/campus-life/housing-dining">
                    Housing
                  </ExternalLink>
                </li>
                <li>
                  <ExternalLink href="http://financialaid.berkeley.edu/cost-attendance">
                    Financial Information
                  </ExternalLink>
                </li>
                <li>
                  <ExternalLink href="http://www.berkeley.edu/academics/schools-colleges">
                    Academics
                  </ExternalLink>
                </li>
              </BulletList>
            </li>
            <li>
              <ExternalLink href="http://registrar.berkeley.edu/current_students/registration_enrollment/stucal.html">
                Academic Calendar
              </ExternalLink>
            </li>
            <li>
              <ExternalLink href="http://news.berkeley.edu">
                UC Berkeley NewsCenter
              </ExternalLink>
            </li>
            <li>
              <ExternalLink href="http://www.berkeley.edu/news/in_news/">
                Berkeley in the News
              </ExternalLink>
            </li>
            <li>
              <ExternalLink href="http://www.dailycal.org">
                The Daily Californian
              </ExternalLink>
            </li>
          </BulletList>
        </CardBody>
      </Card>
    </RequireDelegate>
  );
}
