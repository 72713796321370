import React from "react";
import PropTypes from "prop-types";
import { Card, CardBody, CardTitle } from "components/ui/Card";
import { Spinner } from "components/ui/Spinner";
import { APILink } from "components/ui/APILink";

import { GraduateTransferCredit } from "./TransferCreditCard/GraduateTransferCredit";
import { UgdTransferCredit } from "./TransferCreditCard/UgdTransferCredit";
import { LawTransferCredit } from "./TransferCreditCard/LawTransferCredit";
import { fetchTransferCredit } from "data/actions/transferCreditActions";
import { fetchTransferCredit as fetchAdvisingTransferCredit } from "data/slices/users/transferCredit";
import { useSlice } from "components/hooks/useSlice";
import { useAdvisingStudent } from "components/hooks/useAdvisingStudent";
import { dispatchFunction } from "components/hooks/dispatchFunction";
import { dispatchAdvisingStudentSlice } from "components/hooks/dispatchAdvisingStudentSlice";

export function TransferCreditCard() {
  dispatchFunction(fetchTransferCredit);
  const myTransferCredit = useSlice("myTransferCredit");

  return <TransferCreditCardUI transferCredit={myTransferCredit} />;
}

export function AdvisingTransferCreditCard() {
  dispatchAdvisingStudentSlice(fetchAdvisingTransferCredit);

  const transferCredit = useAdvisingStudent(
    ({ transferCredit }) => transferCredit
  );

  return <TransferCreditCardUI transferCredit={transferCredit} />;
}

function TransferCreditCardUI({ transferCredit }) {
  const { hasUnits, loadState, law, graduate, undergraduate } =
    transferCredit || {};

  const {
    academics: { advisorLinks: { tcReportLink: advisorLink } = {} },
  } = useSlice("advising") || {};

  const studentLinks = useSlice("apiLinks");

  if (hasUnits) {
    return (
      <Card data-testid="transfer-credit-card">
        <CardTitle>
          <h2>Transfer Credit</h2>
        </CardTitle>
        <CardBody>
          {loadState === "pending" && <Spinner />}
          {loadState === "success" && (
            <>
              {studentLinks && !advisorLink && (
                <APILink
                  link={studentLinks.UC_CX_XFER_CREDIT_REPORT_STDNT}
                  text="Transfer Credit Report"
                />
              )}
              {advisorLink && (
                <APILink link={advisorLink} text="Transfer Credit Report" />
              )}

              <LawTransferCredit
                transferCredits={law}
                hasHairline={
                  Boolean(graduate.summary) || Boolean(undergraduate.summary)
                }
              />
              <GraduateTransferCredit
                transferCredits={graduate}
                hasHairline={Boolean(undergraduate.summary)}
              />
              <UgdTransferCredit transferCredits={undergraduate} />
            </>
          )}
        </CardBody>
      </Card>
    );
  }

  return null;
}

TransferCreditCardUI.propTypes = {
  transferCredit: PropTypes.object,
};
