import React from "react";
import { react2angular } from "react2angular";
import ReduxProvider from "components/ReduxProvider";

import { AcademicProfileCard } from "components/cards/AcademicProfileCard";

const NgAcademicProfileCard = () => (
  <ReduxProvider>
    <AcademicProfileCard />
  </ReduxProvider>
);

angular
  .module("calcentral.react")
  .component("academicProfileCard", react2angular(NgAcademicProfileCard));
