import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import bannerBerkeley from "assets/images/sir/banner_berkeley_326x96.jpg";
import bannerHaas from "assets/images/sir/banner_haas_326x96.jpg";
import bannerLaw from "assets/images/sir/banner_law_326x96.jpg";

const banners = {
  berkeley: bannerBerkeley,
  haasgrad: bannerHaas,
  lawjd: bannerLaw,
};

import { HeaderPortrait } from "./HeaderPortrait";

export function StatusHeader({ header }) {
  const { background, picture, name, title } = header;

  return (
    <StatusHeaderStyles $background={background}>
      {picture && (
        <HeaderPortrait $picture={picture} title="Picture of the Dean" />
      )}

      <div>
        <h2 className="cc-widget-sir-header-intro">
          Congratulations and Welcome!
        </h2>
        {name && <div className="cc-widget-sir-header-name">{name}</div>}
        {title && (
          <div className="cc-widget-sir-header-title">
            <i>{title}</i>
          </div>
        )}
      </div>
    </StatusHeaderStyles>
  );
}

StatusHeader.propTypes = {
  header: PropTypes.shape({
    background: PropTypes.string,
    picture: PropTypes.string,
    name: PropTypes.string,
    title: PropTypes.string,
  }),
};

const StatusHeaderStyles = styled.div`
  background: var(--white);
  display: flex;
  min-height: 96px;
  overflow: hidden;
  padding: 10px;
  position: relative;

  .cc-widget-sir-header-intro {
    font-size: 14px;
    position: relative;
    z-index: 2;
  }

  &::before {
    background-size: cover;
    content: " ";
    display: block;
    height: 100%;
    left: 0;
    opacity: 0.15;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;

    ${({ $background }) =>
      `background-image: url('${
        banners[$background.replace("cc-widget-sir-background-", "")]
      }');`}
  }
`;
