export const filterEnrollment = (filter) => (student) => {
  switch (filter) {
    case "enrolled":
      if (student.enroll_status === "E") {
        return true;
      }

      return false;

    case "waitlisted":
      if (student.enroll_status === "W") {
        return true;
      }

      return false;
    default:
      return true;
  }
};

export const filterCCN = (currentSection) => (student) => {
  if (currentSection === "all" || currentSection === "") {
    return true;
  }

  const matchingCourseCatalogNumber = student.section_ccns.find(
    (catalogNumber) => currentSection === catalogNumber
  );

  if (matchingCourseCatalogNumber) {
    return true;
  } else {
    return false;
  }
};

export const sortStudents = (column, direction) => (a, b) => {
  const valueA = get(a, column, null);
  const valueB = get(b, column, null);
  const isAscendingDirection = direction === "ASC";

  if (String(valueA) > String(valueB)) {
    return isAscendingDirection ? 1 : -1;
  }

  if (String(valueA) < String(valueB)) {
    return isAscendingDirection ? -1 : 1;
  }

  return 0;
};

// works similar to LoDash _.get
// use this only if destructing is not possible
// See https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore#_get
export const get = (obj, path, defaultValue = undefined) => {
  const travel = (regexp) =>
    String.prototype.split
      .call(path, regexp)
      .filter(Boolean)
      .reduce(
        (res, key) => (res !== null && res !== undefined ? res[key] : res),
        obj
      );
  const result = travel(/[,[\]]+?/) || travel(/[,[\].]+?/);
  return result === undefined || result === obj ? defaultValue : result;
};
