import React, { useState } from "react";
import PropTypes from "prop-types";

import MissingPhoto from "./MissingPhoto";

import styled from "styled-components";
import unavailableBear from "assets/images/photo_unavailable_bear_72x96.svg";
import { useSlice } from "components/hooks/useSlice";

const DelegateStudentImageStyles = styled.div`
  background-image: url("${unavailableBear}");
  height: 96px;
  width: 72px;
`;

const PhotoWrapper = styled.div`
  margin-bottom: var(--md);
`;

const ProfilePicture = (props) => {
  const [error, setError] = useState(false);
  const { isStudentSummaryView = false, ldapUid = null } = props;

  const isDelegate = !!useSlice("myStatus").delegateActingAsUid;
  const { fullName } = useSlice("userProfile");

  const imgSrc =
    isStudentSummaryView && ldapUid ? `api/photo/${ldapUid}` : "/api/my/photo";

  if (isDelegate) {
    return (
      <PhotoWrapper>
        <DelegateStudentImageStyles alt={`${fullName}'s photo`} />
      </PhotoWrapper>
    );
  }

  if (error) {
    return (
      <PhotoWrapper>
        <MissingPhoto isStudentSummaryView={isStudentSummaryView} />
      </PhotoWrapper>
    );
  }

  return (
    <PhotoWrapper>
      <img
        src={imgSrc}
        alt={`${fullName}'s Profile Picture`}
        onError={() => setError(true)}
        width="72"
        height="96"
      />
    </PhotoWrapper>
  );
};

ProfilePicture.displayName = "ProfilePicture";
ProfilePicture.propTypes = {
  isStudentSummaryView: PropTypes.bool,
  ldapUid: PropTypes.string,
};

export default ProfilePicture;
