import React, { Fragment } from "react";
import styled from "styled-components";
import { Card, CardBody, CardTitle } from "components/ui/Card";
import { BulletList } from "components/ui/Lists";
import AccessibleLinkContent from "components/ui/AccessibleLinkContent";
import { useGroupedLinks } from "components/pages/CampusPage/useGroupedLinks";

export function CampusLinksCard() {
  const groupedLinks = useGroupedLinks();
  const subcategoryNames = Object.keys(groupedLinks).sort();

  return (
    <Card data-testid="campus-links-card">
      <CardTitle>
        <h2>Links</h2>
      </CardTitle>
      <CardBody>
        {subcategoryNames.map((subcategory) => (
          <Fragment key={subcategory}>
            <SubcategoryHeading>{subcategory}</SubcategoryHeading>
            <BulletList>
              {groupedLinks[subcategory].sort().map((link) => (
                <li key={link.name}>
                  <a
                    href={link.url}
                    title={link.hoverText}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <AccessibleLinkContent showNewWindow={true}>
                      {link.name}
                    </AccessibleLinkContent>
                  </a>{" "}
                  {link.description}
                </li>
              ))}
            </BulletList>
          </Fragment>
        ))}
      </CardBody>
    </Card>
  );
}

const SubcategoryHeading = styled.h3`
  margin: 10px 0;

  &:first-child {
    margin-top: 0;
  }
`;
