import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const UnorderedListBullets = styled.ul`
  margin: 10px 10px 0 15px;
  li::before {
    color: var(--hoki);
    content: "\\25A0";
    float: left;
    margin-left: -16px;
    margin-top: -2px;
    padding-right: 8px;
  }
`;

export default function HaasProgramLinks({ currentStudentAcademicRoles }) {
  const {
    haasFullTimeMba: isCurrentHassFullTimeMba,
    haasEveningWeekendMba: isCurrentHassEveningWeekendMba,
    haasExecMba: isCurrentHassExecMba,
    haasMastersFinEng: isCurrentHassMastersFinEng,
    haasMbaPublicHealth: iscurrentHaasMbaPublicHealth,
    haasMbaJurisDoctor: isCurrentHaasMbaJurisDoctor,
  } = currentStudentAcademicRoles || {};

  const isHaasStudent =
    isCurrentHassFullTimeMba ||
    isCurrentHassEveningWeekendMba ||
    isCurrentHassExecMba ||
    isCurrentHassMastersFinEng ||
    iscurrentHaasMbaPublicHealth ||
    isCurrentHaasMbaJurisDoctor;

  if (isHaasStudent) {
    return (
      <div>
        {isCurrentHassFullTimeMba &&
          !iscurrentHaasMbaPublicHealth &&
          !isCurrentHaasMbaJurisDoctor && (
            <div>
              <a href="http://www.haas.berkeley.edu/MBA/contacts/index.html">
                Contact the Full-time MBA Program Office
              </a>
            </div>
          )}
        {isCurrentHassEveningWeekendMba && (
          <div>
            <a href="http://haas.berkeley.edu/EWMBA/contacts/">
              Contact the Evening and Weekend MBA Program Office
            </a>
          </div>
        )}
        {isCurrentHassExecMba && (
          <div>
            <a href="http://haas.berkeley.edu/EMBA/contacts/">
              Contact the Berkeley MBA For Executives Program Office
            </a>
          </div>
        )}
        {isCurrentHassMastersFinEng && (
          <div>
            <a href="http://www.haas.berkeley.edu/MFE/">
              Contact the Master of Financial Engineering Program Office
            </a>
          </div>
        )}
        {iscurrentHaasMbaPublicHealth && (
          <div>
            Contact the appropriate degree program office at Haas or Public
            Health:
            <UnorderedListBullets>
              <li>
                <a href="http://www.haas.berkeley.edu/MBA/contacts/index.html">
                  Haas
                </a>
              </li>
              <li>
                <a href="http://sph.berkeley.edu/areas-study/health-policy-management">
                  Public Health
                </a>
              </li>
            </UnorderedListBullets>
          </div>
        )}
        {isCurrentHaasMbaJurisDoctor && (
          <div>
            Contact the appropriate degree program office at Haas or Law:
            <UnorderedListBullets>
              <li>
                <a href="http://www.haas.berkeley.edu/MBA/contacts/index.html">
                  Haas
                </a>
              </li>
              <li>
                <a href="https://www.law.berkeley.edu/students/student-services/">
                  Law
                </a>
              </li>
            </UnorderedListBullets>
          </div>
        )}
      </div>
    );
  } else {
    return null;
  }
}

HaasProgramLinks.displayName = "HaasProgramLinks";
HaasProgramLinks.propTypes = {
  links: PropTypes.object,
  currentStudentAcademicRoles: PropTypes.object,
};
