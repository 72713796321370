export const isEnrolled = (section) => !section.waitlisted;
export const isWaitlisted = (section) => section.waitlisted;

export const collectTopics = (course) => ({
  ...course,
  topics: course.sections
    .map((section) => section.topic_description)
    .filter(Boolean),
});

export const getWaitlisted = (courses) => {
  return courses
    .map(filteredCourseSections(isWaitlisted))
    .filter((course) => course.sections.length);
};

export const filteredCourseSections = (filter) => (course) => ({
  ...course,
  sections: course.sections.filter(filter),
});

export function splitMultiplePrimaries(course) {
  const courses = {};

  course.sections.map((section) => {
    if (section.is_primary_section) {
      const courseCopy = JSON.parse(JSON.stringify(course));

      courseCopy.gradeOption = section.gradeOption;
      courseCopy.units = section.units;

      if (course.multiplePrimaries) {
        courseCopy.url = section.url;
      }

      const key = course.multiplePrimaries ? section.slug : "default";

      courseCopy.sections = courses[key] ? courses[key].sections : [];
      courseCopy.sections.push(section);
      courseCopy.topics = collectTopics(course);
      courses[key] = courseCopy;
    } else {
      const key = course.multiplePrimaries
        ? section.associatedWithPrimary
        : "default";

      if (!courses[key]) {
        courses[key] = {};
        courses[key].sections = [section];
      } else {
        courses[key].sections.push(section);
      }
    }
  });

  let splitCourses = [];
  for (let ccn in courses) {
    if (courses.hasOwnProperty(ccn)) {
      splitCourses.push(courses[ccn]);
    }
  }

  return splitCourses;
}
