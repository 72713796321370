import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { Card, CardBody, CardTitle } from "components/ui/Card";
import Spinner from "components/ui/Spinner";
import currentUserState from "utils/currentUserState";
import formatCurrency from "functions/formatCurrency";
import { dispatchUserSlice } from "components/hooks/dispatchUserSlice";
import { fetchLoanHistorySummary } from "data/slices/users/loanHistorySummary";

export function LoanHistoryCard() {
  dispatchUserSlice(fetchLoanHistorySummary);

  const loanHistorySummary = useSelector((state) => {
    return currentUserState(state)?.loanHistorySummary;
  });

  const { loadState, active, amountOwed } = loanHistorySummary;

  return (
    <Card data-testid="loan-history-card">
      <CardTitle>
        <h2>Loan History</h2>
      </CardTitle>
      <CardBody>
        {loadState === "pending" && <Spinner />}
        {loadState === "success" && (
          <>
            {active ? (
              <ActiveLoanHistoryStyles>
                <div>
                  <strong>Total Calculated Debt</strong>
                  <div className="amount">{formatCurrency(amountOwed)}</div>
                </div>
                <div>
                  <Link to="/finances/loan_summary_aid_year">
                    View Summary by Aid Year
                  </Link>
                  <Link to="/finances/cumulative_loan_debt">
                    View Cumulative Debt
                  </Link>
                </div>
              </ActiveLoanHistoryStyles>
            ) : (
              <a href="/finances/loan_resources">View Loan Resources</a>
            )}
          </>
        )}
      </CardBody>
    </Card>
  );
}

const ActiveLoanHistoryStyles = styled.div`
  display: flex;
  justify-content: space-between;

  div {
    flex; 1
  }

  > div:first-child {
    .amount {
      font-size: 16px;
    }
  }

  > div:last-child {
    text-align: right;

    a {
      display: block;
    }
  }
`;
