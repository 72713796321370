import React from "react";
import { react2angular } from "react2angular";
import ReduxProvider from "components/data/ReduxProvider";
import { GraduationChecklistCard } from "components/cards/GraduationChecklistCard";

const NgAcademicsGraduationChecklist = () => (
  <ReduxProvider>
    <GraduationChecklistCard />
  </ReduxProvider>
);

angular
  .module("calcentral.react")
  .component(
    "academicsGraduationChecklist",
    react2angular(NgAcademicsGraduationChecklist)
  );
