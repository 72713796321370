import React from "react";
import PropTypes from "prop-types";

import SemesterHeader from "./SemesterHeader";
import SemesterClasses from "./SemesterClasses";
import SemesterTotalUnits from "./SemesterTotalUnits";

import styled from "styled-components";

const parseSemesterTotalUnits = (semester) => {
  let enrolledUnits = [];
  let waitlistUnits = [];
  let enrolledUnitsTotal = 0;
  let waitlistUnitsTotal = 0;

  const sumReducer = (accumulator, currentValue) => accumulator + currentValue;

  let semesterSections = [];
  semester.classes.map((semesterClass) => {
    semesterSections = semesterSections.concat(semesterClass.sections);
  });

  let primarySections = semesterSections.filter((section) => {
    return section.is_primary_section;
  });

  primarySections.map((section) => {
    if (!section.waitlisted) {
      enrolledUnits.push(parseFloat(section.units));
    }
    if (section.waitlisted) {
      waitlistUnits.push(parseFloat(section.units));
    }
  });

  enrolledUnitsTotal =
    enrolledUnits.length > 0 ? enrolledUnits.reduce(sumReducer) : 0;
  waitlistUnitsTotal =
    waitlistUnits.length > 0 ? waitlistUnits.reduce(sumReducer) : 0;

  return [enrolledUnitsTotal, waitlistUnitsTotal];
};

const SemesterStyles = styled.div`
  border-bottom: var(--dusty-gray) solid 1px;
  margin-top: var(--large-spacing);
  padding-bottom: var(--large-spacing);
  width: 100%;

  .status {
    font-size: 13px;
    font-weight: bold;
    margin: 0 var(--medium-spacing);
    > span {
      display: inline-block;
      vertical-align: middle;
    }
  }

  .notation {
    margin-left: var(--medium-spacing);
  }
`;

const Semester = ({ isAdvisingView, semester }) => {
  const [enrolledUnits, waitlistedUnits] = parseSemesterTotalUnits(semester);

  return (
    <SemesterStyles data-testid="academic-semester">
      <SemesterHeader isAdvisingView={isAdvisingView} semester={semester} />
      <SemesterClasses isAdvisingView={isAdvisingView} semester={semester} />
      <SemesterTotalUnits
        enrolledUnits={enrolledUnits}
        waitlistedUnits={waitlistedUnits}
        semester={semester}
      />
    </SemesterStyles>
  );
};

Semester.displayName = "Semester";
Semester.propTypes = {
  isAdvisingView: PropTypes.bool,
  semester: PropTypes.object,
};

export default Semester;
