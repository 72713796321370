import React, { useState } from "react";
import { Card, CardTitle, CardBody } from "components/ui/Card";
import { fetchFinaidCostOfAttendance } from "data/slices/users/finaid/finaidCostOfAttendance";
import { Spinner } from "components/ui/Spinner";
import ButtonAsLink from "components/ui/ButtonAsLink";
import { MediumSpacer } from "components/ui/VerticalSpacers";
import { CostOfAttendanceTable } from "./CostOfAttendanceCard/CostOfAttendanceTable";
import { useFinaidYear } from "components/hooks/useFinaidYear";
import { dispatchUserFinaidSlice } from "components/hooks/dispatchUserFinaidSlice";
import { StyledMessage } from "components/ui/StyledMessage";
import { ErrorBoundary } from "components/ui/ErrorBoundary";
import { LargeSpacer } from "components/ui/VerticalSpacers";

export function CostOfAttendanceCard() {
  return (
    <ErrorBoundary title="Estimated Cost of Attendance">
      <CostOfAttendanceCardUI />
    </ErrorBoundary>
  );
}

function CostOfAttendanceCardUI() {
  dispatchUserFinaidSlice(fetchFinaidCostOfAttendance);

  const { loadState } = useFinaidYear(
    (finaid) => finaid.finaidCostOfAttendance || {}
  );

  return (
    <Card data-testid="finaid-estimated-cost-of-attendance-card">
      <CardTitle>
        <h2>Estimated Cost of Attendance</h2>
      </CardTitle>
      <CardBody>
        {loadState === "pending" && <Spinner />}
        {loadState === "success" && <CostOfAttendanceBody />}
      </CardBody>
    </Card>
  );
}

function CostOfAttendanceBody() {
  const [fullYearOrSemester, setFullYearOrSemester] = useState("fullyear");

  const {
    coa: { message, fullyear, semester } = {},
    noCoaDataMessage: { descrlong: noCoaDataForAidYearMessage } = {},
  } = useFinaidYear((finaid) => finaid.finaidCostOfAttendance || {});

  const { data: fullYearData = [] } = fullyear || {};
  const { data: semesterData = [] } = semester || {};

  const toggleView = () => {
    setFullYearOrSemester(
      fullYearOrSemester === "semester" ? "fullyear" : "semester"
    );
  };

  const currentDataSet =
    fullYearOrSemester === "semester" ? semesterData : fullYearData;

  return (
    <>
      {message && (
        <>
          <StyledMessage
            dangerouslySetInnerHTML={{ __html: message }}
            style={{ margin: `0` }}
          />
          <LargeSpacer />
        </>
      )}

      {!currentDataSet.length && noCoaDataForAidYearMessage && (
        <div>{noCoaDataForAidYearMessage}</div>
      )}

      <div
        data-ng-if="!coa[coa.currentView].data.length"
        className="cc-widget-finaid-coa-text-padding"
      ></div>

      {!!currentDataSet.length && (
        <>
          <ButtonAsLink onClick={toggleView}>
            {fullYearOrSemester === "semester"
              ? "View Annual Amount"
              : "View Term Amount"}
          </ButtonAsLink>
          <MediumSpacer />
          <CostOfAttendanceTable data={currentDataSet} />
        </>
      )}
    </>
  );
}
